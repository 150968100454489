






















































































import StatsCard from '@/components/Argon/Cards/StatsCard.vue';
import usersApp from '@/store/types/usersApp';
import transactionsCustomer from '@/store/types/transactionsCustomer';
import { transactionsCustomerParams } from '@/store/interfaces/transactionsCustomer';
import { risk } from '@/util/transformString/translationsDecorator';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import PaymentMethods from '@/components/Reusable/PaymentMethods.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'UserTransactions',
  components: {
    StatsCard,
    StatusBadges,
    PaymentMethods,
  },
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'commerce_name',
        label: 'Comercio',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'branch_name',
        label: 'Sucursal',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'subtotal_formatted',
        label: 'Subtotal',
        minWidth: 120,
        sortable: true,
      },
      {
        prop: 'tip_formatted',
        label: 'Propina',
        minWidth: 120,
        sortable: true,
      },
      {
        prop: 'discount_formatted',
        label: 'Descuento',
        minWidth: 120,
        sortable: true,
      },
      {
        prop: 'total_formatted',
        label: 'Total',
        minWidth: 120,
        sortable: true,
      },
      {
        prop: 'status',
        label: 'Estado',
        minWidth: 120,
        sortable: true,
        slotName: 'status',
      },
      {
        prop: 'kind_name',
        label: 'Canal de pago',
        minWidth: 130,
      },
      {
        prop: 'card',
        label: 'Método de pago',
        minWidth: 220,
        sortable: true,
        slotName: 'card',
      },

      {
        prop: 'virtual_updated_at',
        label: 'Fecha y hora',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'risk',
        label: 'Riesgo',
        minWidth: 220,
        sortable: true,
        formatter: (row: transactionsCustomerParams): string => {
          return risk(row.risk || '');
        },
      }
    ] as tTableColumns,
    rowSelected: {
      authorization_number: '',
      balance_amount_cents: 0,
      balance_amount_currency: 'USD',
      balance_amount_formatted: '',
      biometrics: false,
      branch_name: '',
      card_amount_cents: 0,
      card_amount_currency: 'USD',
      card_amount_formatted: '',
      card_last_digits: '',
      card_network: '',
      city_code: '',
      commerce_name: '',
      country_code: '',
      created_at: '',
      description: '',
      device_name: '',
      device_platform: '',
      device_vendor: '',
      device_version: '',
      discount_cents: 0,
      discount_currency: '',
      discount_formatted: '',
      distribution: ['not_assigned'],
      expires_at: '',
      id: '',
      kind_name: '',
      latitude: '',
      logo: '',
      longitude: '',
      payment_distributions: [],
      order_kind: 'qr',
      reference_number: '',
      requestable_id: '',
      requestable_type: '',
      response_code: '',
      risk: '',
      state_code: '',
      status: 'pending',
      subtotal_cents: 0,
      subtotal_currency: '',
      subtotal_formatted: '',
      tip_cents: 0,
      tip_currency: '',
      tip_formatted: '',
      total_cents: 0,
      total_currency: '',
      total_formatted: '',
      updated_at: '',
      user_id: '',
      user_name: '',
      virtual_updated_at: '',
    } as transactionsCustomerParams,
  }),
  computed: {
    ...mapGetters({
      getSingleUser: `usersApp/${usersApp.getters.GET_SINGLE_USER}`,
      getTransactionList: `usersApp/transactions/${transactionsCustomer.getters.GET_TRANSACTIONS_BY_CUSTOM_USER}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  created(): void {
    if (!this.hostConfig.globals.tip) {
      const tipPosition = this.tableColumns.findIndex((r) => r.prop === 'tip_formatted');
      this.tableColumns.splice(tipPosition, 1); // delete tip column
    }

    if (!this.hostConfig.globals.risk) {
      const riskPoisition = this.tableColumns.findIndex((r) => r.prop === 'risk');
      this.tableColumns.splice(riskPoisition, 1); // delete risk column
    }

    if (!this.hostConfig.globals.discount) {
      const discountPosition = this.tableColumns.findIndex((r) => r.prop === 'discount_formatted');
      this.tableColumns.splice(discountPosition, 1); // delete discount column
    }
  },
  methods: {
    ...mapActions({
      transactionList: `usersApp/transactions/${transactionsCustomer.actions.TRANSACTIONS_LIST_BY_CUSTOM_USER}`,
    }),
    transactionSelected(row: transactionsCustomerParams): void {
      this.rowSelected = row;
    },
    isCash(row: transactionsCustomerParams): boolean {
      return row.payment_distributions.some((r) => r.kind === 'cash');
    },
    paymentIntentView(row: transactionsCustomerParams): void {
      const hasCash = row.payment_distributions.some((r) => r.kind === 'cash');
      if (!hasCash) {
        this.$router.push({
          name: 'transactions-payment-intent',
          params: {
            user_id: this.$route.params.user_id,
            transaction_id: this.$data.rowSelected.id,
          },
        });
      }
    },
  },
});

