export default {
  mutations: {
    SET_DISCOUNTS: 'SET_DISCOUNTS',
    SET_RULES_VALIDATED: 'SET_RULES_VALIDATED',
    SET_REMOVE_RULES_VALIDATES: 'SET_REMOVE_RULES_VALIDATES',
  },
  getters: {
    GET_RULES_VALIDATED: 'GET_RULES_VALIDATED',
  },
};
