























































































import { CommerceKindParams } from '@/store/interfaces/commerceKind';
import configurations from '@/store/types/configurations';
import { ValidationProvider } from 'vee-validate';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'UpdatePartnersProfile',
  props: {
    singleCommerce: {
      required: true,
      type: Object,
    },
    processing: {
      required: true,
      type: Boolean,
    },
    commerceKindList: {
      required: true,
      type: Array as PropType<CommerceKindParams[]>,
    },
    errors: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      default_type: 1,
      model: {
        name: '',
        commerce_kind_id: '',
        is_franchise: false,
        is_event: false,
        description: '',
      },
    };
  },
  created(): void {
    this.valueDefault();
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  methods: {
    valueDefault(): void {
      if (Object.keys(this.singleCommerce).length > 0) {
        this.$data.model.name = this.singleCommerce.name;
        this.$data.model.commerce_kind_id =
          this.singleCommerce.commerce_kind.id;
        this.$data.model.is_franchise = this.singleCommerce.is_franchise;
        this.$data.model.is_event = this.singleCommerce.is_event;
        this.$data.model.description = this.singleCommerce.description;

        const pos = this.commerceKindList
          .map((e: CommerceKindParams) => e.id)
          .indexOf(this.$data.model.commerce_kind_id);

        this.$data.default_type = pos + 1;
      }
    },

    cancelEdit(): void {
      this.$emit('cancel', true);
      this.valueDefault();
    },

    updateProfile(): void {
      const data = {
        commerce_id: this.$route.params.commerce_id,
        data: this.$data.model,
      };
      this.$emit('update', data);
    },
  },
  watch: {
    errors(error: string[]): void {
      (
        this.$refs.formValidatorUpdateProfile as InstanceType<
          typeof ValidationProvider
        >
      ).setErrors(error);
    },
  },
});
