/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  transactionsCustomerParams,
  transactionsCustomerState
} from '@/store/interfaces/transactionsCustomer';
// Establece los types de los modulos store
import transactionCustomType from '@/store/types/transactionsCustomer';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import DatesFormat from '@/util/classes/datesFormat';
import { nullToString } from '@/util/transformString/nullValidation';
import paymentIntentModule from './paymentsIntent';
import { responseCollection } from '@/store/interfaces/json-api/generics';
const namespaced = true;

const state: transactionsCustomerState = {
  transactionsCustomers: {
    data: [],
    pagination: {},
  },
};
const getters: GetterTree<transactionsCustomerState, any> = {
  [transactionCustomType.getters.GET_TRANSACTIONS_BY_CUSTOM_USER]: state => {
    return state.transactionsCustomers;
  },
};
const mutations: MutationTree<transactionsCustomerState> = {
  [transactionCustomType.mutations.SET_TRANSACTIONS_BY_CUSTOM_USER]: (
    state,
    transactionCustom: responseCollection<transactionsCustomerParams>
  ) => {
    const transactions: Array<transactionsCustomerParams> = [];
    const date = new DatesFormat();

    transactionCustom.data.forEach((transaction: transactionsCustomerParams | any) => {
      transaction.virtual_updated_at = date.formatDateTime(transaction.updated_at);
      Object.entries(transaction).forEach(([value, key]: any) => {
        transaction[value] = nullToString(key);
      });

      transactions.push(transaction);
    });

    state.transactionsCustomers.data = transactions;
    state.transactionsCustomers.pagination = transactionCustom.meta.pagination;
  },
};
const actions: ActionTree<transactionsCustomerState, any> = {
  [transactionCustomType.actions.TRANSACTIONS_LIST_BY_CUSTOM_USER]: (
    { commit, },
    { query, user_id, transaction_id, }
  ) => {
    const newQuery = transaction_id ? `${query}&id_eq=${transaction_id}` : query;

    return new Promise((resolve, reject) => {
      http
        .get(
          `backoffice/customers/${user_id}/payment_requests?${newQuery}`
        )
        .then((res: AxiosResponse) => {
          commit(
            transactionCustomType.mutations.SET_TRANSACTIONS_BY_CUSTOM_USER,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    paymentIntent: paymentIntentModule,
  },
};
