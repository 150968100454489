
































































import uploadImagesMixin from '@/mixins/uploadImagesMixin';
import Vue from 'vue';

export default Vue.extend({
  name: 'UploadDocumentCompanyContact',
  mixins: [uploadImagesMixin],
  data() {
    return {
      references: '',
      register_document_data: {},
    };
  },
  props: {
    documentKindId: {
      type: String,
      required: false,
      default: '',
    },
    inputId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'active',
    },
    documentType: {
      type: String,
    },
    mask: {
      type: String,
    },
    rules: {
      type: String,
    },
    isFor: {
      type: String,
      default: 'company',
    },
  },
  watch: {
    files: {
      immediate: true,
      deep: true,
      handler(val: File): void {
        if (val != null && this.$props.multiple) {
          this.$emit('input', this.$data.files);
        }
      },
    },
    data: {
      deep: true,
      handler(val: string): void {
        this.prepareDocumentToSend(val);
      },
    },
    references: {
      immediate: true,
      deep: true,
      handler(val: string): void {
        this.prepareDocumentToSend(val);
      },
    },
  },
  methods: {
    prepareDocumentToSend(val: string): void {
      if (val !== '') {
        const infoImage = {
          reference: this.$props.id,
          url: this.$props.url,
          data: this.$data.data,
          image: this.$data.currentFile,
          register_document_data: {
            status: this.$props.status,
            document_kind_id: this.$props.documentKindId,
            identity_code: Math.random().toString(36).substring(2),
            document: this.$data.data.blob_signed_id,
            reference: this.$data.references,
          },
        };

        if (this.$data.references === '') {
          delete infoImage.register_document_data.reference;
        }
        this.$data.register_document_data = infoImage.register_document_data;
      }
    },
  },
});
