
























































































import { tModel } from './bankAccountModal.vue';
import bankAccount from '@/store/types/bankAccount';
import resources from '@/store/types/resources';
import { ContactKindParams } from '@/store/interfaces/contact';
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'FormBankAccount',
  props: {
    model: {
      required: true,
      type: Object as PropType<tModel>,
    },
    withContact: {
      required: false,
      type: Boolean,
      default: false,
    },
    contacts: {
      required: false,
      type: Array as PropType<ContactKindParams[]>,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getBanks: `companyModule/bankAccount/${bankAccount.getters.GET_BANKS_LIST}`,
      getBankAccountTypes: `resourcesModule/${resources.getters.GET_BANK_ACCOUNT_TYPES}`,
      getBillingTypes: `resourcesModule/${resources.getters.GET_BILLING_TYPES}`,
    }),
    modelSynced: {
      get(): tModel {
        return this.model;
      },
      set(value: tModel) {
        this.$emit('update:model', value);
      },
    },
  },
  async mounted(): Promise<void> {
    await this.bankAccountTypes();
    await this.billingTypes();
    await this.bankList();
  },
  methods: {
    ...mapActions({
      bankList: `companyModule/bankAccount/${bankAccount.actions.BANKS_LIST}`,
      bankAccountTypes: `resourcesModule/${resources.actions.BANK_ACCOUNT_TYPES}`,
      billingTypes: `resourcesModule/${resources.actions.BILLING_TYPES}`,
    }),
  },
});
