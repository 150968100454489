export default {
  mutations: {
    SET_START_PROCESS: '  SET_START_PROCESS',
    SET_END_PROCESS: '  SET_END_PROCESS',
  },
  getters: {
    GET_START_PROCESS: 'GET_START_PROCESS',
  },
  actions: {
  },
};
