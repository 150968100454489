export default {
  mutations: {
    SET_CONTACT_KIND: 'SET_CONTACT_KIND',
    SET_CONTACT_BY_COMPANY: 'SET_CONTACT_BY_COMPANY',
    SET_CONTACT_BY_BRANCHES: 'SET_CONTACT_BY_BRANCHES',
    SET_SAVE_CONTACT_BY_COMPANY: 'SET_SAVE_CONTACT_BY_COMPANY',
    SET_SAVE_CONTACT_BY_BRANCH: 'SET_SAVE_CONTACT_BY_BRANCH',
    SET_DELETE_DRAFT_CONTACT: 'SET_DELETE_DRAFT_CONTACT',
    SET_SINGLE_CONTACT: 'SET_SINGLE_CONTACT',
  },
  getters: {
    GET_CONTACT_KIND: 'GET_CONTACT_KIND',
    GET_CONTACT_BY_COMPANY: 'GET_CONTACT_BY_COMPANY',
    GET_CONTACT_BY_BRANCHES: 'GET_CONTACT_BY_BRANCHES',
    GET_SINGLE_CONTACT: 'GET_SINGLE_CONTACT',
  },
  actions: {
    LIST_CONTACT_KIND: 'LIST_CONTACT_KIND',
    LIST_CONTACT_BY_BRANCH: 'LIST_CONTACT_BY_BRANCH',
    LIST_CONTACT_BY_COMPANY: 'LIST_CONTACT_BY_COMPANY',
    SAVE_CONTACT: 'SAVE_CONTACT',
    SINGLE_CONTACT: 'SINGLE_CONTACT',
    UPDATE_CONTACT: 'UPDATE_CONTACT',
    DELETE_DRAFT_CONTACT: 'DELETE_DRAFT_CONTACT',
  },
};
