/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { checkPermissions } from '../routeValidation';
import { NavigationGuardNext, Route } from 'vue-router';
import { withdrawalsPermissions } from './permissions/index';
const CommerceKindList = () =>
  import(
    /* webpackChunkName: "refund-by-transfer-report" */
    '@/views/RefundByTransfer/Report.vue'
  );

const commerceKindRoutes = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/refund-by-transfer',
      component: CommerceKindList,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, withdrawalsPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default commerceKindRoutes;
