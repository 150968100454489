import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import commerceType from '@/store/types/commerceKind';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import {
  CommerceKindState,
  CommerceKindParams
} from '@/store/interfaces/commerceKind';
import { newCommerceKindType } from '@/store/interfaces/BlobImage';
import { allowAny } from 'teip-components';
import { responseCollection } from '@/store/interfaces/json-api/generics';
import uploadIcon from './utils/uploadIcon';

const namespaced = true;

const state: CommerceKindState = {
  commerce_kind: {
    data: [],
    pagination: {},
  },
  currentQueryString: '',
};
const getters: GetterTree<CommerceKindState, allowAny> = {
  [commerceType.getters.GET_COMMERCE_KIND]: state => {
    return state.commerce_kind;
  },
};
const mutations: MutationTree<CommerceKindState> = {
  [commerceType.mutations.SET_COMMERCE_KIND]: (
    state,
    commerces: responseCollection<CommerceKindParams>
  ) => {
    state.commerce_kind.data = commerces.data;
    state.commerce_kind.pagination = commerces.meta.pagination;
  },
  [commerceType.mutations.SET_CURRENT_QUERY_STRING]: (
    state,
    params: string
  ) => {
    state.currentQueryString = params;
  },
};
const actions: ActionTree<CommerceKindState, allowAny> = {
  [commerceType.actions.DELETE_COMMERCE_KIND]: ({ dispatch, state, }, id: string) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/commerce_kinds/${id}`)
        .then((res: AxiosResponse) => {
          dispatch(commerceType.actions.LIST_COMMERCE_KIND, state.currentQueryString);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.LIST_COMMERCE_KIND]: ({ commit, }, queryParams: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerce_kinds?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_COMMERCE_KIND, res.data);
          commit(commerceType.mutations.SET_CURRENT_QUERY_STRING, queryParams);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.UPDATE_COMMERCE_KIND]: ({ dispatch, state, },
    { commerceKind, id, }: { commerceKind: newCommerceKindType, id: string }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/commerce_kinds/${id}`, { data: { name: commerceKind.name, }, })
        .then(async (res: AxiosResponse) => {
          if (commerceKind.iconFile) {
            await uploadIcon(id, commerceKind, dispatch);
          }
          dispatch(commerceType.actions.LIST_COMMERCE_KIND, state.currentQueryString);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.SAVE_COMMERCE_KIND]: ({ dispatch, state, }, data: newCommerceKindType) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/commerce_kinds', { data: { name: data.name, }, })
        .then(async (res: AxiosResponse) => {
          const commerce_kind_id = res.data.data.id;
          await uploadIcon(commerce_kind_id, data, dispatch);
          await dispatch(commerceType.actions.LIST_COMMERCE_KIND, state.currentQueryString);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
