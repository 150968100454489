




















import Vue from 'vue';

export default Vue.extend({
  name: 'AuthenticationLayout',
  data: () => ({
    logo: `${process.env.VUE_APP_LOGIN_LOGO}`,
    hostCompany: `${process.env.VUE_APP_COMPANY_HOST}`,
  }),
});
