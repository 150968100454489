














































import company from '@/store/types/company';
import { CompanyParams } from '@/store/interfaces/company';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
import { phoneFormat } from '@/util/phoneFormat';

export default Vue.extend({
  name: 'PartnersCompanies',
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'business_name',
        label: '', // asigned in created function
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'address.description',
        label: 'Dirección',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'address.phone',
        label: 'Teléfono',
        minWidth: 220,
        sortable: true,
        formatter: (value: CompanyParams) => {
          return phoneFormat(value.address?.phone);
        },
      }
    ] as tTableColumns,
    rowSelected: {},
  }),
  created() {
    this.tableColumns[0].label = this.$t('menu.company.singular.formal').toString();
  },
  computed: {
    ...mapGetters({
      getCompaniesByCommerce: `companyModule/${company.getters.GET_COMPANIES_BY_COMMERCE}`,
    }),
  },
  methods: {
    ...mapActions({
      companiesByCommerce: `companyModule/${company.actions.COMPANIES_BY_COMMERCE_LIST}`,
    }),
    ...mapMutations({
      company: `companyModule/${company.mutations.SET_SINGLE_COMPANY}`,
    }),

    rowselect(row: CompanyParams): void {
      this.$data.rowSelected = row;
      this.company(row);
    },

    newCommerce(): void {
      this.$router.push({
        path: `/commerce/new-commerce/company/${this.$data.rowSelected.id}`,
      });
    },

    goProfile(): void {
      this.$router.push({
        name: 'company-profile',
        params: { company_id: this.$data.rowSelected.id, },
      });
    },
  },
});
