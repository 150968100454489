import {
  UserStaffParams,
  UserStaffState
} from '@/store/interfaces/staffUser';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import userType from '@/store/types/userStaff';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { allowAny } from 'teip-components';
import { responseCollection } from '../interfaces/json-api/generics';
const namespaced = true;

const initialState = (): UserStaffState => {
  return {
    users: {
      data: [],
      pagination: {},
    },
    user: {
      email: '',
      id: '',
      permission_groups: [
        { id: '', name: '', kind: 'staff', }
      ],
      intermediary_id: null,
      intermediary: null,
      profile: {
        first_name: '',
        id: '',
        last_name: '',
      },
    },
  };
};

const state: UserStaffState = initialState();

const getters: GetterTree<UserStaffState, allowAny> = {
  [userType.getters.GET_USER_STAFF]: state => {
    return state.users;
  },
  [userType.getters.GET_SINGLE_USER_STAFF]: state => {
    return state.user;
  },
};
const mutations: MutationTree<UserStaffState> = {
  [userType.mutations.SET_USER_STAFF]: (
    state,
    users: responseCollection<UserStaffParams>
  ) => {
    state.users.data = users.data;
    state.users.pagination = users.meta.pagination;
  },
  [userType.mutations.SET_SINGLE_USER_STAFF]: (
    state,
    user: UserStaffParams
  ) => {
    state.user = user;
  },
};
const actions: ActionTree<UserStaffState, allowAny> = {
  [userType.actions.USER_STAFF]: ({ commit, }, queryParams: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/staffs?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_USER_STAFF, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.USER_SINGLE_STAFF]: ({ commit, }, user_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/staffs/${user_id}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_USER_STAFF, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.UPDATE_USER_STAFF]: ({ commit, }, staffUser) => {
    const { user_id, data, } = staffUser;
    const dataToSend = {
      data,
    };
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/staffs/${user_id}`, dataToSend)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_USER_STAFF, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.UPDATE_CURRENT_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .put('backoffice/profile', { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SAVE_USER_STAFF]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/staffs', { data, })
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_USER_STAFF, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
