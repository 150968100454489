






































































































import { UserStaffParams } from '@/store/interfaces/staffUser';
import userStaffType from '@/store/types/userStaff';
import copyObj from '@/util/copyObj';
import { TeipNotifications } from '@/util/teipNotifications';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import IntermediarySelect from '../Reusable/IntermediarySelect.vue';
import configurations from '@/store/types/configurations';
import auth from '@/store/types/auth';

export default Vue.extend({
  name: 'ProfileStaffComponent',
  components: { IntermediarySelect, },
  data: () => ({
    user: {
      profile: {
        first_name: '',
        last_name: '',
      },
      intermediary: null,
      email: '',
      intermediary_id: '',
    } as UserStaffParams,
    edit: false,
    user_id: '',
  }),
  computed: {
    ...mapGetters({
      userStaff: `userStaff/${userStaffType.getters.GET_SINGLE_USER_STAFF}`,
      authUser: `authModule/${auth.getters.GET_AUTH_USER}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    canModifyIntermediary(): boolean {
      return this.hostConfig.globals.intermediary && this.authUser.id !== this.$route.params.user_id;
    },
  },
  mounted() {
    this.user_id = this.userStaff.id;
    this.user = copyObj({
      ...this.userStaff,
      intermediary_id: this.userStaff.intermediary
        ? this.userStaff.intermediary.id
        : '',
    });
  },
  methods: {
    ...mapActions({
      updateUserStaff: `userStaff/${userStaffType.actions.UPDATE_USER_STAFF}`,
    }),
    async updateProfile() {
      const notification = new TeipNotifications();
      const newData = this.$data.user;
      delete newData.permission_groups;
      const dataToSend = {
        user_id: this.$data.user_id,
        data: newData,
      };
      if (this.$route.name === 'my-profile') {
        this.$emit('currentUser', newData);
        this.$data.edit = false;
      } else {
        this.updateUserStaff(dataToSend)
          .then(() => {
            notification.success('Actualizado correctamente');
            this.$data.edit = false;
          })
          .catch(() => {
            notification.warning('No hemos podido actualizar el perfil :(');
          });
      }
    },
  },
});
