export default {
  mutations: {
    SET_BANNERS: 'SET_BANNERS',
    SET_BANNER: 'SET_BANNER',
  },
  getters: {
    GET_BANNERS: 'GET_BANNERS',
    GET_BANNER: 'GET_BANNER',
    GET_NEW_BANNER_SAVED: 'GET_NEW_BANNER_SAVED',
  },
  actions: {
    SAVE_BANNER: 'SAVE_BANNER',
    EDIT_BANNER: 'EDIT_BANNER',
    BANNER_LIST: 'BANNER_LIST',
    BANNER: 'BANNER',
    DELETE_BANNER: 'DELETE_BANNER',
  },
};
