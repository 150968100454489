












































import {
  liquidationPeriod,
  renewalTraduction
} from '@/util/transformString/translationsDecorator';
import { EnterpriceCommissions } from '@/store/interfaces/company';
import Vue, { PropType } from 'vue';
import { formatDate, moneyFormat } from '@/util/filters';
import { mapGetters } from 'vuex';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'ShowCommissions',
  props: {
    commission: {
      required: true,
      type: Object as PropType<EnterpriceCommissions>,
    },
  },
  data: () => ({
    renewalTraduction,
    liquidationPeriod,
    moneyFormat,
  }),
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    startAtCommision(): string {
      return formatDate(this.commission.start_at);
    },
  },
});
