












































































import bankAccount from '@/store/types/bankAccount';
import { BankParams } from '@/store/interfaces/bankAccount';
import bankModal from './bank/bankAccountModal.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import SweetAlert from '@/util/classes/SweetAlert';
import company from '@/store/types/company';
import contact from '@/store/types/contact';
import { DEFAULT_EMPTY_VALUE } from '@/util/consts';
import { billingType } from '@/util/transformString/translationsDecorator';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
const notification = new TeipNotifications();

type tContactInfo = { title: string; key: string }[];
type tBankInfo = {
  title: string;
  key: string;
  fn?: (value: string) => string;
};

export default Vue.extend({
  name: 'BankAccountCompany',
  components: {
    bankModal,
  },
  data: () => ({
    viewModal: false,
    loading: true,
    bankInfo: [
      {
        title: 'Banco',
        key: 'bank_id',
      },
      {
        title: 'Cuenta',
        key: 'name',
      },
      {
        title: 'Tipo de cuenta',
        key: 'account_type',
      },
      {
        title: 'Cuenta bancaria',
        key: 'account_number',
      },
      {
        title: 'Dirección de facturación',
        key: 'billing_address',
      },
      {
        title: 'Titular de la cuenta',
        key: 'account_holder',
      },
      {
        title: 'Tipo de facturación',
        key: 'billing_type',
        fn: billingType,
      }
    ] as tBankInfo[],
    contactInfo: [
      {
        title: 'Nombre',
        key: 'name',
      },
      {
        title: 'Teléfono',
        key: 'phone',
      },
      {
        title: 'Email',
        key: 'email',
      }
    ] as tContactInfo,
  }),
  async created() {
    await this.bankAccount(this.$route.params.company_id);
    const company_id = this.$route.params.company_id;
    this.ListContactByCompany({ company_id, });
    if (
      Object.keys(this.getSingleCompany).length === 0 ||
      this.getSingleCompany.id !== company_id
    ) {
      await this.singleCompanyAction(company_id);
    }

    this.$data.loading = false;
  },

  computed: {
    ...mapGetters({
      getBankAccount: `companyModule/bankAccount/${bankAccount.getters.GET_BANK_ACCOUNT}`,
      getBanks: `companyModule/bankAccount/${bankAccount.getters.GET_BANKS_LIST}`,
      getListContactByCompany: `contactModule/${contact.getters.GET_CONTACT_BY_COMPANY}`,
      getShowDeleteButton: `companyModule/bankAccount/${bankAccount.getters.GET_SHOW_DELETE_BUTTON}`,
      getSingleCompany: `companyModule/${company.getters.GET_SINGLE_COMPANY}`,
    }),
  },

  methods: {
    ...mapActions({
      bankAccount: `companyModule/bankAccount/${bankAccount.actions.BANK_ACCOUNT}`,
      removeBankAccount: `companyModule/bankAccount/${bankAccount.actions.DELETE_BANK_ACCOUNT}`,
      singleCompanyAction: `companyModule/${company.actions.SINGLE_COMPANY}`,
      ListContactByCompany: `contactModule/${contact.actions.LIST_CONTACT_BY_COMPANY}`,
    }),

    printBankInfo(info: tBankInfo): string {
      if (info.fn) {
        return info.fn(this.getBankAccount[info.key]);
      } else if (info.key === 'bank_id') {
        return this.searchBank(this.getBankAccount[info.key]);
      } else if (info.key === 'account_type') {
        return this.accountType(this.getBankAccount[info.key]);
      }
      return this.getBankAccount[info.key];
    },

    deleteBankAccount(): void {
      const swal = new SweetAlert();
      swal
        .askingSweetAlert({
          title: 'Borrar cuenta bancaria',
          text: '¿Seguro que desea eliminar la cuenta?',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sí, eliminar',
          alertType: 'warning',
        })
        .then((res) => {
          if (res) {
            this.removeBankAccount(this.$route.params.company_id).catch(() => {
              notification.warning('Ha ocurrido un error al eliminar :(');
            });
          }
        });
    },

    searchBank(bank_id: string): string {
      const bankName = this.getBanks.filter(
        (bank: BankParams) => bank.id === bank_id
      )[0];
      return bankName ? bankName.name : DEFAULT_EMPTY_VALUE;
    },

    accountType(bank_type: string): string {
      let type = DEFAULT_EMPTY_VALUE;
      if (bank_type === 'savings') {
        type = 'Ahorros';
      } else if (bank_type === 'current') {
        type = 'Corriente';
      }
      return type;
    },
  },
});
