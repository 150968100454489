





































import branchs from '@/store/types/branchs';
import { branchSignature } from '@/store/interfaces/branchs';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { TokenColorPrimaryFillMidDay } from 'glue-design';

export default Vue.extend({
  name: 'QrCodeGeneration',
  data() {
    return {
      TokenColorPrimaryFillMidDay: TokenColorPrimaryFillMidDay,
      signature: '',
      branchSignature: {
        id: '',
        signature: '',
        virtualBranchName: '',
        branch: {
          id: '',
          name: '',
        },
        commerce: {
          id: '',
          name: '',
        },
        company: {
          id: '',
          name: '',
        },
      } as branchSignature,
    };
  },
  async mounted(): Promise<void> {
    await this.branchesSignature({
      company_id: this.$route.params.company_id,
    });
  },
  computed: {
    ...mapGetters({
      getBranchsSignatures: `commerceModule/branches/${branchs.getters.GET_BRANCHS_SIGNATURES}`,
    }),
    stringQRCodeGenerator(): string {
      // eslint-disable-next-line max-len
      return `${process.env.VUE_APP_URL_QR}/?c=${this.$data.branchSignature.branch.id}.${this.$data.branchSignature.signature}`;
    },
  },
  methods: {
    ...mapActions({
      branchesSignature: `commerceModule/branches/${branchs.actions.BRANCHS_SIGNATURES}`,
    }),
    findBranchNameBySignature(signature: string): void {
      this.$data.branchSignature = this.getBranchsSignatures.data.find(
        (sign: branchSignature) => {
          return sign.signature === signature;
        }
      );
    },
  },
  watch: {
    signature(sign: string): void {
      if (sign) {
        this.findBranchNameBySignature(sign);
      }
    },
  },
});
