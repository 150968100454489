
























import branchsUser from '@/store/types/branchsUser';
import { AxiosError } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import updateUserForm from './form/user.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
const teipNotification = new TeipNotifications();

export default Vue.extend({
  name: 'UpdateBranchUsers',
  components: {
    updateUserForm,
  },
  data: () => ({
    errors: {},
    success: false,
  }),
  computed: {
    ...mapGetters({
      getUserBranch: `commerceModule/branches/users/${branchsUser.getters.GET_USER_BRANCHES}`,
      getSingleBranchUser: `commerceModule/branches/users/${branchsUser.getters.GET_SINGLE_USER_BRANCH}`,
    }),
  },
  created(): void {
    if (!this.getSingleBranchUser.id) {
      this.$router.push({
        name: 'users-braches',
      });
    }
  },
  methods: {
    ...mapActions({
      updateUser: `commerceModule/branches/users/${branchsUser.actions.UPDATE_BRANCH_USER}`,
    }),
    userUpdate(): void {
      const dataToSend = {
        data: {
          ...this.getSingleBranchUser,
        },
        user_branch_id: this.getSingleBranchUser.id,
      };
      this.updateUser(dataToSend)
        .then(() => {
          teipNotification.success('Usuario actualizado correctamente :)');
          this.$router.push({
            name: 'users-braches',
          });
          this.$data.success = true;
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 422) {
            this.$data.errors = err.response.data.error;
          } else {
            teipNotification.warning(
              'Ha ocurrido un error al tratar de actualizar el usuario :('
            );
          }
        })
        .finally(() => {
          this.$data.success = false;
        });
    },
  },
});
