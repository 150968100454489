












































import enterpriseAddress from '@/components/Company/newCompany/companyInfo/enterpriseAddress.vue';
import enterpriseData from '@/components/Company/newCompany/companyInfo/enterpriseData.vue';
import commissions from '@/components/Company/newCompany/companyInfo/forms/commissions.vue';
import { EnterpriceCommissions } from '@/store/interfaces/company';
import companyType from '@/store/types/company';
import root from '@/store/types/root';
import { dayjsUTC } from '@/util/classes/datesFormat';
import copyObj from '@/util/copyObj';
import { TeipNotifications } from '@/util/teipNotifications';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ProfileCompanyUpdateCompany',
  components: {
    commissions,
    enterpriseData,
    enterpriseAddress,
  },

  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters({
      getSingleCompany: `companyModule/${companyType.getters.GET_NEW_COMPANY_PARAMS}`,
      getProcess: root.getters.GET_START_PROCESS,
    }),
  },
  created() {
    // Formats date
    if (this.getSingleCompany.commission) {
      const start_at = this.getSingleCompany.commission.start_at;
      this.getSingleCompany.commission.start_at = dayjsUTC(start_at)
        .format('YYYY-MM-DD');

      this.getSingleCompany.commission.fixed_fee_cents = this.getSingleCompany.commission.fixed_fee_cents / 100;
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      updateCompany: `companyModule/${companyType.actions.UPDATE_COMPANY}`,
    }),
    getCommissions(val: EnterpriceCommissions) {
      this.getSingleCompany.commission = val;
    },
    updateProfile() {
      const notificacion = new TeipNotifications();
      const companyToUpdate = copyObj(this.getSingleCompany);

      if (companyToUpdate.commission && 'fixed_fee_cents' in companyToUpdate.commission) {
        companyToUpdate.commission.fixed_fee_cents = companyToUpdate.commission.fixed_fee_cents * 100;
      }

      if ('address' in companyToUpdate) {
        companyToUpdate.address.phone = companyToUpdate.address.phone.replace(/\s+/g, '');
      }
      companyToUpdate.phone = companyToUpdate.phone.replace(/\s+/g, '');

      if ('bank_account' in companyToUpdate && companyToUpdate.bank_account.account_number === '') {
        delete companyToUpdate.bank_account;
      }

      if ('naka_company_configuration' in companyToUpdate &&
      companyToUpdate.naka_company_configuration.exchange_fee_id === '') {
        delete companyToUpdate.naka_company_configuration;
      }

      const toUpdate = {
        data: companyToUpdate,
        company_id: this.$route.params.company_id,
      };

      delete companyToUpdate.contacts;

      this.updateCompany(toUpdate)
        .then(() => {
          notificacion.success('Actualizado correctamente');
          this.$emit('cancel', true);
        })
        .catch(() => {
          notificacion.warning('Algo ha salido mal');
        });
    },
    cancel() {
      this.$emit('cancel', true);
    },
  },
  watch: {
    'getSingleCompany.state_code'(val: string) {
      if (this.getSingleCompany.address) {
        this.getSingleCompany.address.state_code = val;
      }
    },
  },
});

