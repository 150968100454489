export default {
  mutations: {
    SET_PAYMENTS_INTENT_BY_TRANSACTION: 'SET_PAYMENTS_INTENT_BY_TRANSACTION',
  },
  getters: {
    GET_PAYMENTS_INTENT_BY_TRANSACTION: 'GET_PAYMENTS_INTENT_BY_TRANSACTION',
  },
  actions: {
    PAYMENTS_INTENT_BY_TRANSACTION: 'PAYMENTS_INTENT_BY_TRANSACTION',
  },
};
