import router from '@/routes/router';
import { AuthState, AuthTokens, AuthUserParams } from '@/store/interfaces/auth';
import { RootState } from '@/store/interfaces/rootState';
import authType from '@/store/types/auth';
import RefreshToken from '@/util/classes/refreshTokenTime';
import { http } from '@/util/HTTP';
import { objectValueNull } from '@/util/transformString/nullValidation';
import { AxiosResponse, AxiosError } from 'axios';
import crypto from 'crypto';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { base64URLEncode, sha256 } from '../../util/auth';
import { iCompanyConfiguration } from '../interfaces/configurations';

const namespaced = true;
export type UserLogin = {
  email: string
  password?: string
}

const initialState = (): AuthState => {
  return {
    userSession: {
      access_token: '',
      created_at: 0,
      expires_in: 0,
      refresh_token: '',
      scope: '',
      token_type: '',
    },
    token: '',
    permissions: [],
    status: 'not_logged',
    canSeeCommerces: true,
    currentUser: {
      email: '',
      profile: {
        first_name: '',
        last_name: '',
        gender: '',
        phone_number: '',
        birthdate: '',
        avatar_url: '',
      },
      permission_groups: [],
    },
  };
};

const state: AuthState = initialState();
const getters: GetterTree<AuthState, RootState> = {
  [authType.getters.AUTH_STATUS]: (state): string => {
    return state.status;
  },
  [authType.getters.IS_AUTHENTICATED]: state => {
    return !!state.token;
  },
  [authType.getters.AUTH_PERMISSIONS]: state => {
    return state.canSeeCommerces;
  },
  [authType.getters.PERMISSIONS]: state => {
    return state.permissions;
  },
  [authType.getters.GET_AUTH_USER]: state => {
    return objectValueNull(state.currentUser);
  },
  [authType.getters.GET_USER_SESSION]: state => {
    return state.userSession;
  },
};
const mutations: MutationTree<AuthState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.keys(initialStateFields).forEach(key => {
      state[key] = initialStateFields[key];
    });
  },
  [authType.mutations.AUTH_REQUEST]: state => {
    state.status = 'loading';
  },
  [authType.mutations.AUTH_PERMISSIONS]: (state, resp: boolean) => {
    state.canSeeCommerces = resp;
  },
  [authType.mutations.AUTH_SUCCESS]: (state, resp: AuthTokens) => {
    state.status = 'success';
    state.userSession = resp;
    state.token = resp.access_token;
  },
  [authType.mutations.AUTH_ERROR]: state => {
    state.status = 'error';
  },
  [authType.mutations.AUTH_LOGOUT]: state => {
    state.token = '';
  },
  [authType.mutations.SET_AUTH_USER]: (state, data: AuthUserParams) => {
    state.currentUser = data;
    state.permissions = data.permission_groups
      .map(p => p.permissions)
      .flat()
      .map(p => p.slug);
  },
  [authType.mutations.SET_UPDATE_AUTH_USER]: (
    state,
    { first_name, last_name, }
  ) => {
    state.currentUser.profile.first_name = first_name;
    state.currentUser.profile.last_name = last_name;
  },
  [authType.mutations.SET_TOKEN_CONFIGURATION]: (state, token: AuthTokens) => {
    localStorage.setItem('_token', token.access_token);
    localStorage.setItem('userSession', JSON.stringify(token));
    http.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [authType.mutations.SET_CONCAT_FEATURES_FLAG_TO_THE_PERMISSIONS]:
    (state, configurations: { data: iCompanyConfiguration }) => {
      const featuresFlagAllowed = Object.entries(configurations.data.features)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, value]) => value)
        .map(([key]) => key);

      state.permissions = state.permissions.concat(featuresFlagAllowed);
    },
};
const actions: ActionTree<AuthState, RootState> = {
  [authType.actions.AUTHORIZE]: async ({ commit, dispatch, }, user: UserLogin) => {
    try {
      const token: AxiosResponse = await getToken();

      const getUserToken = {
        grant_type: 'password',
        email: user.email,
        password: user.password,
        scope: 'staff',
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      if (/@ayuda.teip.io\s*$/.test(user.email)) {
        commit(authType.mutations.AUTH_PERMISSIONS, false);
      }
      const userToken: AxiosResponse = await http.post(
        'oauth/token',
        getUserToken,
        config
      );
      commit(authType.mutations.AUTH_SUCCESS, userToken.data);
      commit(authType.mutations.SET_TOKEN_CONFIGURATION, userToken.data);
      dispatch(authType.actions.EXPIRES_IN);

      const currentUser: AxiosResponse = await http.get('backoffice/profile');

      commit(authType.mutations.SET_AUTH_USER, currentUser.data.data);
    } catch (err) {
      throw new Error('Unable to get a token.' + err);
    }
  },
  [authType.actions.SEND_PASSWORD_INSTRUCTIONS]: async (
    _,
    user: UserLogin
  ) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };

      await http.post(
        'accounts/passwords',
        { data: { email: user.email, }, },
        config
      );
    } catch (err) {
      throw new Error('Unable to get a token.' + err);
    }
  },
  [authType.actions.RESET_PASSWORD]: async (_, data) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      await http.put('accounts/passwords', { data: data, }, config);
    } catch (err) {
      throw new Error('Unable to get a token.' + err);
    }
  },
  [authType.actions.SEND_UNLOCK_INSTRUCTIONS]: async (
    _,
    user: UserLogin
  ) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };

      await http.post(
        'accounts/unlocks',
        { data: { email: user.email, }, },
        config
      );
    } catch (err) {
      throw new Error('Unable to get a token.' + err);
    }
  },
  [authType.actions.UNLOCK_ACCOUNT]: async (_, code: string) => {
    try {
      const token: AxiosResponse = await getToken();

      const config = {
        headers: {
          Authorization: `Bearer ${token.data.access_token}`,
        },
      };
      await http.post(`accounts/unlocks/${code}`, {}, config);
    } catch (err) {
      throw new Error('Unable to get a token.' + err);
    }
  },
  [authType.actions.AUTH_LOGOUT]: ({ commit, }) => {
    return new Promise(resolve => {
      commit(authType.mutations.AUTH_LOGOUT);
      localStorage.removeItem('_token');
      localStorage.removeItem('userSession');
      localStorage.removeItem('teip');
      localStorage.removeItem('translations');
      commit('reset');
      resolve(true);
    });
  },
  [authType.actions.REFRESH_TOKEN]: async ({ state, commit, dispatch, }) => {
    if (state.token) {
      const getUserToken = {
        refresh_token: state.userSession.refresh_token,
        grant_type: 'refresh_token',
      };

      const config = {
        headers: {
          Authorization: `Bearer ${state.userSession.access_token}`,
        },
      };
      http
        .post('oauth/token', getUserToken, config)
        .then((res: AxiosResponse) => {
          commit(authType.mutations.AUTH_SUCCESS, res.data);
          commit(authType.mutations.SET_TOKEN_CONFIGURATION, res.data);
          dispatch(authType.actions.EXPIRES_IN);
        })
        .catch(() => {
          dispatch(authType.actions.AUTH_LOGOUT);
          router.push({ name: 'Login', });
        });
    }
  },
  [authType.actions.EXPIRES_IN]: ({ state, dispatch, }) => {
    const timeToExpiredToken = new RefreshToken(state.userSession);
    const time = timeToExpiredToken.timeToRefresh();
    // time must not be exceed 2147483647 milliseconds
    if (time > 0) {
      setTimeout(() => {
        dispatch(authType.actions.REFRESH_TOKEN);
      }, time);
    } else {
      dispatch(authType.actions.REFRESH_TOKEN);
    }
  },
  [authType.actions.UPDATE_MY_PROFILE]: ({ commit, state, }, data) => {
    const dataToServer = {
      first_name: data.firstName,
      last_name: data.lastName,
      id: state.currentUser.id,
    };
    return new Promise((resolve, reject) => {
      http
        .put('backoffice/profile', { data: dataToServer, })
        .then((res: AxiosResponse) => {
          commit(
            `authModule/${authType.mutations.SET_UPDATE_AUTH_USER}`,
            res.data.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export const getToken = async (UID: string | undefined = process.env.VUE_APP_UID):
  Promise<AxiosResponse> => {
  const REDIRECT_URI: string | undefined = process.env.VUE_APP_REDIRECT_URI;
  const verifier = base64URLEncode(crypto.randomBytes(32));
  const codeChallenge = base64URLEncode(sha256(verifier));
  const authorize = {
    client_id: UID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  };

  try {
    const code: AxiosResponse = await http.post(
      'oauth/application/authorize',
      authorize
    );
    const getToken = {
      client_id: UID,
      code: code.data.redirect_uri.code,
      code_verifier: verifier,
      redirect_uri: REDIRECT_URI,
      grant_type: 'authorization_code',
    };
    const token: AxiosResponse = await http.post(
      'oauth/application/token',
      getToken
    );
    return token;
  } catch (err) {
    throw new Error('Unable to get a token.' + err);
  }
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
