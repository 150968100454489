/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  TransactionParams,
  transactionsNotAffiliated,
  TransactionState
} from '@/store/interfaces/transactions';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

// Establece los types de los modulos store
import transactionType from '@/store/types/transaction';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): TransactionState => {
  return {
    transactions: {
      data: [],
      pagination: {},
    },
    transactionsNotAffiliated: {
      data: [],
      pagination: {},
    },
    transaction: {
      authorization_number: '',
      branch_name: '',
      card_last_digits: '',
      card_network: '',
      biometrics: false,
      city_code: '',
      commerce_name: '',
      country_code: '',
      created_at: '',
      discount_cents: 0,
      discount_currency: '',
      order_kind: 'qr',
      kind_name: '',
      discount_formatted: '',
      id: '',
      latitude: '',
      logo: '',
      longitude: '',
      reference_number: '',
      response_code: '',
      risk: '',
      state_code: '',
      status: 'pending',
      subtotal_cents: 0,
      subtotal_currency: '',
      subtotal_formatted: '',
      tip_cents: 0,
      tip_currency: '',
      tip_formatted: '',
      total_cents: 0,
      total_currency: '',
      total_formatted: '',
      updated_at: '',
      user_name: '',
      device_name: '',
      device_platform: '',
      device_version: '',
      device_vendor: '',
      requestable_type: '',
      requestable_id: '',

      user_id: '',
      balance_amount_cents: null,
      balance_amount_currency: null,
      balance_amount_formatted: null,
      card_amount_cents: null,
      card_amount_currency: null,
      card_amount_formatted: null,
      description: '',
      distribution: ['not_assigned'],
      payment_distributions: [],
      expires_at: 1,
    },
  };
};

const state: TransactionState = initialState();

const getters: GetterTree<TransactionState, any> = {
  [transactionType.getters.GET_FLAGGED_TRANSACTIONS]: state => {
    return state.transactions;
  },
  [transactionType.getters.GET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED]: state => {
    return state.transactionsNotAffiliated;
  },
  [transactionType.getters.GET_FLAGGED_TRANSACTION]: state => {
    return { data: state.transaction, };
  },
  [transactionType.getters.GET_POSTMORTEM_TRANSACTIONS]: state => {
    return state.transactions;
  },
};
const mutations: MutationTree<TransactionState> = {

  [transactionType.mutations.SET_FLAGGED_TRANSACTIONS]: (
    state,
    transactions: responseCollection<TransactionParams>
  ) => {
    state.transactions.data = transactions.data;
  },
  [transactionType.mutations.SET_FLAGGED_TRANSACTION]: (
    state,
    transacction: TransactionParams
  ) => {
    state.transaction = transacction;
  },
  [transactionType.mutations.SET_POSTMORTEM_TRANSACTIONS]: (
    state,
    transactions: responseCollection<TransactionParams>
  ) => {
    state.transactions.data = transactions.data;
    state.transactions.pagination = transactions.meta.pagination;
  },
  [transactionType.mutations.SET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED]: (
    state,
    transactions: responseCollection<transactionsNotAffiliated>
  ) => {
    state.transactionsNotAffiliated.data = transactions.data;
    state.transactionsNotAffiliated.pagination = transactions.meta.pagination;
  },
};

const actions: ActionTree<TransactionState, any> = {
  [transactionType.actions.FLAGGED_TRANSACTION_LIST]: (
    { commit, },
    queryParams = ''
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/payment_requests?${queryParams}`)
        .then((res: AxiosResponse) => {
          resolve(res);
          commit(transactionType.mutations.SET_FLAGGED_TRANSACTIONS, res.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionType.actions.FLAGGED_TRANSACTION]: ({ commit, }, id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/payment_requests/${id}`)
        .then((res: AxiosResponse) => {
          commit(
            transactionType.mutations.SET_FLAGGED_TRANSACTION,
            res.data.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionType.actions.POSTMORTEM_TRANSACTION_LIST]: (
    { commit, },
    queryParams = ''
  ) => {
    return new Promise((resolve, reject) => {
      const query = queryParams || '&page=1&per_page=10';
      http
        .get(`backoffice/payment_requests?${query}`)
        .then((res: AxiosResponse) => {
          commit(
            transactionType.mutations.SET_POSTMORTEM_TRANSACTIONS,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionType.actions.TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED]: (
    { commit, },
    query
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/remote_payment_requests?${query}`)
        .then((res: AxiosResponse) => {
          commit(
            transactionType.mutations.SET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionType.actions.MONITOR_TRANSACTION_LIST]: (
    { commit, },
    queryParams = ''
  ) => {
    return new Promise((resolve, reject) => {
      const query = queryParams || '&page=1&per_page=5';
      http
        .get(`backoffice/payment_requests?status_not_eq=pending${query}`)
        .then((res: AxiosResponse) => {
          commit(
            transactionType.mutations.SET_POSTMORTEM_TRANSACTIONS,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionType.actions.PAYMENT_REFUND]: (_, { id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .post(`backoffice/payment_requests/${id}/refund`, { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
