/**
 * @param e KeyboardEvent
 * @param value string of numbers
 * @returns void
 */

export const currencyEvent = (e: KeyboardEvent, value: string): void => {
  const valueAsArray = value.split('.');

  // value has .
  const alreadyHavePountDecimal = valueAsArray.length - 1 === 1;

  // Determinate number of decimals
  const hasTwoDecimals = valueAsArray[1] && valueAsArray[1].length === 2;
  if (hasTwoDecimals) e.preventDefault();

  if (!isNaN(Number(e.key)) || (e.key === '.' && !alreadyHavePountDecimal)) return;

  e.preventDefault();
};

/**
 * @param e KeyboardEvent
 * @param value number
 * @returns void
 */

const currencyKeyboardEventValidation = (e: KeyboardEvent, value: number): void => {
  const valueAsArray = String(value).split('.');

  if (((!isNaN(Number(e.key)) && valueAsArray[1] !== undefined && valueAsArray[1].length < 2)) ||
    e.key === '.' ||
    (!isNaN(Number(e.key)) && valueAsArray.length === 1)) return;

  e.preventDefault();
};

export { currencyKeyboardEventValidation };
