import { BuyersParams, BuyersState } from '@/store/interfaces/buyers';
import { RootState } from '@/store/interfaces/rootState';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection } from '@/store/interfaces/json-api/generics';
import buyers from '../types/buyers';

const namespaced = true;

const initialState = (): BuyersState => {
  return {
    buyers: {
      data: [],
      pagination: {},
    },
  };
};

const state: BuyersState = initialState();

const getters: GetterTree<BuyersState, RootState> = {
  [buyers.getters.GET_BUYERS]: state => {
    return state.buyers;
  },
};
const mutations: MutationTree<BuyersState> = {
  [buyers.mutations.SET_BUYERS]: (state, buyers: responseCollection<BuyersParams>) => {
    state.buyers.data = buyers.data;
    state.buyers.pagination = buyers.meta.pagination;
  },
};
const actions: ActionTree<BuyersState, RootState> = {
  [buyers.actions.BUYERS_LIST]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/buyer_profiles')
        .then((res: AxiosResponse) => {
          commit(buyers.mutations.SET_BUYERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
