















































import uploadImagesMixin from '@/mixins/uploadImagesMixin';
import Vue from 'vue';
import imagePreview from './imagePreview.vue';

export default Vue.extend({
  name: 'UploadFiles',
  components: {
    imagePreview,
  },
  mixins: [uploadImagesMixin],
});
