export const transactionHistoryMonitorPermissions = [
  'index_stats_payment_intents',
  'index_payment_requests',
  'transactions',
  'transactions.summary'
];

export const transactionHistoryPermissions = [
  'index_payment_requests',
  'transactions',
  'transactions.p2b_transactions'
];

export const buyersPermissions = [
  'index_buyer_profiles', 'buyers'
];

export const transactionHistoryNotAffiliatedPermissions = [
  'index_remote_payment_requests',
  'transactions',
  'transactions.quickpay'
];

export const transferHistoryPermissions = [
  'index_transfer_requests',
  'transactions',
  'transactions.p2p_transactions'
];

export const ticketPermissions = [
  'index_tickets',
  'support'
];

export const liquidationPermissions = [
  'index_liquidations',
  'settlements',
  'settlements.liquidations'
];

export const settlementsPermissions = [
  'index_settlements_settlement',
  'settlements',
  'settlements.settlements'
];

export const companiesPermissions = [
  'index_companies',
  'commerces',
  'commerces.companies'
];

export const commercePermissions = [
  'index_commerces',
  'commerces',
  'commerces.commerces'
];

export const draftCompanyPermissions = [
  'index_drafts_companies',
  'commerces',
  'commerces.inbound'
];

export const commerceKindsPermissions = [
  'index_commerce_kinds',
  'commerces',
  'commerces.categories'
];

export const staffPermissions = [
  'index_staffs',
  'staff',
  'staff.users'
];

export const permissionsOfPermissions = [
  'index_permission_groups',
  'staff',
  'staff.permissions'
];

export const customerUserPermissions = [
  'index_customers_users',
  'customers'
];

export const discountPermissions = [
  'index_discounts',
  'marketing',
  'marketing.discounts'
];

export const cashbackPermissions = [
  'index_cashbacks',
  'marketing',
  'marketing.cashbacks'
];

export const withdrawalsPermissions = [
  'index_withdrawals',
  'withdrawals'
];
