import authType from '@/store/types/auth';
import { DirectiveBinding, VNode } from 'vue';
/**
 *
 * @param {string[] | string} permissions
 * @param {VNode} vnode
 * @returns boolean
 */
export const hasPermissionTo = (permissions, vnode) => {
  if (vnode.context) {
    const permissionsFromServer = vnode.context.$store.getters[`authModule/${authType.getters.PERMISSIONS}`];

    if (typeof permissions === 'string') {
      return permissionsFromServer.some((val) => val === permissions);
    }
    return permissions.every((permission) => permissionsFromServer.includes(permission));
  }
  return new Error(`vnode.context is undefined for: ${JSON.stringify(permissions)}`);

};

/**
 * @param {HTMLElement | HTMLButtonElement} el
 * @returns void
 * */
const removeElement = (el) => {
  const parentNode = el.parentNode;
  if (parentNode) {
    parentNode.removeChild(el);
  } else {
    el.setAttribute('style', 'display: none;')
  }
};

export default {
  /**
   *
   * @param {HTMLElement} el
   * @param {DirectiveBinding} binding
   * @param {import('vue').VNode} vnode
   */
  bind: function (el, binding, vnode) {
    const { value, arg } = binding;

    if (arg === 'at-least-one') {
      const hasPermission = value.some((permission) => hasPermissionTo(permission, vnode));
      if (!hasPermission) {
        removeElement(el);
      }
      return;
    }

    const hasPermission = hasPermissionTo(value, vnode);

    if (!hasPermission) {
      removeElement(el);
    }
  },
  /**
   *
   * @param {HTMLElement} el
   * @param {DirectiveBinding} binding
   * @param {VNode} vnode
  */
  update: function (el, binding, vnode) {
    const { value, arg } = binding;

    if (arg === 'at-least-one') {
      const hasPermission = value.some((permission) => hasPermissionTo(permission, vnode));
      if (!hasPermission) {
        removeElement(el);
      }
      return;
    }
    const hasPermission = hasPermissionTo(value, vnode);

    if (!hasPermission) {
      removeElement(el);
    }
  },
};
