<template>
  <b-row>
    <b-col cols="6" class="mt-4">
      <h5 class="h4">Sucursal</h5>
      <div class="h5 font-weight-300">
        {{ getSingleBranch.name }}
      </div>
    </b-col>
    <b-col cols="6" class="mt-4">
      <h5 class="h4">Dirección</h5>
      <div class="h5 font-weight-300">
        {{ getSingleBranch.address.description }}
      </div>
    </b-col>
    <b-col cols="6" class="mt-4">
      <h5 class="h4">Teléfono</h5>
      <div class="h5 font-weight-300">
        {{ phoneFormat(getSingleBranch.address.phone) }}
      </div>
    </b-col>
    <b-col cols="6" class="mt-4">
      <h5 class="h4">Estado</h5>
      <div class="h5 font-weight-300">
        {{ getSingleBranch.status === 'inactive' ? 'Inactiva' : 'Activa' }}
      </div>
    </b-col>
    <b-col cols="6" class="mt-4">
      <h5 class="h4">Correo que recibe notificaciones</h5>
      <div class="h5 font-weight-300">
        {{ getSingleBranch.notification_email }}
      </div>
    </b-col>
  </b-row>
</template>
<script>
import branchs from '@/store/types/branchs';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { phoneFormat } from '@/util/phoneFormat';

export default Vue.extend({
  name: 'ShowBranchProfile',
  data: () => ({
    phoneFormat,
  }),
  computed: {
    ...mapGetters({
      getSingleBranch: `commerceModule/branches/${branchs.getters.GET_SINGLE_BRANCH}`,
    }),
  },
});
</script>
