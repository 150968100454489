export default {
  mutations: {
    SET_BANK_ACCOUNT: 'SET_BANK_ACCOUNT',
    SET_SHOW_DELETE_BUTTON: 'SET_SHOW_DELETE_BUTTON',
    SET_BANKS_LIST: 'SET_BANKS_LIST',
  },
  getters: {
    GET_BANK_ACCOUNT: 'GET_BANK_ACCOUNT',
    GET_SHOW_DELETE_BUTTON: 'GET_SHOW_DELETE_BUTTON',
    GET_BANKS_LIST: 'GET_BANKS_LIST',
  },
  actions: {
    BANKS_LIST: 'BANKS_LIST',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    SAVE_BANK_ACCOUNT: 'SAVE_BANK_ACCOUNT',
    DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
  },
};
