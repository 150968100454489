












import Vue from 'vue';
import permissionListForm from '../Permissions/permissionListForm.vue';
import { mapGetters } from 'vuex';
import auth from '@/store/types/auth';

export default Vue.extend({
  components: { permissionListForm, },
  name: 'PermissionStaffComponent',
  computed: {
    ...mapGetters({
      authUser: `authModule/${auth.getters.GET_AUTH_USER}`,
    }),
    canModifyIntermediary(): boolean {
      return this.authUser.id !== this.$route.params.user_id;
    },
  },
});
