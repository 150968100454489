


































import companyType from '@/store/types/company';
import { TeipNotifications } from '@/util/teipNotifications';
import updateForm from './forms/updateCompany.vue';
import showCommissions from './showInfo/commissions.vue';
import companyInfo from './showInfo/company.vue';
import showContact from './showInfo/contacts.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'ProfileCompanyComponents',
  components: {
    updateForm,
    companyInfo,
    showContact,
    showCommissions,
  },
  data: () => ({
    company: {
      business_name: '',
      address: {
        description: '',
        phone: '',
      },
    },
    edit: false,
  }),
  async created() {
    const direction = this.getSingleCompany.address?.description;
    this.$data.company.business_name = this.getSingleCompany.business_name;
    this.$data.company.address.description =
      direction !== undefined ? direction : '';
    this.$data.company.address.phone = this.getSingleCompany.address?.phone;
  },
  computed: {
    ...mapGetters({
      getSingleCompany: `companyModule/${companyType.getters.GET_SINGLE_COMPANY}`,
    }),
  },
  methods: {
    ...mapActions({
      updateCompany: `companyModule/${companyType.actions.UPDATE_COMPANY}`,
    }),
    cancel() {
      this.$data.edit = false;
    },
    updateProfile() {
      const notificacion = new TeipNotifications();
      const toUpdate = {
        data: this.$data.company,
        company_id: this.$route.params.company_id,
      };

      this.updateCompany(toUpdate)
        .then(() => {
          notificacion.success('Actualizado correctamente');
          this.$data.edit = false;
        })
        .catch(() => {
          notificacion.error('Algo ha salido mal');
        });
    },
  },
});
