export default {
  mutations: {
    SET_DISCOUNTS: 'SET_DISCOUNTS',
  },
  getters: {
    GET_DISCOUNTS: 'GET_DISCOUNTS',
  },
  actions: {
    SAVE_DISCOUNT: 'SAVE_DISCOUNT',
    EDIT_DISCOUNT: 'EDIT_DISCOUNT',
    DISCOUNT_LIST: 'DISCOUNT_LIST',
    DISCOUNT: 'DISCOUNT',
    DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  },
};
