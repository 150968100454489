
































import {
  CompanyCommerceParams
} from '@/store/interfaces/company_commerce';
import company_commerce from '@/store/types/company_commerce';
import { http } from '@/util/HTTP';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Currencies',
  data() {
    return {
      http,
      currencies: [] as string[],
      updating: false,
    };
  },
  computed: {
    ...mapGetters({
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
    }),
  },
  watch: {
    singleCompanyCommerce: {
      handler(company: CompanyCommerceParams) {
        this.currencies = company.currencies.map(
          (currency) => currency.currency_code
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async updateCodeAndStatus(): Promise<void> {
      const notification = new TeipNotifications();
      const id = this.singleCompanyCommerce.id;
      const currenciesFormated = this.currencies.map((currency) => ({
        currency_code: currency,
      }));

      http
        .put(`backoffice/company_commerces/${id}/update_currencies`, {
          data: { currencies: currenciesFormated, },
        })
        .then((res: AxiosResponse<{ data: CompanyCommerceParams }>) => {
          notification.success('¡Éxito!', 'Monedas actualizadas correctamente');
          this.$store.commit(
            `companyCommerceModule/${company_commerce.mutations.SET_SINGLE_COMPANY_COMMERCE}`,
            res.data.data,
            { root: true, }
          );
          this.$emit('success');
        })
        .catch(() => {
          notification.warning('¡Error!', 'Algo ha salido mal :(');
        });
    },
  },
});
