export default {
  mutations: {
    SET_CONFIGURATION: 'SET_CONFIGURATION',
    SET_I18N_LOCAL_MESSAGE: 'SET_I18N_LOCAL_MESSAGE',
    SET_HOST_CONFIGURATION: 'SET_HOST_CONFIGURATION',
  },
  getters: {
    GET_CONFIGURATION: 'GET_CONFIGURATION',
    GET_HOST_CONFIGURATION: 'GET_HOST_CONFIGURATION',
  },
  actions: {
    CONFIGURATION: 'CONFIGURATION',
    HOST_CONFIGURATION: 'HOST_CONFIGURATION',
  },
};
