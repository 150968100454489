export default {
  mutations: {
    SET_LIQUIDATION_KIND_LIST: 'SET_LIQUIDATION_KIND_LIST',
  },
  getters: {
    GET_LIQUIDATION_KIND_LIST: 'GET_LIQUIDATION_KIND_LIST',
  },
  actions: {
    LIQUIDATION_KIND_LIST: 'LIQUIDATION_KIND_LIST',
  },
};
