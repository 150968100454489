/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NavigationGuardNext, Route } from 'vue-router';
import { checkPermissions, ifAuthenticated } from '../routeValidation';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { ticketPermissions } from './permissions/index';

const CustomerSupport = () =>
  import(
    /* webpackChunkName: "CustomerSupport" */ '@/views/CustomerSupport/tickets.vue'
  );

const permissionsPages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'customer-support',
  children: [
    {
      path: '/customer-support',
      component: CustomerSupport,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, ticketPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};

export default permissionsPages;
