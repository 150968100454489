/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ifNotAuthenticated } from '../routeValidation';
import AuthenticationLayout from '@/views/Layout/AuthenticationLayout.vue';
const UnlockAccount = () =>
  import(/* webpackChunkName: "UnlockAccount" */ '@/views/UnlockAccount/SendUnlockInstructions.vue');

export default {
  path: '/unlock-account',
  component: AuthenticationLayout,
  beforeEnter: ifNotAuthenticated,
  children: [
    {
      path: '/unlock-account',
      name: 'SendUnlockAccountMail',
      component: UnlockAccount,
    },
    {
      path: '/unlock-account/:id',
      name: 'UnlockAccount',
      component: UnlockAccount,
    }
  ],
};
