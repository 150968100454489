export default {
  mutations: {
    SET_COMPANY: 'SET_COMPANY',
    SET_SINGLE_COMPANY: 'SET_SINGLE_COMPANY',
    SET_PREPARE_EDITABLE_COMPANY: 'SET_PREPARE_EDITABLE_COMPANY',
    SET_COMPANY_PAGINATION: 'SET_COMPANY_PAGINATION',
    SET_CLEAN: 'SET_CLEAN',
    SET_CONTACT: 'SET_CONTACT',
    SET_REMOVE_CONTACT: 'SET_REMOVE_CONTACT',
    SET_CLEAN_DATA: 'SET_CLEAN_DATA',
    SET_COMPANIES_BY_COMMERCE: 'SET_COMPANIES_BY_COMMERCE',
    SET_COMPANIES_PROFILE: 'SET_COMPANIES_PROFILE',
  },
  getters: {
    GET_COMPANY: 'GET_COMPANY',
    GET_SINGLE_COMPANY: 'GET_SINGLE_COMPANY',
    GET_NEW_COMPANY_PARAMS: 'GET_NEW_COMPANY_PARAMS',
    GET_COMPANIES_BY_COMMERCE: 'GET_COMPANIES_BY_COMMERCE',
    GET_COMPANIES_PROFILE_LIST: 'GET_COMPANIES_PROFILE_LIST',
  },
  actions: {
    COMPANY_LIST: 'COMPANY_LIST',
    SAVE_COMPANY: 'SAVE_COMPANY',
    UPDATE_COMPANY: 'UPDATE_COMPANY',
    SINGLE_COMPANY: 'SINGLE_COMPANY',
    COMPANIES_BY_COMMERCE_LIST: 'COMPANIES_BY_COMMERCE_LIST',
    COMPANY_DOCUMENTS: 'COMPANY_DOCUMENTS',
    SAVE_DOCUMENTS_OF_COMPANY: 'SAVE_DOCUMENTS_OF_COMPANY',
    DELETE_COMPANY_CONTACT_DOCUMENTS: 'DELETE_COMPANY_CONTACT_DOCUMENTS',
    COMPANIES_PROFILE: 'COMPANIES_PROFILE',
  },
};
