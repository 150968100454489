


























import { tDeviceTransactionInfo } from '@/store/interfaces/transactions';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Device',
  props: {
    device: {
      required: true,
      type: Object as PropType<tDeviceTransactionInfo>,
    },
  },
});
