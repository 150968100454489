














import formStaff from '@/components/Staff/form.vue';
import { itemsRoute, navLink } from '@/ts/navegationLinks';
import Vue from 'vue';

export default Vue.extend({
  name: 'NewStaffUser',
  components: {
    formStaff,
  },
  data: () => ({
    items: [
      {
        id: 1,
        icon: 'fas fa-user',
        title: 'Datos',
        route: 'user-profile',
      },
      {
        id: 2,
        icon: 'fas fa-lock',
        title: 'Permisos',
        route: 'user-config',
      }
    ] as itemsRoute[],
    navlink: [
      {
        name: 'Usuarios staff',
        active: false,
        to: '/staff',
      },
      {
        name: 'Nuevo usuario',
        active: true,
      }
    ] as navLink[],
  }),
});
