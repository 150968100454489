export default {
  mutations: {
    SET_COUNTRY: 'SET_COUNTRY',
    SET_STATE: 'SET_STATE',

  },
  getters: {
    GET_COUNTRY: 'GET_COUNTRY',
    GET_STATE: 'GET_STATE',
  },
  actions: {
    COUNTRY_LIST: 'COUNTRY_LIST',
    STATE_LIST: 'STATE_LIST',
  },
};
