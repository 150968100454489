




























import { status, risk } from '@/util/transformString/translationsDecorator';
import { tTransactionModal } from '@/store/interfaces/transactions';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'Transaction',
  props: {
    transaction: {
      required: true,
      type: Object as PropType<tTransactionModal>,
    },
  },
  data: () => ({
    risk,
    status,
  }),
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    isCreditCardOrBot(): boolean {
      return (
        this.transaction.distribution.includes('credit_card') ||
        this.transaction.distribution.includes('both')
      );
    },
  },
});
