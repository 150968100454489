















































































import document from '@/store/types/document';
import root from '@/store/types/root';
import copyObj from '@/util/copyObj';
import { IObjectKeys } from '@/store/interfaces/generic';
import { mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';
import { DocumentKindParams } from '@/store/interfaces/document';

export interface Document {
  company_id: string;
  document: string;
  document_type_name: string;
  id: string;
  identity_code: string;
  reference: string;
  status: string;
}

export default Vue.extend({
  name: 'UpdateDocumentsModal',
  props: {
    viewModal: {
      type: Boolean,
      required: true,
    },
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
  },
  data: () => ({
    document_kind_id: '',
    document_reference: '',
    defaultPositionTypeDocument: 1,
  }),
  watch: {
    document: {
      deep: true,
      immediate: true,
      handler(document: Document): void {
        if (Object.keys(document).length > 0) {
          const dc = this.getListDocumetKind.data.findIndex(
            (doc: DocumentKindParams) =>
              doc.name === document.document_type_name
          );
          this.$data.defaultPositionTypeDocument = dc + 1;
          this.$data.document_reference = document.reference;
          if (dc !== -1) {
            this.$data.document_kind_id = this.getListDocumetKind.data[dc].id;
          }
        }
      },
    },
  },
  methods: {
    cancelUploadDocument(): void {
      this.syncedViewModal = false;
    },
    async uploadingDocument(): Promise<void> {
      const data: IObjectKeys = copyObj(this.document);
      data.reference = this.$data.document_reference;
      data.document_kind_id = this.$data.document_kind_id;
      let url = 'company_documents';
      if (this.$route.name === 'contacts-documents') {
        url = 'contact_documents';
      }
      const dataToSend = {
        url,
        document_id: this.document.id,
        data,
      };
      this.$emit('udpate', dataToSend);
    },
    updateImage(): void {
      this.$emit('updateImage', this.document);
    },
  },
  computed: {
    ...mapGetters({
      getProcess: `${root.getters.GET_START_PROCESS}`,
      getListDocumetKind: `documentModule/${document.getters.GET_DOCUMENT_KIND}`,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.$props.viewModal;
      },
      set(val: boolean): void {
        this.$emit('update:viewModal', val);
      },
    },
  },
});
