/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import usersPages from '@/routes/menus/accountsUserApp';
import { LoginRoutes } from './menus/login';
import ForgotPassword from './menus/forgotPassword';
import UnlockAccount from './menus/unlockAccount';
import noveltiesAndPromotions from '@/routes/menus/noveltiesAndPromotions';
import liquidationsPages from '@/routes/menus/liquidations';
import transactionsTicketsPages from '@/routes/menus/transactions';
import usersStaff from './menus/usersStaff';
import myProfile from './menus/myProfile';
import permissions from './menus/Permissions';
import customerSupport from './menus/customerSupport';
import Accounts from './menus/accounts';
import Buyers from './menus/buyers';
import commerceKind from './menus/commerceKinds';
import refundByTransfer from './menus/refundByTransfers';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import NotAllowed from '@/views/GeneralViews/NotAllowedPage.vue';

import { ifAuthenticated } from './routeValidation';
// Login
import commercePages from '@/routes/menus/commerceCompany';

const DashboardLayout = () =>
  import(/* webpackChunkName: "DashboardLayout" */ '@/views/Layout/DashboardLayout.vue');
// Home
const Home = () =>
  import(/* webpackChunkName: "Home" */ '@/views/Home/Home.vue');

const homePages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        navbarType: 'light',
      },
    }
  ],
};

const notAllowed = {
  path: '/403',
  name: 'not-allowed',
  component: NotAllowed,
  meta: {
    navbarType: 'light',
  },
};

const notFoundPages = {
  path: '*',
  name: 'not-found',
  component: NotFound,
  meta: {
    navbarType: 'light',
  },
};

const routes = [
  LoginRoutes,
  ForgotPassword,
  homePages,
  commercePages,
  transactionsTicketsPages,
  liquidationsPages,
  usersPages,
  usersStaff,
  noveltiesAndPromotions,
  notFoundPages,
  myProfile,
  Buyers,
  UnlockAccount,
  permissions,
  customerSupport,
  commerceKind,
  notAllowed,
  Accounts,
  refundByTransfer
];

export default routes;
