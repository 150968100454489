/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import commerceType from '@/store/types/branchs';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import {
  BranchParams,
  branchSignature,
  BranchState,
  newBranchParams
} from '@/store/interfaces/branchs';
import { ContactParams } from '@/store/interfaces/contact';
import branchsUser from './branchsUser';
import { objectValueNull } from '@/util/transformString/nullValidation';
import { responseCollection, responseSingle } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const state: BranchState = {
  branchSignatures: { data: [], pagination: {}, },
  single_branch: {
    created_at: '',
    address: {
      description: '',
      phone: '',
      state_code: '',
    },
    commerce_id: '',
    company_id: '',
    id: '',
    name: '',
    status: 'inactive',
    users_count: 0,
    notification_email: '',
    contacts: [
      {
        name: '',
        phone: '',
        email: '',
        contact_kind_id: '',
      }
    ],
  },
  branchesByCompany: {
    data: [],
    pagination: {},
  },
  newBranchParams: {
    address: {
      description: '',
      phone: '',
      state_code: '',
    },
    name: '',
    status: 'inactive',
    contacts: [
      {
        name: '',
        phone: '',
        email: '',
        contact_kind_id: '',
      }
    ],
  },
  branchesByCommerce: {
    data: [],
    pagination: {},
  },
};
const getters: GetterTree<BranchState, any> = {
  [commerceType.getters.GET_BRANCH_BY_COMPANY]: state => {
    return state.branchesByCompany;
  },
  [commerceType.getters.GET_NEW_BRANCH_PARAMS]: state => {
    return state.newBranchParams;
  },
  [commerceType.getters.GET_BRANCH_BY_COMMERCE]: state => {
    return state.branchesByCommerce;
  },
  [commerceType.getters.GET_SINGLE_BRANCH]: state => {
    return state.single_branch;
  },
  [commerceType.getters.GET_BRANCHS_SIGNATURES]: state => {
    return state.branchSignatures;
  },
};
const mutations: MutationTree<BranchState> = {
  [commerceType.mutations.SET_BRANCHES_BY_COMPANY]: (
    state,
    commerces: responseCollection<BranchParams>
  ) => {
    state.branchesByCompany.data = commerces.data;
    state.branchesByCompany.pagination = commerces.meta.pagination;
  },
  [commerceType.mutations.SET_NEW_BRANCH_PARAMS]: (
    state,
    branches: newBranchParams
  ) => {
    state.newBranchParams = branches;
  },
  [commerceType.mutations.SET_SINGLE_BRANCH]: (
    state,
    branch: responseSingle<BranchParams>
  ) => {
    if (branch.data) {
      state.single_branch = objectValueNull(branch.data);
    }
  },
  [commerceType.mutations.SET_EMPTY_NEW_BRANCH_PARAMS]: state => {
    state.newBranchParams.name = '';
    state.newBranchParams.status = 'inactive';

    // Reset contacts
    const keysContact: Record<string, any> = Object.keys(state.newBranchParams.contacts[0]);
    state.newBranchParams.contacts.forEach(
      (cont: ContactParams, position: number) => {
        keysContact.forEach((key: 'email' | 'name' | 'phone' | 'phone2' | 'contact_kind_id') => {
          state.newBranchParams.contacts[position][key] = '';
        });
      }
    );
    // Reset address
    const keysAddres: Record<string, any> = Object.keys(state.newBranchParams.address);
    keysAddres.forEach((add: 'description' | 'phone' | 'state_code' | 'phone_alternative') => {
      state.newBranchParams.address[add] = '';
    });
  },
  [commerceType.mutations.SET_BRANCH_BY_COMMERCE]: (
    state,
    branches: responseCollection<BranchParams>
  ) => {
    state.branchesByCommerce.data = branches.data;
    state.branchesByCommerce.pagination = branches.meta.pagination;
  },
  [commerceType.mutations.SET_BRANCHS_SIGNATURES]: (
    state,
    branches: responseCollection<branchSignature>
  ) => {
    state.branchSignatures.data = branches.data.map(branch => {
      const dataFormat = branch;
      dataFormat.virtualBranchName = branch.branch.name;
      return dataFormat;
    });
    state.branchSignatures.pagination = branches.meta.pagination;
  },
};
const actions: ActionTree<BranchState, any> = {
  [commerceType.actions.BRANCHES_BY_COMPANY]: (
    { commit, },
    { query, company_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/branches?${query}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_BRANCHES_BY_COMPANY, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.SAVE_BRANCH]: (
    { commit, },
    { data, company_commerce_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          `backoffice/company_commerces/${company_commerce_id}/branches`,
          { data, }
        )
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_SINGLE_BRANCH, res.data);
          commit(commerceType.mutations.SET_EMPTY_NEW_BRANCH_PARAMS);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.LIST_BRANCHES_BY_COMMERCE]: (
    { commit, },
    { query, commerce_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerces/${commerce_id}/branches?${query}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_BRANCH_BY_COMMERCE, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.SINGLE_BRANCH]: ({ commit, }, branch_id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/branches/${branch_id}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_SINGLE_BRANCH, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.UPDATE_BRANCH]: ({ commit, }, { branch_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/branches/${branch_id}`, { data: data, })
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_SINGLE_BRANCH, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.BRANCHS_SIGNATURES]: ({ commit, }, { company_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/branch_signatures?per_page=100`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_BRANCHS_SIGNATURES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    users: branchsUser,
  },
};
