import { responseCollection } from '@/store/interfaces/json-api/generics';
import {
  liquidationKind,
  LiquidationState
} from '@/store/interfaces/liquidations';
import { RootState } from '@/store/interfaces/rootState';
import liquidationsType from '@/store/types/liquidations';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const namespaced = true;

const initialState = (): LiquidationState => {
  return {
    liquidationsKinds: { data: [], pagination: {}, },
  };
};

const state: LiquidationState = initialState();
const getters: GetterTree<LiquidationState, RootState> = {
  [liquidationsType.getters.GET_LIQUIDATION_KIND_LIST]: state => {
    return state.liquidationsKinds;
  },
};
const mutations: MutationTree<LiquidationState> = {
  [liquidationsType.mutations.SET_LIQUIDATION_KIND_LIST]: (
    state,
    liquidation: responseCollection<liquidationKind>
  ) => {
    state.liquidationsKinds.data = liquidation.data;
    state.liquidationsKinds.pagination = liquidation.meta.pagination;
  },
};
const actions: ActionTree<LiquidationState, RootState> = {
  [liquidationsType.actions.LIQUIDATION_KIND_LIST]: (
    { commit, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/liquidation_kinds')
        .then((res: AxiosResponse) => {
          commit(liquidationsType.mutations.SET_LIQUIDATION_KIND_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
