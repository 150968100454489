

























































































import { BranchParams } from '@/store/interfaces/branchs';
import branchs from '@/store/types/branchs';
import copyObj from '@/util/copyObj';
import { TeipNotifications } from '@/util/teipNotifications';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'EditBranchProfile',
  data: () => ({
    updatingBranch: false,
    branch: {
      address: {
        description: '',
        id: '',
        phone: '',
        state_code: '',
      },
      commerce_id: '',
      company_id: '',
      created_at: '',
      name: '',
      notification_email: '',
      status: 'active',
    } as BranchParams,
  }),
  computed: {
    ...mapGetters({
      getSingleBranch: `commerceModule/branches/${branchs.getters.GET_SINGLE_BRANCH}`,
    }),
    active: {
      get(): boolean {
        return (this.getSingleBranch as BranchParams).status === 'active';
      },
      set(value: boolean) {
        this.branch.status = value ? 'active' : 'inactive';
      },
    },
  },
  methods: {
    ...mapActions({
      updateBranch: `commerceModule/branches/${branchs.actions.UPDATE_BRANCH}`,
    }),
    filledBranch(): void {
      this.branch = copyObj(this.getSingleBranch);
    },
    updateProfile(): void {
      const notificacion = new TeipNotifications();
      this.updatingBranch = true;
      const data = {
        branch_id: this.$route.params.sucursal_id,
        data: {
          name: this.branch.name,
          notification_email: this.branch.notification_email,
          status: this.branch.status,
          address: {
            description: this.branch.address.description,
            phone: this.branch.address.phone,
          },
        },
      };
      this.updateBranch(data)
        .then(() => {
          notificacion.success('Sucursal actualizada');
          this.$emit('editedSuccess', true);
        })
        .catch(() => {
          notificacion.warning(
            '¡Error!',
            'Ha ocurrido un error al actualizar :('
          );
        })
        .finally(() => {
          this.updatingBranch = false;
        });
    },
    cancelUpdate() {
      this.$emit('cancel', true);
    },
  },
  mounted() {
    this.filledBranch();
  },
});
