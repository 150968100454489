/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NavigationGuardNext, Route } from 'vue-router';
import { checkPermissions, ifAuthenticated } from '../routeValidation';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';

// Documents
const Accounts = () =>
  import(
    /* webpackChunkName: "Accounts" */ '@/views/Accounts/account.vue'
  );

const permissionsPages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'accountList',
  children: [
    {
      path: '/accounts-list',
      component: Accounts,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'index_customers_users');
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};

export default permissionsPages;
