












































































import commerceType from '@/store/types/commerce';
import root from '@/store/types/root';
import image from '@/assets/images/no-image-available.png';
import updateProfile from './forms/updateProfile.vue';
import commerceKind from '@/store/types/commerceKind';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'PartnersProfile',
  components: {
    updateProfile,
  },
  data: () => ({
    image,
    edit: false,
    isLoading: true,
    updateErrors: {},
  }),
  async mounted() {
    await this.CommerceKindList();
    this.$data.isLoading = false;
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      getSingleComerce: `commerceModule/${commerceType.getters.GET_SINGLE_COMMERCE}`,
      getCommerceKindList: `commerceModule/commerceKind/${commerceKind.getters.GET_COMMERCE_KIND}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  methods: {
    ...mapActions({
      CommerceKindList: `commerceModule/commerceKind/${commerceKind.actions.LIST_COMMERCE_KIND}`,
      updateCommerce: `commerceModule/${commerceType.actions.UPDATE_COMMERCE}`,
    }),

    bannerImage(url: string): string {
      if (!url) {
        return image;
      }
      return url;
    },
    isFranchiseOrEvent(val: boolean): string {
      return val ? 'Si' : 'No';
    },

    updateCommerceFunction(values: unknown): void {
      const notification = new TeipNotifications();
      this.updateCommerce(values)
        .then(() => {
          this.$data.edit = false;
          notification.success('Actualizado correctamente');
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.status === 422) {
            this.$data.updateErrors = err.response.data.error;
          } else {
            notification.warning('No hemos podido actualizar :(');
          }
        });
    },
  },
});
