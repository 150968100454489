export default {
  mutations: {
    SET_DEVICES_CUSTOMER_LIST: 'SET_DEVICES_CUSTOMER_LIST',
    SET_SESSIONS_BY_DEVICE: 'SET_SESSIONS_BY_DEVICE',
  },
  getters: {
    GET_DEVICES_CUSTOMER_LIST: 'GET_DEVICES_CUSTOMER_LIST',
    GET_SESSIONS_BY_DEVICE: 'GET_SESSIONS_BY_DEVICE',
  },
  actions: {
    DEVICES_CUSTOMER_LIST: 'DEVICES_CUSTOMER_LIST',
    SESSIONS_BY_DEVICE: 'SESSIONS_BY_DEVICE',
  },
};
