/* eslint-disable @typescript-eslint/no-empty-function */
import Swal, {
  SweetAlertIcon,
  SweetAlertResult,
  SweetAlertCustomClass
} from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { hosterButtonPrimary } from '../hoster';

export declare interface iSimpleSweetAlertInterface {
  title: string;
  message?: string;
  alertType: SweetAlertIcon;
}
export declare interface iSweetConfig {
  title: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText: string;
  alertType: SweetAlertIcon;
  customClass?: SweetAlertCustomClass;
}

export default class SweetAlert {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  async simpleSweetAlert(config: iSimpleSweetAlertInterface): Promise<void> {
    await Swal.fire(config.title, config.message, config.alertType);
  }

  async askingSweetAlert(config: iSweetConfig): Promise<boolean> {
    let optionSelected = false;

    const typeButton = `btn-${hosterButtonPrimary('primary')}`;
    await Swal.fire({
      title: config.title,
      text: config.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: config.confirmButtonText,
      cancelButtonText: config.cancelButtonText,
      reverseButtons: true,
      customClass: {
        cancelButton: 'btn base-button btn-outline-secondary',
        confirmButton: `btn base-button float-right ${typeButton}`,
      },
      buttonsStyling: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })
      .then((result: SweetAlertResult) => {
        optionSelected = !!result.value;
      });
    return optionSelected;
  }
}
