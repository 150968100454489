/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NavigationGuardNext, Route } from 'vue-router';
import { checkPermissions, ifAuthenticated } from './../routeValidation';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { permissionsOfPermissions } from './permissions/index';

// Documents
const Permission = () =>
  import(
    /* webpackChunkName: "Permission" */ '@/views/Permissions/Permissions.vue'
  );

const newPermissionGroup = () =>
  import(
    /* webpackChunkName: "newPermissionGroup" */ '@/views/Permissions/newPermissionGroup.vue'
  );

const editPermissionGroup = () =>
  import(
    /* webpackChunkName: "editPermissionGroup" */ '@/views/Permissions/editPermissionGroup.vue'
  );

const permissionsPages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'Permission',
  children: [
    {
      path: '/permisos',
      name: 'permissions',
      component: Permission,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        // checkPermissions(to, from, next, 'index_permission_groups');
        checkPermissions(to, from, next, permissionsOfPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/permisos/nuevo-grupo',
      name: 'new-group-permission',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_permission_groups');
      },
      component: newPermissionGroup,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/permisos/editar/:permission_id',
      name: 'edit-group-permission',
      component: editPermissionGroup,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'update_permission_groups');
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};

export default permissionsPages;
