/* eslint-disable @typescript-eslint/no-explicit-any */

import { RootState } from '@/store/interfaces/rootState';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { iIntermediaries, IntermediariesState } from '../interfaces/intermediaries';
import { responseCollection } from '../interfaces/json-api/generics';
import intermediaries from '../types/intermediaries';

const namespaced = true;

const state: IntermediariesState = {
  intermediaries: {
    data: [],
    pagination: {
      count: 0,
      per_page: 0,
      page: 0,
    },
  },
};

const getters: GetterTree<IntermediariesState, RootState> = {
  [intermediaries.getters.GET_INTERMEDIARIES]: state => {
    return state.intermediaries;
  },
};
const mutations: MutationTree<IntermediariesState> = {
  [intermediaries.mutations.SET_INTERMEDIARIES]: (state, response: responseCollection<iIntermediaries>) => {
    state.intermediaries.data = response.data;
    state.intermediaries.pagination = response.meta.pagination;
  },
};
const actions: ActionTree<IntermediariesState, RootState> = {
  [intermediaries.actions.INTERMEDIARIES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/intermediaries')
        .then((res: AxiosResponse) => {
          commit(intermediaries.mutations.SET_INTERMEDIARIES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
