import clickOutside from '@/directives/click-ouside.js';
import canDirective from '@/directives/can-directive.js';
import { VueConstructor } from 'vue';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue: VueConstructor): void {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('can', canDirective);
  },
};

export default GlobalDirectives;
