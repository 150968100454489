import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import RootTypes from '@/store/types/root';
import router from '@/routes/router';
import RefreshToken from '@/util/classes/refreshTokenTime';
import { AuthTokens } from '@/store/interfaces/auth';
import auth from '@/store/types/auth';
import { ObjectKeysGeneric } from '@/store/interfaces/generic';

const URL: string | undefined = process.env.VUE_APP_URL_API;

const http = axios.create({
  baseURL: `${URL}/api/v1/`,
});
http.interceptors.response.use(undefined, function(err) {
  return new Promise(function(resolve, reject) {
    const type = err.response.data.type;
    if (err.response.status === 404) {
      router.push({ path: '/not-found', }, resolve, reject);
    }
    if (err.response.status === 401) {
      if (type !== 'locked_account_by_attempts') {
        // if you ever get an unauthorized, logout the user
        store
          .dispatch('authModule/' + auth.mutations.AUTH_LOGOUT)
          .then(() => {
            router.push({ name: 'Login', });
          });
        throw type;
      }
      // you can also redirect to /login if needed !
    }
    throw err;
  });
});
// Add a request interceptor
http.interceptors.request.use(
  async config => {
    const url = config.url?.split('?');
    if (url?.length === 2) {
      const obj: ObjectKeysGeneric<string> = {};
      url[1].replace(/([^=&]+)=([^&]*)/g, function(m, key, value): string {
        obj[key] = encodeURIComponent(value);
        return m;
      });
      const queryString: string = Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&');
      config.url = `${url[0]}?${queryString}`;
    }
    store.commit(RootTypes.mutations.SET_START_PROCESS);
    await requiredRefreshToken(config);
    return config;
  },
  error => {
    // Do something with request error
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return response;
  },
  error => {
    // Do something with response error
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return Promise.reject(error);
  }
);

const token = localStorage.getItem('_token');
if (token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const requiredRefreshToken = async (config: AxiosRequestConfig) => {
  const userSession: AuthTokens =
    store.getters[`authModule/${auth.getters.GET_USER_SESSION}`];
  const timeToExpiredToken = new RefreshToken(userSession);
  if (
    store.getters[`authModule/${auth.getters.IS_AUTHENTICATED}`] &&
    config.url !== 'oauth/token' &&
    timeToExpiredToken.requiredNewToken()
  ) {
    await store.dispatch(`authModule/${auth.actions.REFRESH_TOKEN}`);
  }
};

export { http, URL };
