/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import paymentGatewayType from '@/store/types/paymentsGateway';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection } from '@/store/interfaces/json-api/generics';
import { paymentGatewayParams, paymentsGatewayState } from '../interfaces/paymentsGateway';

const namespaced = true;

const state: paymentsGatewayState = {
  gateways: {
    data: [],
    pagination: {},
  },
};
const getters: GetterTree<paymentsGatewayState, any> = {
  [paymentGatewayType.getters.GET_GATEWAY_PAYMENT]: state => {
    return state.gateways;
  },
};
const mutations: MutationTree<paymentsGatewayState> = {
  [paymentGatewayType.mutations.SET_GATEWAY_PAYMENT]: (
    state,
    paymentsGateway: responseCollection<paymentGatewayParams>
  ) => {
    state.gateways.data = paymentsGateway.data;
    state.gateways.pagination = paymentsGateway.meta.pagination;
  },
};
const actions: ActionTree<paymentsGatewayState, any> = {
  [paymentGatewayType.actions.GATEWAY_PAYMENT]: (
    { commit, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          'backoffice/payment_gateways'
        )
        .then((res: AxiosResponse) => {
          commit(
            paymentGatewayType.mutations.SET_GATEWAY_PAYMENT,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
