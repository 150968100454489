var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","hide-footer":"","title":"Detalle de transferencia","static":"","modal-class":"transfer-detail"},model:{value:(_vm.syncedShowModal),callback:function ($$v) {_vm.syncedShowModal=$$v},expression:"syncedShowModal"}},[_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('base-button',{directives:[{name:"can",rawName:"v-can",value:('show_transfer_requests'),expression:"'show_transfer_requests'"}],staticClass:"download-voucher",attrs:{"type":"primary","size":"sm","loading":_vm.isDownloadVoucher},on:{"click":_vm.downloadVoucher}},[_vm._v("\n      "+_vm._s(_vm.isDownloadVoucher ? 'Descargando' : 'Descargar')+" voucher PDF\n    ")])],1),(_vm.withUser)?_c('b-row',[_c('b-col',{attrs:{"cols":"12 h3"}},[_vm._v(" Usuario ")]),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: 'user-transactions',
          params: { user_id: _vm.transfer.user.id },
        },"title":"Ver perfil"}},[_c('i',{staticClass:"fas fa-user mr-3"}),_vm._v("\n        "+_vm._s(_vm.transfer.user.profile.first_name)+"\n        "+_vm._s(_vm.transfer.user.profile.last_name)+"\n      ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: 'user-transfer',
          params: { user_id: _vm.transfer.user.id },
        },"title":"Ver transferencia"}},[_c('i',{staticClass:"fas fa-fingerprint mr-3"}),_vm._v(_vm._s(_vm.transfer.id)+"\n      ")])],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12 h3"}},[_vm._v(" Destinario ")]),_c('b-col',{attrs:{"cols":"6"}},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"to":{
          name: 'user-transactions',
          params: { user_id: _vm.transfer.recipient.id },
        },"title":"Ver perfil"}},[_c('i',{staticClass:"fas fa-user mr-3"}),_vm._v("\n        "+_vm._s(_vm.transfer.recipient.profile.first_name)+"\n        "+_vm._s(_vm.transfer.recipient.profile.last_name)+"\n      ")])],1)],1),_c('Transaction',{attrs:{"transaction":_vm.transaction}}),_c('Pay',{attrs:{"pay":_vm.pay}}),(_vm.hostConfig.globals.wallet_info)?_c('Device',{attrs:{"device":_vm.device}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }