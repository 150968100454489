export default {
  mutations: {
    SET_USER_BRANCHES: 'SET_USER_BRANCHES',
    SET_SINGLE_USER_BRANCH: 'SET_SINGLE_USER_BRANCH',
    SET_CLEAN_SINGLE_USER: 'SET_CLEAN_SINGLE_USER',
    SET_DELETE_USER: 'SET_DELETE_USER',
  },
  getters: {
    GET_USER_BRANCHES: 'GET_USER_BRANCHES',
    GET_SINGLE_USER_BRANCH: 'GET_SINGLE_USER_BRANCH',
  },
  actions: {
    USER_BRANCH_LIST: 'USER_BRANCH_LIST',
    NEW_BRANCH_USER: 'NEW_BRANCH_USER',
    UPDATE_BRANCH_USER: 'UPDATE_BRANCH_USER',
    DELETE_DRAFT_BRANCH_USER: 'DELETE_DRAFT_BRANCH_USER',
  },
};
