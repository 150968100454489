/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { BranchContacts, ModalState } from '@/store/interfaces/modals';

// Establece los types de los modulos store
import modalType from '@/store/types/modals';

const namespaced = true;

const initialState = (): ModalState => {
  return {
    show_contact_modal: false,
    contacts: {
      type: 'create',
      data: [
        {
          name: '',
          email: '',
          phone: '',
          phone2: '',
          contact_kind_id: '',
        }
      ],
    },
  };
};

const state: ModalState = initialState();

const getters: GetterTree<ModalState, any> = {
  [modalType.getters.GET_SHOW_CONTACT_BRANCHES]: state => {
    return state.contacts;
  },
  [modalType.getters.GET_SHOW_CONTACT_MODAL]: state => {
    return state.show_contact_modal;
  },
};

const mutations: MutationTree<ModalState> = {
  [modalType.mutations.SET_TYPE_CONTACT_BRANCHES]: (
    state,
    contact_modal: BranchContacts
  ) => {
    state.contacts = contact_modal;
  },
  [modalType.mutations.SET_SHOW_CONTACT_MODAL]: (state, show: boolean) => {
    state.show_contact_modal = show;
  },
};

const actions: ActionTree<ModalState, any> = {};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
