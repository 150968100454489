import { GetterTree, ActionTree, MutationTree } from 'vuex';
import brachUserType from '@/store/types/branchsUser';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import {
  BranchUserParams,
  BranchUserState
} from '@/store/interfaces/branchsUser';
import { allowAny } from 'teip-components';
import { responseCollection } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const state: BranchUserState = {
  users: {
    data: [],
    pagination: {},
  },
  single_user: {
    email: '',
    profile: {
      first_name: '',
      last_name: '',
    },
  },
};
const getters: GetterTree<BranchUserState, allowAny> = {
  [brachUserType.getters.GET_USER_BRANCHES]: state => {
    return state.users;
  },
  [brachUserType.getters.GET_SINGLE_USER_BRANCH]: state => {
    return state.single_user;
  },
};
const mutations: MutationTree<BranchUserState> = {
  [brachUserType.mutations.SET_USER_BRANCHES]: (
    state,
    branchUser: responseCollection<BranchUserParams>
  ) => {
    state.users.data = branchUser.data;
    state.users.pagination = branchUser.meta.pagination;
  },
  [brachUserType.mutations.SET_SINGLE_USER_BRANCH]: (
    state,
    userBranch: BranchUserParams
  ) => {
    state.single_user = userBranch;
  },
  [brachUserType.mutations.SET_DELETE_USER]: (
    state,
    user_branch_id: string
  ) => {
    state.users.data = state.users.data.filter(
      (user: BranchUserParams) => user.id !== user_branch_id
    );
  },
  [brachUserType.mutations.SET_CLEAN_SINGLE_USER]: state => {
    // delete inusables for update/create
    delete state.single_user.id;
    delete state.single_user.name;
    delete state.single_user.branch;

    state.single_user.email = '';

    // Reset profile
    const keyprofile: Record<string, allowAny> =
        Object.keys(state.single_user.profile);
    keyprofile.forEach((val: 'id' | 'first_name' | 'last_name') => {
      state.single_user.profile[val] = '';
    });
  },
};
const actions: ActionTree<BranchUserState, allowAny> = {
  [brachUserType.actions.USER_BRANCH_LIST]: ({ commit, }, { branch_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/users?branch_id_eq=${branch_id}`)
        .then((res: AxiosResponse) => {
          commit(brachUserType.mutations.SET_USER_BRANCHES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [brachUserType.actions.NEW_BRANCH_USER]: ({ commit, }, data: BranchUserParams) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/users', { data, })
        .then((res: AxiosResponse) => {
          commit(brachUserType.mutations.SET_CLEAN_SINGLE_USER);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [brachUserType.actions.UPDATE_BRANCH_USER]: (
    { commit, },
    data_brach_user
  ) => {
    const { data, user_branch_id, } = data_brach_user;
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/users/${user_branch_id}`, { data, })
        .then((res: AxiosResponse) => {
          commit(brachUserType.mutations.SET_SINGLE_USER_BRANCH, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [brachUserType.actions.DELETE_DRAFT_BRANCH_USER]: (
    { commit, },
    branch_user_id: string
  ) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/users/${branch_user_id}`)
        .then((res: AxiosResponse) => {
          commit(brachUserType.mutations.SET_DELETE_USER, branch_user_id);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
