



































































import AddTeipBizUser from '@/components/Company/Profile/modals/addTeipBizUser.vue';
import companyUser from '@/store/types/companyUser';
import SweetAlert from '@/util/classes/SweetAlert';

import { companyHost } from '@/const/companyHost';
import {
  CompanyUserParams,
  NewCompanyUser
} from '@/store/interfaces/companyUser';
import { TableRef } from '@/store/interfaces/generic';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SearchUserByEmail from '@/components/Company/Profile/modals/form/searchUser.vue';
import { ValidationProvider } from 'vee-validate';
import configurations from '@/store/types/configurations';

const notification = new TeipNotifications();
export default Vue.extend({
  name: 'CompanyUsers',
  components: {
    AddTeipBizUser,
  },
  data: () => ({
    companyHost,
    viewDetails: false,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 250,
        sortable: true,
        formatter: (row: CompanyUserParams) => {
          return `${row.user.profile.first_name} ${row.user.profile.last_name}`;
        },
      },
      {
        prop: 'user.email',
        label: 'Email',
        minWidth: 180,
        sortable: true,
      }
    ] as tTableColumns,
    contact_modal: false,
    contactSelected: {},
    rowSelected: {},
    errors: {},
    success: false,
    saveIsSuccess: false,
    deleteContactConfirm: false,
  }),
  created() {
    if (this.hostConfig.company_users.code) {
      this.tableColumns.push({
        prop: 'code',
        label: 'Código interno',
        minWidth: 130,
        sortable: true,
      });
    }

    if (this.hostConfig.company_users.commerce_id) {
      this.tableColumns.push({
        prop: 'commerce_name',
        label: 'Comercio',
        minWidth: 200,
        sortable: true,
      });
    }

    if (this.hostConfig.company_users.branch_id) {
      this.tableColumns.push({
        prop: 'branch_name',
        label: 'Sucursal',
        minWidth: 200,
        sortable: true,
      });
    }
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
      getCompanyUserList: `companyUserModule/${companyUser.getters.GET_COMPANY_USERS}`,
    }),
    companyIdQueryString(): string {
      const companyId = this.$route.params.company_id;
      return `&company_id_eq=${companyId}`;
    },
  },
  methods: {
    ...mapActions({
      CompanyUserList: `companyUserModule/${companyUser.actions.COMPANY_USERS_LIST}`,
      deletedCompanyUser: `companyUserModule/${companyUser.actions.DELETE_COMPANY_USER}`,
      saveUser: `companyUserModule/${companyUser.actions.SAVE_COMPANY_USER}`,
      updateUser: `companyUserModule/${companyUser.actions.UPDATE_COMPANY_USER}`,
    }),
    rowSelect(row: CompanyUserParams): void {
      this.$data.rowSelected = row;
    },
    editUser(): void {
      this.$data.contactSelected = { ...this.$data.rowSelected, };
      this.$data.contact_modal = true;
    },
    newUser(): void {
      this.$data.contactSelected = {};
      this.$data.contact_modal = true;
    },
    triggerContactInfo(val: {
      update: boolean;
      user: NewCompanyUser;
      companyUserId: string;
    }): void {
      if (val.update) {
        this.updateContact(val.user, val.companyUserId);
      } else {
        this.createContact(val.user);
      }
    },
    updateContact(user: NewCompanyUser, companyUserId: string): void {
      this.updateUser({ user_id: companyUserId, data: user, })
        .then(() => {
          notification.success('Usuario actualizado correctamente');
          this.$data.contact_modal = false;
          (this.$refs.refCompanyUserList as TableRef).debouncedFetch();
        })
        .catch((err: AxiosError) => {
          this.setServerErrors(err);
        })
        .finally(() => {
          this.$data.success = false;
        });
    },
    createContact(user: NewCompanyUser): void {
      this.saveUser(user)
        .then(() => {
          notification.success('Usuario agregado correctamente');
          this.$data.contact_modal = false;
          this.$data.saveIsSuccess = true;
          (this.$refs.refCompanyUserList as TableRef).debouncedFetch();
        })
        .catch((err: AxiosError) => {
          this.$data.saveIsSuccess = false;
          this.setServerErrors(err);
        });
    },
    setServerErrors(err: AxiosError): void {
      const notificacion = new TeipNotifications();
      if (err.response?.status === 422) {
        this.$data.errors = err.response.data.error;
        // Set erros to search component
        (
          (
            (this.$refs.contactModal as InstanceType<typeof AddTeipBizUser>).$refs
              .searchUserByEmail as InstanceType<typeof SearchUserByEmail>
          ).$refs.formValidatorSearchUserByEmail as InstanceType<
            typeof ValidationProvider
          >
        ).setErrors(this.$data.errors);
      } else {
        notificacion.warning('Algo ha salido mal');
      }
    },
    async deleteAction(): Promise<void> {
      const companyUser = this.$data.rowSelected;
      const notification = new TeipNotifications();
      const swal = new SweetAlert();
      swal
        .askingSweetAlert({
          title: 'Eliminar Usuario',
          text: '¿Seguro que desea eliminar el Usuario?',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sí, eliminar',
          alertType: 'warning',
        })
        .then((res) => {
          res &&
            this.deletedCompanyUser(companyUser.id)
              .then(() => {
                notification.success('Usuario eliminado');
                (this.$refs.refCompanyUserList as TableRef).debouncedFetch();
              })
              .catch(() => {
                notification.warning('Ha ocurrido un error al eliminar :(');
              });
        });
      (this.$refs.refCompanyUserList as TableRef).fetchData();
    },
  },
});
