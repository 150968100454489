

































import image from '@/assets/images/no-image-available.png';
import { companyHost } from '@/const/companyHost';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ImagePreview',
  props: {
    src: {
      type: [String] as PropType<string | null | undefined>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showModal: false,
    image,
    companyHost,
  }),
  computed: {
    canUpdateSynced: {
      get(): boolean {
        return this.canUpdate;
      },
      set(value: boolean): void {
        this.$emit('update:canUpdate', value);
      },
    },
    urlImage(): string {
      return this.src ? this.src : image;
    },
  },
  mounted(): void {
    document.addEventListener('keydown', this.closePreview);
    if (this.$route.name !== 'new-company') {
      this.canUpdateSynced = true;
    }
  },
  destroyed(): void {
    document.removeEventListener('keydown', this.closePreview);
  },

  methods: {
    closePreview(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    open(): void {
      this.showModal = true;
    },
    trash(): void {
      this.$emit('trash', this.id);
    },
    edit(): void {
      this.$emit('edit', this.id);
    },
    close(): void {
      this.showModal = false;
    },
  },
});
