/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { checkPermissions, ifAuthenticated } from '../routeValidation';
import { NavigationGuardNext, Route } from 'vue-router';
import { cashbackPermissions, discountPermissions } from './permissions/index';

const NoveltiesDiscountList = () =>
  import(
    /* webpackChunkName: "list_discounts" */
    '@/views/NoveltiesAndPromotions/Discounts/Home.vue'
  );
const CashbackList = () =>
  import(
    /* webpackChunkName: "list_cashback" */
    '@/views/NoveltiesAndPromotions/Cashback/List.vue'
  );
const CashbackCreate = () =>
  import(
    /* webpackChunkName: "create_cashback" */
    '@/views/NoveltiesAndPromotions/Cashback/Create.vue'
  );

const CashbackUpdate = () =>
  import(
    /* webpackChunkName: "update_cashback" */
    '@/views/NoveltiesAndPromotions/Cashback/Update.vue'
  );

const NoveltiesDiscountNew = () =>
  import(
    /* webpackChunkName: "new_discounts" */
    '@/views/NoveltiesAndPromotions/Discounts/New.vue'
  );
const noveltiesAndPromotions = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'NoveltiesAndPRomotions',
  children: [
    {
      path: '/noveltiesAndPromotions/discounts',
      name: 'NoveltiesDiscountList',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, discountPermissions);
      },
      component: NoveltiesDiscountList,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/noveltiesAndPromotions/discounts/new',
      name: 'NoveltiesDiscountNew',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_discounts');
      },
      component: NoveltiesDiscountNew,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/noveltiesAndPromotions/discounts/:discount_id/edit',
      name: 'NoveltiesDiscountEdit',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'update_discounts');
      },
      component: NoveltiesDiscountNew,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/noveltiesAndPromotions/cashback',
      name: 'CashbackList',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, cashbackPermissions);
      },
      component: CashbackList,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/noveltiesAndPromotions/cashback/new',
      name: 'CashbackCreate',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_cashbacks');
      },
      component: CashbackCreate,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/noveltiesAndPromotions/cashback/update/:id',
      name: 'CashbackUpdate',
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'update_cashbacks');
      },
      component: CashbackUpdate,
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default noveltiesAndPromotions;
