import { GetterTree, ActionTree, MutationTree } from 'vuex';
import transferType from '@/store/types/transfer';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../interfaces/rootState';
import { transferParams, transferState } from '../interfaces/transfer';
import { responseCollection } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const initialState = (): transferState => {
  return {
    transfers: {
      data: [],
      pagination: {},
    },
    transfersByUser: {
      data: [],
      pagination: {},
    },
  };
};

const state: transferState = initialState();
const getters: GetterTree<transferState, RootState> = {
  [transferType.getters.GET_TRANSFER]: (state) => {
    return state.transfers;
  },
  [transferType.getters.GET_TRANSFER_BY_CUSTOMER_USER]: (state) => {
    return state.transfersByUser;
  },
};
const mutations: MutationTree<transferState> = {
  [transferType.mutations.SET_TRANSFERS]: (
    state,
    transfers: responseCollection<transferParams>
  ) => {
    state.transfers.data = transfers.data;
    state.transfers.pagination = transfers.meta.pagination;
  },
  [transferType.mutations.SET_TRANSFERS_BY_CUSTOMER_USER]: (
    state,
    transfers: responseCollection<transferParams>
  ) => {
    state.transfersByUser.data = transfers.data;
    state.transfersByUser.pagination = transfers.meta.pagination;
  },
};
const actions: ActionTree<transferState, RootState> = {
  [transferType.actions.TRANSFER_LIST]: ({ commit, }, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/transfer_requests?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(transferType.mutations.SET_TRANSFERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transferType.actions.TRANSFER_VOUCHER_DOWNLOAD]: (_, { id, name, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/transfer_requests/${id}.pdf`, {
          responseType: 'blob',
        })
        .then((res: AxiosResponse) => {
          const blob = new Blob([res.data], { type: 'application/pdf', });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${name}`;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transferType.actions.TRANSFER_LIST_BY_CUSTOMER_USER]: ({ commit, }, { user_id, query, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`/backoffice/customers/${user_id}/transfer_requests?${query}`)
        .then((res: AxiosResponse) => {
          commit(transferType.mutations.SET_TRANSFERS_BY_CUSTOMER_USER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
