















































import uploadFiles from '@/components/Reusable/uploadFiles.vue';
import root from '@/store/types/root';
import { Document } from './updateDocument.vue';
import { mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'UpdateDocumentsImageModal',
  components: { uploadFiles, },
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
    document: {
      required: true,
      type: Object as PropType<Document>,
    },
  },
  data: () => ({
    currentFile: null as string | File | null,
  }),
  methods: {
    cancel(): void {
      this.syncedViewModal = false;
    },
    updateDocument(): void {
      const uploadFile = {
        data: (this.$refs.documentImge as InstanceType<typeof uploadFiles>)
          .$data.data,
        currentFile: (
          this.$refs.documentImge as InstanceType<typeof uploadFiles>
        ).$data.currentFile,
      };
      let url = 'company_documents';
      if (this.$route.name === 'contacts-documents') {
        url = 'contact_documents';
      }
      const dataToSend = {
        document_id: this.document.id,
        url,
        data: {
          document: uploadFile.data.blob_signed_id,
        },
      };

      this.$emit('updateDocumentImage', {
        uploadFile,
        dataToSend,
      });
    },
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
  },
});
