



































































import contactModal from '@/components/modals/contact.vue';

import { ContactParams } from '@/store/interfaces/contact';
import { TeipNotifications } from '@/util/teipNotifications';
import contact from '@/store/types/contact';
import { AxiosError, AxiosResponse } from 'axios';
import deleteContactConfirm from '@/components/modals/actionConfirm.vue';
import copyObj from '@/util/copyObj';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { companyHost } from '@/const/companyHost';
import { phoneFormat } from '@/util/phoneFormat';
import { Pagination } from '@/store/interfaces/json-api/pagination';
import configurations from '@/store/types/configurations';
const notification = new TeipNotifications();

type contactInfo = {
  model: ContactParams;
  update: boolean;
};

export default Vue.extend({
  name: 'Contacts',
  components: {
    contactModal,
    deleteContactConfirm,
  },
  props: {
    data: {
      required: true,
      type: Array as PropType<ContactParams[]>,
    },
    pagination: {
      required: true,
      type: Object as PropType<Pagination>,
    },
    ids: {
      required: true,
      type: Object as PropType<Record<string, string>>,
    },
    fetchCallback: {
      required: true,
      type: Function,
    },
    entity: {
      required: false,
      type: String,
      default: 'companies',
    },
  },
  data: () => ({
    companyHost,
    propsToSearch: ['name', 'email', 'phone', 'contact_kind.name', 'phone2'] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'email',
        label: 'Email',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'contact_kind.name',
        label: 'Tipo',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'phone',
        label: 'Teléfono',
        minWidth: 150,
        sortable: true,
        formatter: (value: ContactParams): string => {
          return phoneFormat(value.phone);
        },
      },
      {
        prop: 'phone2',
        label: 'Teléfono secundario',
        minWidth: 250,
        sortable: true,
        formatter: (value: ContactParams): string => {
          return phoneFormat(value.phone2);
        },
      }
    ] as tTableColumns,
    contact_modal: false,
    contactSelected: {},
    errors: {},
    success: false,
    deleteContactConfirm: false,
  }),
  created(): void {
    if (!this.hostConfig.companies.alternative_phone) {
      const findAlternativePhonePosition = this.tableColumns.findIndex(t => t.prop === 'phone2');
      this.tableColumns.splice(findAlternativePhonePosition, 1);
    }
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    entitySync: {
      get(): string {
        return this.entity;
      },
      set(val: string): void {
        this.$emit('update:entity', val);
      },
    },
  },
  methods: {
    ...mapActions({
      contactUpdate: `contactModule/${contact.actions.UPDATE_CONTACT}`,
      deleteContact: `contactModule/${contact.actions.DELETE_DRAFT_CONTACT}`,
      saveContactByCompany: `contactModule/${contact.actions.SAVE_CONTACT}`,
    }),
    edit(): void {
      this.$data.contact_modal = true;
    },
    remove(): void {
      this.$data.deleteContactConfirm = true;
    },
    confirmRemoveContact(): void {
      this.$data.deleteContactConfirm = false;
      const contact_id = this.$data.contactSelected.id;
      const toSend = { contact_id, entity: this.entitySync, };
      this.deleteContact(toSend)
        .then(() => {
          notification.success(
            '¡Eliminado!',
            'El contacto ha sido eliminado satisfactoriamente.'
          );
        })
        .catch(() => {
          notification.warning(
            '¡Error!',
            'Algo ha salido mal al intentar eliminar el contacto :('
          );
        });
    },
    rowselect(row: ContactParams): void {
      this.$data.contactSelected = row;
    },
    showModal(): void {
      this.$data.contactSelected = {};
      this.$data.contact_modal = true;
    },
    showModalContact(val: contactInfo): void {
      if (val.update) {
        this.updateContact(val);
      } else {
        this.createContact(val);
      }
    },
    updateContact(val: contactInfo): void {
      const data = copyObj(val.model);
      delete data.contact_kind;
      delete data.id;

      const dataToSend = {
        contact_id: this.$data.contactSelected.id,
        data,
      };
      this.contactUpdate(dataToSend)
        .then((result: AxiosResponse) => {
          notification.success('Actualizado correctamente');
          this.$data.success = true;
          this.$data.contact_modal = false;

          const position = this.data.findIndex(
            (find: ContactParams) => find.id === result.data.data.id
          );
          this.data[position].name = result.data.data.name;
          this.data[position].email = result.data.data.email;
          this.data[position].phone = result.data.data.phone;
          this.data[position].phone2 = result.data.data.phone2;
          this.data[position].contact_kind =
            result.data.data.contact_kind;
        })
        .catch((err: AxiosError) => {
          this.setServerErrors(err);
        })
        .finally(() => {
          this.$data.success = false;
        });
    },

    createContact(val: contactInfo): void {
      let entity_id: string = this.$route.params.company_id;
      if (!entity_id) {
        entity_id = this.$route.params.sucursal_id;
      }
      const toCreate = {
        data: val.model,
        entity_id,
        entity: this.entitySync,
      };

      this.saveContactByCompany(toCreate)
        .then(() => {
          notification.success('Contacto agregado correctamente');
          this.$data.contact_modal = false;
        })
        .catch((err: AxiosError) => {
          this.setServerErrors(err);
        });
    },

    setServerErrors(err: AxiosError): void {
      const notificacion = new TeipNotifications();
      if (err.response?.status === 422) {
        this.$data.errors = err.response.data.error;
      } else {
        notificacion.warning('Algo ha salido mal');
      }
    },
  },
});
