export default {
  getters: {
    GET_USER_STAFF: 'GET_USER_STAFF',
    GET_SINGLE_USER_STAFF: 'GET_SINGLE_USER_STAFF',
  },
  actions: {
    USER_STAFF: 'USER_STAFF',
    SAVE_USER_STAFF: 'SAVE_USER_STAFF',
    USER_SINGLE_STAFF: 'USER_SINGLE_STAFF',
    UPDATE_USER_STAFF: 'UPDATE_USER_STAFF',
    UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER',
  },
  mutations: {
    SET_USER_STAFF: 'SET_USER_STAFF',
    SET_SINGLE_USER_STAFF: 'SET_SINGLE_USER_STAFF',
  },
};
