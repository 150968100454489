export default {
  getters: {
    GET_PERMISSIONS_LIST: 'GET_PERMISSIONS_LIST',
  },
  actions: {
    PERMISSIONS_LIST: 'PERMISSIONS_LIST',
  },
  mutations: {
    SET_PERMISSIONS_LIST: 'SET_PERMISSIONS_LIST',
  },
};
