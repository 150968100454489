











































import devicesUserCustomer from '@/store/types/devicesUserCustomer';
import { DeviceCustomUserParams } from '@/store/interfaces/devicesUserCustomer';
import DatesFormat from '@/util/classes/datesFormat';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
const formatDate = new DatesFormat();

export default Vue.extend({
  name: 'DevicesUserApp',
  components: { StatusBadges, },
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'device_name',
        label: 'Modelo',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'vendor',
        label: 'Marca',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'system_version',
        label: 'Versión',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'system_name',
        label: 'Plataforma',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'push_token',
        label: 'Estado',
        minWidth: 150,
        sortable: true,
        slotName: 'active',
      },
      {
        prop: 'created_at',
        label: 'Fecha de registro',
        minWidth: 250,
        sortable: true,
        formatter: (row: DeviceCustomUserParams) => {
          return formatDate.formatDateTime(row.created_at);
        },
      },
      {
        prop: 'updated_at',
        label: 'Fecha de actualización',
        minWidth: 250,
        sortable: true,
        formatter: (row: DeviceCustomUserParams) => {
          return formatDate.formatDateTime(row.updated_at);
        },
      }
    ] as tTableColumns,
    deviceSelected: {},
  }),
  computed: {
    ...mapGetters({
      getUserDevices: `usersApp/devices/${devicesUserCustomer.getters.GET_DEVICES_CUSTOMER_LIST}`,
    }),
  },
  methods: {
    ...mapActions({
      UserDevices: `usersApp/devices/${devicesUserCustomer.actions.DEVICES_CUSTOMER_LIST}`,
    }),
    hasPushToken(row: DeviceCustomUserParams): boolean {
      return !!row.push_token;
    },
    sessionsActive(): void {
      this.$router.push({
        name: 'sessions-devices',
        params: { device_id: this.$data.deviceSelected.id, },
      });
    },
    deviceInfo(device: DeviceCustomUserParams): void {
      this.$data.deviceSelected = device;
    },
  },
});
