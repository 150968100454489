





























import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
import { mapActions } from 'vuex';
import companyUser from '@/store/types/companyUser';
import { AxiosResponse } from 'axios';

export default Vue.extend({
  name: 'SearchUser',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    companyHost,
    searching: false,
  }),
  computed: {
    email: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      companyUserList: `companyUserModule/${companyUser.actions.SEARCH_USER}`,
    }),
    async searchUser() {
      this.searching = true;
      try {
        const response: AxiosResponse = await this.companyUserList(
          `email_eq=${this.email}`
        );
        if (response.data.data.length === 0) {
          this.$emit('user-found', {
            user: null,
          });
          this.searching = false;
          return;
        }

        this.$emit('user-found', {
          user: response.data.data[0],
        });
      } catch (error) {
        this.$emit('user-found', {
          user: null,
        });
      }
      this.searching = false;
    },
  },
});
