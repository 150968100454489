/* eslint-disable @typescript-eslint/no-explicit-any */

import { ActionTree } from 'vuex';
import { CommerceState } from '@/store/interfaces/commerce';
// Establece los types de los modulos store
import uploadImageType from '@/store/types/uploadimage';
import { http } from '@/util/HTTP';
import { http as thirdParty } from '@/util/HTTPThirdParty';
import { ImageUpload } from '@/store/interfaces/uploadimage';
import { AxiosError } from 'axios';
import commerce from '../types/commerce';

const namespaced = true;

const actions: ActionTree<CommerceState, any> = {
  [uploadImageType.actions.PRESIGNED_URL]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('resources/presigned_url', data)
        .then((res: ImageUpload) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [uploadImageType.actions.UPLOAD_IMAGE]: (_, data: any) => {
    const {
      data: { url, headers, },
      currentFile,
    } = data;
    return new Promise((resolve, reject) => {
      thirdParty
        .put(url, currentFile, { headers: headers, })
        .then(res => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [uploadImageType.actions.UPDATE_COMMERCE_BANNER]: (
    { dispatch, },
    { data, commerce_id, }
  ) => {
    const from = Object.keys(data)[0];
    return new Promise((resolve, reject) => {
      http
        .post(`/backoffice/commerces/${commerce_id}/assets/${from}`, { data, })
        .then(res => {
          dispatch(
            `commerceModule/${commerce.actions.SINGLE_COMMERCE}`,
            commerce_id,
            { root: true, }
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [uploadImageType.actions.REGISTER_ICON_COMMERCE_KIND]: (
    _,
    { data, commerce_kind_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .post(`/backoffice/commerce_kinds/${commerce_kind_id}/icon`, { data, })
        .then(res => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  actions,
};
