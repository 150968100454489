
export default {
  mutations: {
    SET_TYPE_CONTACT_BRANCHES: 'SET_TYPE_CONTACT_BRANCHES',
    SET_SHOW_CONTACT_MODAL: 'SET_SHOW_CONTACT_MODAL',
  },
  getters: {
    GET_SHOW_CONTACT_BRANCHES: 'GET_SHOW_CONTACT_BRANCHES',
    GET_SHOW_CONTACT_MODAL: 'GET_SHOW_CONTACT_MODAL',
  },
  actions: {
    SHOW_CONTACT_BRANCHES: 'SHOW_CONTACT_BRANCHES',
  },
};
