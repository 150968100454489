import { GetterTree, ActionTree, MutationTree } from 'vuex';
import posType from '@/store/types/pos';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../interfaces/rootState';
import { responseCollection } from '@/store/interfaces/json-api/generics';
import { posParams, posState, tNewPosUser } from '../interfaces/pos';

const namespaced = true;

const initialState = (): posState => {
  return {
    posSystems: {
      data: [],
      pagination: {},
    },
  };
};

const state: posState = initialState();
const getters: GetterTree<posState, RootState> = {
  [posType.getters.GET_POS_LIST]: (state) => {
    return state.posSystems;
  },
};
const mutations: MutationTree<posState> = {
  [posType.mutations.SET_POS_LIST]: (
    state,
    transfers: responseCollection<posParams>
  ) => {
    state.posSystems.data = transfers.data;
    state.posSystems.pagination = transfers.meta.pagination;
  },
};
const actions: ActionTree<posState, RootState> = {
  [posType.actions.POS_LIST]: ({ commit, }, { branch_id, query, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/point_of_sales?branch_id_eq=${branch_id}&${query}`)
        .then((res: AxiosResponse) => {
          commit(posType.mutations.SET_POS_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [posType.actions.SAVE_POS_USER]: (_, user: tNewPosUser) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/point_of_sales', { data: user, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [posType.actions.UPDATE_POS_USER]: (_, { id, user, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/point_of_sales/${id}`, { data: user, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [posType.actions.REMOVE_POS_USER]: (_, id: string) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/point_of_sales/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
