



























import Vue, { PropType } from 'vue';
import { http } from '@/util/HTTP';
type CommerceBranch = { branch_id: string; commerce_id: string };
export default Vue.extend({
  name: 'CommerceBranchSelect',
  props: {
    companyId: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<CommerceBranch>,
      required: true,
    },
    withCommerceId: {
      type: Boolean,
      default: true,
    },
    withBranchId: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      http,
    };
  },
  watch: {
    'syncedFilters.commerce_id': function () {
      this.syncedFilters.branch_id = '';
    },
  },
  computed: {
    commerceUrl(): string {
      return `/backoffice/companies/${this.companyId}/commerces`;
    },
    branchUrl(): string {
      return `/backoffice/commerces/${this.syncedFilters.commerce_id}/branches?company_id_eq=${this.companyId}`;
    },
    syncedFilters: {
      get(): CommerceBranch {
        return this.value;
      },
      set(value: CommerceBranch): void {
        this.$emit('input', value);
      },
    },
  },
});
