import { tButtonColors } from '@/types/button';
import { tHostAllowed } from '@/types/hosts';

export const hosterButtonPrimary = (buttonType: tButtonColors): string => {
  const companyHost = process.env.VUE_APP_COMPANY_HOST as tHostAllowed;

  const hosterDictionary: Record<tHostAllowed, string> = {
    teip: buttonType,
    copadi: buttonType === 'primary' ? 'primary-copadi' : buttonType,
  };

  return hosterDictionary[companyHost] || buttonType;
};
