



































import { CompanyContact } from '@/store/interfaces/company';
import { phoneFormat } from '@/util/phoneFormat';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ShowContacts',
  props: {
    contact: {
      type: Object as PropType<CompanyContact>,
      required: true,
    },
    contactTitle: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    phoneFormat,
  }),
});
