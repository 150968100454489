







































import commerceType from '@/store/types/commerce';
import updateResource from '../../Reusable/updateImages.vue';
import imagePreview from '@/components/Reusable/imagePreview.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import { updateImageTypesRecieved } from '@/components/Reusable/updateImagesForm.vue';
import uploadimage from '@/store/types/uploadimage';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'PartnersCommercesImages',
  components: {
    updateResource,
    imagePreview,
  },
  data: () => ({
    updateImage: false,
    isSaveSuccess: false,
    updateErrors: {},
    resource: '',
    processing: false,
    images: [
      {
        id: 1,
        src: 'banner_url',
        type: 'banner',
        text: 'Banner',
      },
      {
        id: 2,
        src: 'logo_url',
        type: 'logo',
        text: 'Logo',
      },
      {
        id: 3,
        src: 'merchant_logo_url',
        type: 'merchant_logo',
        text: 'Merchant',
      }
    ],
  }),
  created() {
    if (!this.hostConfig.commerce.logo) {
      const findPositionOfLogo = this.images.findIndex((image) => image.type === 'logo');
      this.images.splice(findPositionOfLogo, 1);
    }

    if (!this.hostConfig.commerce.merchants_logo) {
      const findPositionOfMerchant = this.images.findIndex((image) => image.type === 'merchant_logo');
      this.images.splice(findPositionOfMerchant, 1);
    }

    if (!this.hostConfig.commerce.banner) {
      const findPositionOfBanner = this.images.findIndex((image) => image.type === 'banner');
      this.images.splice(findPositionOfBanner, 1);
    }
  },
  computed: {
    ...mapGetters({
      getSingleComerce: `commerceModule/${commerceType.getters.GET_SINGLE_COMMERCE}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  methods: {
    ...mapActions({
      updateCommerceBanner: `uploadImage/${uploadimage.actions.UPDATE_COMMERCE_BANNER}`,
      uploadImage: `uploadImage/${uploadimage.actions.UPLOAD_IMAGE}`,
    }),
    updateResource(resource: string): void {
      this.resource = resource;
      this.updateImage = true;
    },

    firstFormSubmit(files: updateImageTypesRecieved): void {
      this.processing = true;
      const uploadFile = {
        data: files.infoFile,
        currentFile: files.file,
      };
      const dataToSend = {
        commerce_id: this.$route.params.commerce_id,
        data: {
          [`${this.resource}`]: files.infoFile?.blob_signed_id,
        },
      };

      const notification = new TeipNotifications();

      this.uploadImage(uploadFile)
        .then(() => {
          this.updateCommerceBanner(dataToSend)
            .then(() => {
              notification.success('Actualizado correctamente');
              this.isSaveSuccess = true;
            })
            .catch(() => {
              notification.warning('Algo salió mal :(');
              this.isSaveSuccess = false;
            })
            .finally(() => {
              this.processing = false;
            });
        })
        .catch(() => {
          notification.warning('Algo salió mal al cargar la imágen :(');
        }).finally(() => {
          this.processing = false;
        });
    },
  },
});
