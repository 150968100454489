/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { DocumentKindParams, DocumentsImages, State } from '@/store/interfaces/document';
// Establece los types de los modulos store
import documentType from '@/store/types/document';
import { http } from '@/util/HTTP';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): State => {
  return {
    documents_kind: {
      data: [],
      pagination: {},
    },
    documents_images: {
      data: [],
      pagination: {},
    },
  };
};
const state: State = initialState();

const getters: GetterTree<State, any> = {
  [documentType.getters.GET_DOCUMENT_KIND]: state => {
    return state.documents_kind;
  },
  [documentType.getters.GET_DOCUMENTS_IMAGES]: state => {
    return state.documents_images;
  },
};
const mutations: MutationTree<State> = {
  [documentType.mutations.SET_DOCUMENT_KIND]: (state, documents: responseCollection<DocumentKindParams>) => {
    state.documents_kind.data = documents.data;
    state.documents_kind.pagination = documents.meta.pagination;
  },
  [documentType.mutations.SET_DELETE_DOCUMENT_KIND]: (state, id: string) => {
    const filterDocuments = state.documents_kind.data.filter(
      document => document.id !== id
    );

    state.documents_kind.data = filterDocuments;
  },
  [documentType.mutations.SET_SAVE_DOCUMENT_KIND]: (state, document) => {
    const saveNewDocument = {
      id: document.data.id,
      name: document.data.name,
      description: document.data.description,
    };
    state.documents_kind.data.unshift(saveNewDocument);
  },
  [documentType.mutations.SET_UPDATE_DOCUMENT_KIND]: (state, document) => {
    const positionDocument = state.documents_kind.data.findIndex(
      (find) => find.id === document.data.id
    );
    state.documents_kind.data[positionDocument].name = document.data.name;
    state.documents_kind.data[positionDocument].description =
      document.data.description;
  },
  [documentType.mutations.SET_DOCUMENTS_IMAGES]: (state, document: responseCollection<DocumentsImages>) => {
    state.documents_images.data = document.data;
    state.documents_images.pagination = document.meta.pagination;
  },
  [documentType.mutations.SET_SINGLE_DOCUMENT_IMAGE]: (state, document: DocumentsImages) => {
    state.documents_images.data.unshift(document);
  },
};
const actions: ActionTree<State, any> = {
  [documentType.actions.SAVE_DOCUMENT_KIND]: ({ commit, }, document) => {
    const dataToSend = {
      data: {
        name: document.document,
        description: document.description,
      },
    };
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/document_kinds', dataToSend)
        .then(res => {
          commit(documentType.mutations.SET_SAVE_DOCUMENT_KIND, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.LIST_DOCUMENT_KIND]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/document_kinds')
        .then(res => {
          commit(documentType.mutations.SET_DOCUMENT_KIND, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.DELETE_DOCUMENT_KIND]: ({ commit, }, id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/document_kinds/${id}`)
        .then(res => {
          if (res.status === 204) {
            commit(documentType.mutations.SET_DELETE_DOCUMENT_KIND, id);
          }
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.UPDATE_DOCUMENT_KIND]: ({ commit, }, document) => {
    const dataToSend = {
      data: {
        name: document.document,
        description: document.description,
      },
    };
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/document_kinds/${document.id}`, dataToSend)
        .then(res => {
          commit(documentType.mutations.SET_UPDATE_DOCUMENT_KIND, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.DOCUMENTS_BY_COMPANY]: ({ commit, }, company_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/company_documents`)
        .then(res => {
          commit(documentType.mutations.SET_DOCUMENTS_IMAGES, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.UPDATE_DOCUMENT_COMPANY_CONTACT]: (_, { url, document_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/${url}/${document_id}`, { data, })
        .then(res => {
          // commit(documentType.mutations.SET_DOCUMENTS_IMAGES, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.SAVE_NEW_DOCUMENT_UPDATED]: (_, { url, document_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/${url}/${document_id}/document`, { data, })
        .then(res => {
          // commit(documentType.mutations.SET_DOCUMENTS_IMAGES, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  [documentType.actions.DOCUMENTS_BY_CONTACT]: ({ commit, }, contact_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/contacts/${contact_id}/contact_documents`)
        .then(res => {
          commit(documentType.mutations.SET_DOCUMENTS_IMAGES, res.data);
          resolve(res);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
