<template>
  <label
    class="custom-toggle"
    :class="switchClass"
  >
    <input v-model="model" type="checkbox" />
    <span
      class="custom-toggle-slider rounded-circle"
      :data-label-off="offText"
      :data-label-on="onText"
    >
    </span>
  </label>
</template>
<script>
export default {
  name: 'BaseSwitch',
  props: {
    value: [Array, Boolean],
    type: String,
    onText: {
      type: String,
      default: 'Yes',
    },
    offText: {
      type: String,
      default: 'No',
    },
  },
  computed: {
    switchClass() {
      let baseClass = 'custom-toggle-';
      if (this.type) {
        return baseClass + this.type;
      }

      if (process.env.VUE_APP_COMPANY_HOST === 'copadi') {
        return baseClass + 'primary-copadi';
      }

      return '';
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    triggerToggle() {
      this.model = !this.model;
    },
  },
};
</script>
