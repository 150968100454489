/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { StatSingle, StatState } from '@/store/interfaces/stats';
import { RootState } from '@/store/interfaces/rootState';
import statType from '@/store/types/stats';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
const namespaced = true;
const initialState = (): StatState => {
  return {
    stats: {
      total: 0,
      total_success: 0,
      total_failure: 0,
      total_with_risk: 0,
      total_with_risk_low: 0,
      total_with_risk_moderate: 0,
      total_with_risk_high: 0,
      total_today: 0,
      total_today_success: 0,
      total_today_failure: 0,
      total_today_with_risk: 0,
      total_last_week: 0,
      total_last_week_success: 0,
      total_last_week_failure: 0,
      total_last_week_with_risk: 0,
      total_last_month: 0,
      total_last_month_success: 0,
      total_last_month_failure: 0,
      total_last_month_with_risk: 0,
    },
  };
};

const state: StatState = initialState();
const getters: GetterTree<StatState, RootState> = {
  [statType.getters.STATS]: (state) => {
    return state.stats;
  },
};
const mutations: MutationTree<StatState> = {
  [statType.mutations.SET_STATS]: (
    state,
    stats: StatSingle
  ) => {
    state.stats = stats.data;
  },
};
const actions: ActionTree<StatState, RootState> = {
  [statType.actions.GET_STATS]: async ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('/backoffice/stats/payment_intents')
        .then((res: AxiosResponse) => {
          commit(statType.mutations.SET_STATS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        })
        .finally(() => {

        });
    });
  },
};
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
