


































































import document from '@/store/types/document';
import DropzoneFileUpload from '@/components/Reusable/UploadDocumentCompanyContact.vue';
import uploadimageType from '@/store/types/uploadimage';
import company from '@/store/types/company';
import { TeipNotifications } from '@/util/teipNotifications';
import { DocumentKindParams } from '@/store/interfaces/document';
import { ImageUpload } from '@/store/interfaces/uploadimage';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'UploadDocumentModal',
  components: { DropzoneFileUpload, },
  props: {
    viewModal: {
      type: Boolean,
      required: true,
    },
    documentFor: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    documents: [] as ImageUpload[],
    loading: false,
    mask: '',
    rules: '',
    documentKindId: '',
    clean: false,
  }),
  computed: {
    ...mapGetters({
      getDocumentKindList: `documentModule/${document.getters.GET_DOCUMENT_KIND}`,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean) {
        this.$emit('update:viewModal', value);
      },
    },
    docSelected(): string | undefined {
      const document = this.getDocumentKindList.data.find(
        (document: DocumentKindParams) =>
          document.id === this.$data.documentKindId
      );
      return document?.name;
    },
  },
  methods: {
    ...mapActions({
      uploadImage: `uploadImage/${uploadimageType.actions.UPLOAD_IMAGE}`,
      companyDocument: `companyModule/${company.actions.COMPANY_DOCUMENTS}`,
    }),
    cancelUploadDocument(): void {
      this.syncedViewModal = false;
    },
    async uploadingDocument(): Promise<void> {
      this.$data.loading = true;
      const notification = new TeipNotifications();
      const company_document = (
        this.$refs.uploadDocument as InstanceType<typeof DropzoneFileUpload>
      ).$data.register_document_data;

      let url = 'contact_documents';
      if (this.$props.documentFor === 'company') {
        url = 'company_documents';
      }
      company_document.company_id = this.$route.params.company_id;
      company_document.contact_id = this.$route.params.contact_id;
      company_document.document_kind_id = this.$data.documentKindId;

      await this.uploadImage(
        (this.$refs.uploadDocument as InstanceType<typeof DropzoneFileUpload>)
          .$data
      )
        .then(() => {
          this.companyDocument({
            url,
            data: company_document,
          })
            .then(() => {
              notification.success('Documento guardado con éxito :)');
              this.$data.clean = true;
              this.$emit('success', true);
              this.syncedViewModal = false;
            })
            .catch(() => {
              notification.warning(
                `No pudimos registrar el documento a la ${this.$t('menu.company.singular.informal')} :(`
              );
            });
        })
        .catch(() => {
          notification.warning('Ha ocurrido un error al carga el documento :(');
        })
        .finally(() => {
          this.$data.clean = false;
          this.$data.loading = false;
        });
    },
  },
});
