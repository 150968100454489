















































import paymentsGateway from '@/store/types/paymentsGateway';
import root from '@/store/types/root';
import { companyCommercePaymentGatewayParam } from '@/store/interfaces/company_commerce';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'ModelConfigurationForm',
  props: {
    model: {
      required: false,
      type: Object,
    },
    cleanValidation: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      getPaymentGatewayList: `paymentGatewayModule/${paymentsGateway.getters.GET_GATEWAY_PAYMENT}`,
    }),
  },
  async mounted(): Promise<void> {
    await this.paymentsGatewayList();
  },
  methods: {
    ...mapActions({
      paymentsGatewayList: `paymentGatewayModule/${paymentsGateway.actions.GATEWAY_PAYMENT}`,
    }),
    confirmChangeStatus(): void {
      this.$emit('isReadyToUpdate', true);
    },
  },
  watch: {
    cleanValidation(clean: boolean): void {
      if (clean) (this.$refs.formUpdateTip as InstanceType<typeof ValidationProvider>).reset();
    },
    model: {
      handler(model: companyCommercePaymentGatewayParam): void {
        setTimeout(() => {
          this.$emit('update:model', model);
        }, 500);
      },
      deep: true,
      immediate: true,
    },
  },
});
