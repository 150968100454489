/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  PermissionGroupParams,
  PermissionGroupState
} from '@/store/interfaces/PermissionGroup';

import { SectionsGroupCollectionResponse } from '@/store/interfaces/PermissionSections';

import { RootState } from '@/store/interfaces/rootState';

// Establece los types de los modulos store
import permissionGroupType from '@/store/types/permissionGroup';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { responseCollection } from '../interfaces/json-api/generics';
const namespaced = true;

const initialState = (): PermissionGroupState => {
  return {
    PermissionGroupsList: {
      data: [],
      pagination: {},
    },
    sectionsGroupsList: [],
    singlePermissionGroup: {},
  };
};

const state: PermissionGroupState = initialState();

const getters: GetterTree<PermissionGroupState, RootState> = {
  [permissionGroupType.getters.GET_PERMISSIONS_GROUP_LIST]: state => {
    return state.PermissionGroupsList;
  },
  [permissionGroupType.getters.GET_PERMISSION_SECTION_LIST]: state => {
    return state.sectionsGroupsList;
  },
  [permissionGroupType.getters.GET_SINGLE_PERMISSION_GROUP]: state => {
    return state.singlePermissionGroup;
  },
};
const mutations: MutationTree<PermissionGroupState> = {
  [permissionGroupType.mutations.SET_PERMISSIONS_GROUP_LIST]: (
    state,
    states: responseCollection<PermissionGroupParams>
  ) => {
    const permissionGroup: Array<PermissionGroupParams> = [];
    states.data.map((permission: PermissionGroupParams) => {
      permission.virtual_permission_count = permission.permissions.length;
      permissionGroup.push(permission);
    });
    state.PermissionGroupsList.data = permissionGroup;
    state.PermissionGroupsList.pagination = states.meta.pagination;
  },
  [permissionGroupType.mutations.SET_PERMISSION_SECTION_LIST]: (
    state,
    states: SectionsGroupCollectionResponse
  ) => {
    state.sectionsGroupsList = states.data;
  },
  [permissionGroupType.mutations.SET_SINGLE_PERMISSION_GROUP]: (
    state,
    states: { data: PermissionGroupParams }
  ) => {
    state.singlePermissionGroup = states.data;
  },
};
const actions: ActionTree<PermissionGroupState, RootState> = {
  [permissionGroupType.actions.PERMISSION_GROUP_LIST]: (
    { commit, },
    query
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/permission_groups?${query}`)
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_PERMISSIONS_GROUP_LIST,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [permissionGroupType.actions.PERMISSION_SECTION_LIST]: (
    { commit, },
    kind = 0
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/sections?kind_eq=${kind}&per_page=100`)
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_PERMISSION_SECTION_LIST,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [permissionGroupType.actions.SAVE_PERMISSION_GROUP]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/permission_groups', { data, })
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_SINGLE_PERMISSION_GROUP,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [permissionGroupType.actions.SINGLE_PERMISSION_GROUP]: (
    { commit, },
    permission_id: string
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/permission_groups/${permission_id}`)
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_SINGLE_PERMISSION_GROUP,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [permissionGroupType.actions.UPDATE_PERMISSION_GROUP]: (
    { commit, },
    { permission_id, data, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/permission_groups/${permission_id}`, { data, })
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_SINGLE_PERMISSION_GROUP,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
