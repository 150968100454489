



















































import changeCommerceToCompany from './../forms/changeCommerceToCompanyForm.vue';
import updateTipPercent from './../forms/updateTipPercent.vue';
import updateLiquidationModel from '@/components/commerce/Partners/modals/updateLiquidationModel.vue';
import company_commerce from '@/store/types/company_commerce';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import configurations from '@/store/types/configurations';
import CodeAndStatus from '../forms/codeAndStatus.vue';
import Currencies from '../forms/currencies.vue';
import NakaTags from '../forms/NakaTags.vue';

export default Vue.extend({
  name: 'ConfigCompanyCommerceModal',
  components: {
    changeCommerceToCompany,
    updateLiquidationModel,
    updateTipPercent,
    CodeAndStatus,
    Currencies,
    NakaTags,
  },
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    hostCompany: `${process.env.VUE_APP_COMPANY_HOST}`,
  }),
  computed: {
    ...mapGetters({
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
    codeAndStatusTabLabel(): string {
      return this.hostConfig.commerce.code ? 'Código y status' : 'Status';
    },
    kindSlugValidator(): boolean {
      return this.singleCompanyCommerce.liquidation_kind_slug === 'agregador';
    },
    titleTabForTipAndDeliveryFee(): string {
      if (this.hostConfig.globals.tip && this.hostConfig.globals.delivery_fee) {
        return 'Propina y Delivery fee';
      }

      if (this.hostConfig.globals.tip) {
        return 'Propina';
      }

      if (this.hostConfig.globals.delivery_fee) {
        return 'Delivery fee';
      }

      return '';
    },
  },
  methods: {
    success(): void {
      this.syncedViewModal = false;
      this.$emit('success');
    },
  },
});
