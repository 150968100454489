



































































import userStaffType from '@/store/types/userStaff';
import { itemsRoute, navLink } from '@/ts/navegationLinks';
import { nullToString } from '@/util/transformString/nullValidation';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ProfileStaff',
  data: () => ({
    nullToString,
    items: [
      {
        id: 1,
        icon: 'fas fa-user',
        title: 'Datos',
        route: 'user-profile-staff',
      },
      {
        id: 2,
        icon: 'fas fa-lock',
        can: 'index_permission_groups',
        title: 'Permisos',
        route: 'user-staff-permissions',
      }
    ] as itemsRoute[],
    navlink: [
      {
        name: 'Usuarios staff',
        active: false,
        to: '/staff',
      },
      {
        name: 'Perfil de usuario',
        active: true,
      }
    ] as navLink[],
    loading: true,
  }),
  computed: {
    ...mapGetters({
      getUserStaff: `userStaff/${userStaffType.getters.GET_SINGLE_USER_STAFF}`,
    }),
  },
  async mounted(): Promise<void> {
    const user_id = this.$route.params.user_id;
    await this.singleUserStaff(user_id);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      singleUserStaff: `userStaff/${userStaffType.actions.USER_SINGLE_STAFF}`,
    }),
  },
});
