/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  UsersAppState,
  UsersAppParams,
  UserBalanceAccount,
  UserCreditCard,
  userCardStatus
} from '@/store/interfaces/usersApp';
// Establece los types de los modulos store
import userAppType from '@/store/types/usersApp';
import { AxiosError, AxiosResponse } from 'axios';
import { http } from '@/util/HTTP';
import transactionsModule from './childrens/transactionsCustomer';
import deviceModule from './childrens/devicesUserCustomer';
import { responseCollection, responseDataPagination } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): UsersAppState => {
  return {
    userCards: {
      data: [],
      pagination: {},
    },
    users: {
      data: [],
      pagination: {},
    },
    user: {
      confirmed_email: false,
      created_at: '',
      email: '',
      handle: '',
      id: '',
      banned: false,
      profile: {
        avatar_url: null,
        birthdate: '',
        first_name: '',
        gender: 'F',
        id: '',
        last_name: '',
        phone_number: '',
      },
      statistics: {
        failure_transactions_number: 0,
        first_transaction_at: '',
        last_failure_transaction_at: null,
        last_risk_transaction_at: '',
        last_success_transaction_at: '',
        last_transaction_at: '',
        risk_transactions_number: 0,
        success_transactions_number: 0,
        transactions_number: 0,
      },
    },
    user_balance_account: [],
  };
};

const state: UsersAppState = initialState();
const getters: GetterTree<UsersAppState, any> = {
  [userAppType.getters.GET_USER_CREDIT_CARDS]: state => {
    return state.userCards;
  },
  [userAppType.getters.GET_USERS]: state => {
    return state.users;
  },
  [userAppType.getters.GET_SINGLE_USER]: state => {
    return state.user;
  },
  [userAppType.getters.GET_USER_BALANCE]: state => {
    return state.user_balance_account;
  },
};
const mutations: MutationTree<UsersAppState> = {
  [userAppType.mutations.SET_USERS]: (
    state,
    users: responseCollection<UsersAppParams>
  ) => {
    state.users = {
      data: users.data,
      pagination: users.meta.pagination,
    };
  },
  [userAppType.mutations.SET_SINGLE_USER]: (
    state,
    user: UsersAppParams
  ) => {
    state.user = user;
  },
  [userAppType.mutations.SET_USER_BALANCE]: (
    state,
    balance: responseDataPagination<UserBalanceAccount>
  ) => {
    state.user_balance_account = balance.data;
  },
  [userAppType.mutations.SET_USER_CREDIT_CARDS]: (
    state,
    cards: responseCollection<UserCreditCard>
  ) => {
    state.userCards.data = cards.data;
    state.userCards.pagination = cards.meta.pagination;
  },
  [userAppType.mutations.SET_NEW_VALUE_CARD_RESETED]: (
    state,
    {
      card,
      cardId,
    }: { card: { status: userCardStatus }, cardId: string }
  ) => {
    const findedCard = state.userCards.data.find((crd) => crd.id === cardId);
    if (findedCard) {
      findedCard.status = card.status;
    }
  },
};
const actions: ActionTree<UsersAppState, any> = {
  [userAppType.actions.USERS_LIST]: ({ commit, }, queryParams: string) => {
    return new Promise((resolve, reject) => {
      const query = (queryParams || '&page=1&per_page=10');
      http
        .get(`backoffice/customers?${query}`)
        .then((res: AxiosResponse) => {
          commit(userAppType.mutations.SET_USERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userAppType.actions.USER_BALANCE]: ({ commit, }, user_id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/customers/${user_id}/balance/accounts`)
        .then((res: AxiosResponse) => {
          commit(userAppType.mutations.SET_USER_BALANCE, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userAppType.actions.USER_CREDIT_CARDS]: ({ commit, }, { query, user_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/customers/${user_id}/payment_methods?${query}`)
        .then((res: AxiosResponse) => {
          commit(userAppType.mutations.SET_USER_CREDIT_CARDS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userAppType.actions.USER_CREDIT_CARDS_RESET_VERIFICATION]: ({ commit, }, { user_id, id, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/customers/${user_id}/payment_methods/${id}/reset_verification`)
        .then((res: AxiosResponse) => {
          commit(userAppType.mutations.SET_NEW_VALUE_CARD_RESETED, { card: res.data, cardId: id, });
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userAppType.actions.SINGLE_USER]: (
    { commit, state, },
    users_app_id: string
  ) => {
    return new Promise((resolve, reject) => {
      const singleUser = state.users.data.find(
        (user: UsersAppParams) => user.id === users_app_id
      );
      if (singleUser) {
        commit(userAppType.mutations.SET_SINGLE_USER, singleUser);
        resolve(singleUser);
      } else {
        http
          .get(`backoffice/customers/${users_app_id}`)
          .then((res: AxiosResponse) => {
            commit(userAppType.mutations.SET_SINGLE_USER, res.data.data);
            resolve(res);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      }
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    transactions: transactionsModule,
    devices: deviceModule,
  },
};
