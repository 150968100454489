















































































import { posParams, tNewPosUser } from '@/store/interfaces/pos';
import { allowAny } from 'teip-components';
import { ValidationProvider } from 'vee-validate';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'PosUserModal',
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
    success: {
      required: true,
      type: Boolean,
    },
    update: {
      required: true,
      type: Boolean,
    },
    pos: {
      required: true,
      type: Object as PropType<posParams>,
    },
    errors: {
      required: true,
      type: [Array, Object] as PropType<Record<string, allowAny>>,
    },
  },
  data: () => ({
    posUser: {
      profile: {
        first_name: '',
        last_name: '',
      },
      email: '',
      branch_id: '',
      hardware_configuration: {
        terminal_id: '',
        identifier: '',
      },
    } as tNewPosUser,
  }),
  computed: {
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(val: boolean): void {
        this.$emit('update:viewModal', val);
      },
    },
  },
  methods: {
    close(): void {
      this.syncedViewModal = false;
    },
    posUserSave(): void {
      this.$emit('user', this.posUser);
    },
    resetPosUser(): void {
      this.posUser = {
        profile: {
          first_name: '',
          last_name: '',
        },
        email: '',
        branch_id: '',
        hardware_configuration: {
          terminal_id: '',
          identifier: '',
        },
      };
    },
  },
  watch: {
    errors: {
      deep: true,
      handler(val: string[]): void {
        (
          this.$refs.formPosUser as InstanceType<typeof ValidationProvider>
        ).setErrors(val);
      },
    },
    success: {
      deep: true,
      handler(val: boolean): void {
        if (val) {
          this.resetPosUser();
          this.$emit('update:success', false);
          (
            this.$refs.formPosUser as InstanceType<typeof ValidationProvider>
          ).reset();
          this.close();
        }
      },
    },
    update: {
      deep: true,
      handler(ps: boolean): void {
        if (ps) {
          this.posUser.hardware_configuration.identifier =
            this.pos.hardware_configuration.identifier;
          this.posUser.hardware_configuration.terminal_id =
            this.pos.hardware_configuration.terminal_id;
          this.posUser.profile.first_name = this.pos.profile.first_name;
          this.posUser.profile.last_name = this.pos.profile.last_name;
          this.posUser.email = this.pos.email;
        } else {
          this.resetPosUser();
        }
        (
          this.$refs.formPosUser as InstanceType<typeof ValidationProvider>
        ).reset();
      },
    },
  },
});

