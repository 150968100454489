















import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import resources from '@/store/types/resources';

export default Vue.extend({
  name: 'TaxpayersTypesSelect',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  async mounted() {
    await this.fetchTaxpayersTypes();
    this.loading = false;
  },
  computed: {
    syncedValue: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
    ...mapGetters({
      taxpayersTypes: `resourcesModule/${resources.getters.GET_TAXPAYER_TYPES}`,
    }),
  },
  methods: {
    ...mapActions({
      fetchTaxpayersTypes: `resourcesModule/${resources.actions.TAXPAYER_TYPES}`,
    }),
  },
});
