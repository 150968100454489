























import Vue from 'vue';

export default Vue.extend({
  name: 'InboundCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    withoutEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      edit: false,
    };
  },
  watch: {
    edit(edit: boolean) {
      this.$emit('edit', edit);
    },
  },
});
