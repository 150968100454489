




























































































import { currencyKeyboardEventValidation } from '@/util/CurrencyValidation';
import { EnterpriceCommissions } from '@/store/interfaces/company';
import flatPicker from '@/components/FlatPickr/FlatPicker.vue';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'CommissionsForms',
  components: {
    flatPicker,
  },
  props: {
    commissions: {
      type: Object as PropType<EnterpriceCommissions>,
      required: true,
    },
    action: {
      type: String,
      required: false,
      default: 'update',
    },
  },
  data: () => ({
    currencyKeyboardEventValidation,
    default_liquidation_period: 1,
    default_renewal: 1,
    liquidation_period: [
      { id: 'daily', name: 'Diaria', },
      { id: 'weekly', name: 'Semanal', },
      { id: 'monthly', name: 'Mensual', }
    ],
    commissionsInfo: {},
    renewal_array: [
      {
        key: 'automatic',
        value: 'Automático',
      },
      {
        key: 'manual',
        value: 'Manual',
      }
    ],
  }),
  created(): void {
    this.setInitialConfig();
  },
  methods: {
    setInitialConfig(): void {
      this.commissionsInfo = this.commissions;
      this.setLiquidationPeriod(this.commissions.liquidation_period);
      if (this.action === 'update') {
        this.renewalValue(this.commissions.renewal);
      }
    },
    renewalValue(renewal: string): void {
      if (renewal === 'manual') {
        this.default_renewal = 2;
      }
    },
    setLiquidationPeriod(period: string): void {
      if (period === 'daily') {
        this.default_liquidation_period = 1;
      } else if (period === 'weekly') {
        this.default_liquidation_period = 2;
      } else {
        this.default_liquidation_period = 3;
      }
    },
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  watch: {
    commissions: {
      immediate: true,
      deep: true,
      handler(vale: EnterpriceCommissions): void {
        if (this.action === 'create') {
          this.setInitialConfig();
        }
        setTimeout(() => {
          this.$emit('commissions', vale);
        }, 100);
      },
    },
  },
});
