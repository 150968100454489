import { allowAny, IObjects } from 'teip-components';
/**
 *
 * @param value null | string
 * @returns string
 */
function nullToString(value: null | string): string {
  if (value === null) return '';
  return value;
}

/**
 *
 * @param value object
 * @returns Object
 */
function objectValueNull<T=IObjects>(value: T): T {
  const temporal: allowAny = {};
  Object.entries(value).forEach(([v, k]) => {
    if (typeof k === 'string') {
      temporal[v] = nullToString(k);
    } else if (typeof k === 'object') {
      if (k && k.length) {
        temporal[v] = arrayValueNull(k);
      } else if (k) {
        temporal[v] = objectValueNull(k);
      } else {
        temporal[v] = nullToString(k);
      }
    } else {
      temporal[v] = k;
    }
  });
  return temporal;
}

/**
 *
 * @param array params required must be an array
 * @returns array of type T, by default is IObjects type
 */
function arrayValueNull<T=IObjects>(array: Array<T>): Array<T> {
  const temporalArray: Array<T> = [];
  array.map(array => {
    temporalArray.push(objectValueNull(array));
  });
  return temporalArray;
}

export {
  arrayValueNull,
  nullToString,
  objectValueNull
};
