













import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import intermediaries from '@/store/types/intermediaries';

export default Vue.extend({
  name: 'IntermediarySelect',
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  data: () => ({ loading: true, }),
  methods: {
    ...mapActions({
      intermerdiariesList: `intermediariesModule/${intermediaries.actions.INTERMEDIARIES}`,
    }),
  },
  async mounted() {
    await this.intermerdiariesList();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getIntermediariesList: `intermediariesModule/${intermediaries.getters.GET_INTERMEDIARIES}`,
    }),
    intermediary: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
});
