/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import { checkPermissions, ifAuthenticated } from './../routeValidation';

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { customerUserPermissions } from './permissions/index';
// Users
const Accounts = () =>
  import(/* webpackChunkName: "Accounts" */ '@/views/UsersApps/Account.vue');

const UserProfile = () =>
  import(
    /* webpackChunkName: "UserProfile" */ '@/views/UsersApps/Profile.vue'
  );

const usersPages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'Users',
  children: [
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, customerUserPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/accounts/:user_id',
      component: UserProfile,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_customers_users');
      },
      meta: {
        navbarType: 'light',
      },
      children: [
        {
          name: 'user-transactions',
          path: '',
          component: require('@/components/Users/Transactions.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_payment_requests');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'user-withdrawals',
          path: 'withdrawals',
          component: require('@/components/Users/Withdrawals.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_withdrawals');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'user-transfer',
          path: 'transfer',
          component: require('@/components/Users/Transfers.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_transfer_requests');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'transactions-payment-intent',
          path: 'transactions/:transaction_id',
          component: require('@/components/Users/PaymentIntents.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'show_customers_users');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'user-devices',
          path: 'devices',
          component: require('@/components/Users/Devices.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_devices');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'user-cards',
          path: 'cards',
          component: require('@/components/Users/Cards.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_payment_methods');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'sessions-devices',
          path: 'devices/:device_id',
          component: require('@/components/Users/sessionDevices.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_customers_sessions');
          },
          meta: {
            navbarType: 'light',
          },
        }
      ],
    }
  ],
};
export default usersPages;
