var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('div',[_c('b-card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"md":"auto"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Intentos de pago")])])],1),(Object.keys(_vm.getTransactionList.data).length > 0)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('div',{staticClass:"d-flex flex-column"},[_c('strong',[_vm._v("Usuario:")]),_c('span',[_vm._v("\n                  "+_vm._s(_vm.successTransactions.user_name)+"\n                ")])])]),_c('b-col',[_c('div',{staticClass:"d-flex flex-column"},[_c('strong',[_vm._v(" Comercio: ")]),_c('span',[_vm._v("\n                  "+_vm._s(_vm.successTransactions.commerce_name)+"\n                ")])])]),_c('b-col',[_c('div',{staticClass:"d-flex flex-column"},[_c('strong',[_vm._v(" Sucursal: ")]),_c('span',[_vm._v("\n                  "+_vm._s(_vm.successTransactions.branch_name)+"\n                ")])])])],1):_vm._e()],1),_c('teip-table',{attrs:{"props-to-search":_vm.propsToSearch,"table-columns":_vm.tableColumns,"host":_vm.companyHost,"table-data":_vm.getPaymentIntentList.data,"fetch-callback":_vm.paymentIntent,"ids":{
            user_id: _vm.$route.params.user_id,
            payment_id: _vm.$route.params.transaction_id,
          },"table-pagination":_vm.getPaymentIntentList.pagination,"can-search":false,"table":"intentos de pago","trigger-drop-down":"hover","dropdown":false},scopedSlots:_vm._u([{key:"status",fn:function(ref){
          var row = ref.row;
return [_c('StatusBadges',{attrs:{"status":row.status}})]}},{key:"card",fn:function(ref){
          var row = ref.row;
return [_c('credit-card',{staticClass:"d-inline",attrs:{"card-last-digits":row.card_last_digits,"card-network":row.card_network}})]}},{key:"amount_cents",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.moneyFormat(row.amount_cents / 100)))])]}}])})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }