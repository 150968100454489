import { paymentsLinkState, tPaymentsLinkParams } from './../interfaces/paymentsLink';

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import paymentsLinkType from '@/store/types/paymentsLink';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection } from '@/store/interfaces/json-api/generics';
import { allowAny } from 'teip-components';

const namespaced = true;

const state: paymentsLinkState = {
  paymentsLink: {
    data: [],
    pagination: {},
  },
};
const getters: GetterTree<paymentsLinkState, allowAny> = {
  [paymentsLinkType.getters.GET_PAYMENTS_LINK]: state => {
    return state.paymentsLink;
  },
};
const mutations: MutationTree<paymentsLinkState> = {
  [paymentsLinkType.mutations.SET_PAYMENTS_LINK]: (
    state,
    paymentsGateway: responseCollection<tPaymentsLinkParams>
  ) => {
    state.paymentsLink.data = paymentsGateway.data;
    state.paymentsLink.pagination = paymentsGateway.meta.pagination;
  },
};
const actions: ActionTree<paymentsLinkState, allowAny> = {
  [paymentsLinkType.actions.PAYMENTS_LINK]: (
    { commit, }, { id, query, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${id}/branch_payment_links?${query}`)
        .then((res: AxiosResponse) => {
          commit(
            paymentsLinkType.mutations.SET_PAYMENTS_LINK,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
