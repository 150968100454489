








































import { VuexFunction } from '@/ts/axios';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import { responseDataPagination } from '@/store/interfaces/json-api/generics';
import { transferParams } from '@/store/interfaces/transfer';
import DatesFormat from '@/util/classes/datesFormat';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import PaymentMethods from '@/components/Reusable/PaymentMethods.vue';
import DetailTransfer from '@/components/TransactionTickets/Transfer/Detail.vue';
import Vue, { PropType } from 'vue';
import { companyHost } from '@/const/companyHost';

const formatDate = new DatesFormat();

export default Vue.extend({
  name: 'TransfersTable',
  components: {
    StatusBadges,
    PaymentMethods,
    DetailTransfer,
  },
  props: {
    query: {
      required: true,
      type: String,
    },
    canDropdown: {
      required: true,
      type: String,
    },
    transferList: {
      required: true,
      type: Function as PropType<VuexFunction>,
    },
    transferListData: {
      required: true,
      type: Object as PropType<responseDataPagination<transferParams>>,
    },
    ids: {
      required: false,
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    withUser: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    companyHost,
    viewDetails: false,
    enableRefund: false,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'recipient_name',
        label: 'Destinatario',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'recipient.handle',
        label: 'Teiptag destinatario',
        minWidth: 250,
        sortable: true,
        formatter: (row: transferParams): string => {
          return `$${row.recipient.handle}`;
        },
      },
      {
        prop: 'user_name',
        label: 'Usuario',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'amount_formatted',
        label: 'Total',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'paymentMethod',
        label: 'Método de pago',
        minWidth: 150,
        sortable: true,
        slotName: 'paymentMethod',
      },
      {
        prop: 'subtotal_formatted',
        label: 'Estado',
        minWidth: 120,
        sortable: true,
        slotName: 'status',
      },
      {
        prop: 'created_at',
        label: 'Fecha y Hora',
        minWidth: 180,
        sortable: true,
        formatter: (row: transferParams): string => {
          return `${formatDate.formatDateTime(row.created_at)}`;
        },
      }
    ] as tTableColumns,
    rowSelected: null as transferParams | null,
  }),

  computed: {
    tableColumnsFiltered(): tTableColumns {
      if (this.withUser) return this.tableColumns;
      return this.tableColumns.filter((tb) => tb.prop !== 'user_name');
    },
  },
  methods: {
    rowselect(row: transferParams): void {
      this.rowSelected = row;
    },
    viewDetailsAction(): void {
      this.viewDetails = true;
    },
  },
});
