export default {
  mutations: {
    AUTHORIZE: 'AUTHORIZE',
    AUTH_REQUEST: 'AUTH_REQUEST',
    AUTH_SUCCESS: 'AUTH_SUCCESS',
    AUTH_ERROR: 'AUTH_ERROR',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_PERMISSIONS: 'AUTH_PERMISSIONS',
    SET_AUTH_USER: 'SET_AUTH_USER',
    SET_TOKEN_CONFIGURATION: 'SET_TOKEN_CONFIGURATION',
    SET_UPDATE_AUTH_USER: 'SET_UPDATE_AUTH_USER',
    SET_CONCAT_FEATURES_FLAG_TO_THE_PERMISSIONS: 'SET_CONCAT_FEATURES_FLAG_TO_THE_PERMISSIONS',
  },
  getters: {
    IS_AUTHENTICATED: 'IS_AUTHENTICATED',
    AUTH_STATUS: 'AUTH_STATUS',
    AUTH_PERMISSIONS: 'AUTH_PERMISSIONS',
    GET_AUTH_USER: 'GET_AUTH_USER',
    PERMISSIONS: 'PERMISSIONS',
    GET_USER_SESSION: 'GET_USER_SESSION',
  },
  actions: {
    AUTHORIZE: 'AUTHORIZE',
    SEND_PASSWORD_INSTRUCTIONS: 'SEND_PASSWORD_INSTRUCTIONS',
    AUTH_REQUEST: 'AUTH_REQUEST',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SEND_UNLOCK_INSTRUCTIONS: 'SEND_UNLOCK_INSTRUCTIONS',
    UNLOCK_ACCOUNT: 'UNLOCK_ACCOUNT',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    EXPIRES_IN: 'EXPIRES_IN',
    UPDATE_MY_PROFILE: 'UPDATE_MY_PROFILE',
  },
};
