/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import devicesTypes from '@/store/types/devicesUserCustomer';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import {
  DeviceCustomerUserState,
  DeviceCustomUserParams,
  DeviceSessionsParams
} from '@/store/interfaces/devicesUserCustomer';
import { responseCollection } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const state: DeviceCustomerUserState = {
  devices: { data: [], pagination: {}, },
  sessions_by_device: { data: [], pagination: {}, },
};
const getters: GetterTree<DeviceCustomerUserState, any> = {
  [devicesTypes.getters.GET_DEVICES_CUSTOMER_LIST]: state => {
    return state.devices;
  },
  [devicesTypes.getters.GET_SESSIONS_BY_DEVICE]: state => {
    return state.sessions_by_device;
  },
};
const mutations: MutationTree<DeviceCustomerUserState> = {
  [devicesTypes.mutations.SET_DEVICES_CUSTOMER_LIST]: (
    state,
    devices: responseCollection<DeviceCustomUserParams>
  ) => {
    state.devices.data = devices.data;
    state.devices.pagination = devices.meta.pagination;
  },
  [devicesTypes.mutations.SET_SESSIONS_BY_DEVICE]: (
    state,
    devices: responseCollection<DeviceSessionsParams>
  ) => {
    state.sessions_by_device.data = devices.data;
    state.sessions_by_device.pagination = devices.meta.pagination;
  },
};
const actions: ActionTree<DeviceCustomerUserState, any> = {
  [devicesTypes.actions.DEVICES_CUSTOMER_LIST]: ({ commit, }, { query, user_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/customers/${user_id}/devices?${query}`)
        .then((res: AxiosResponse) => {
          commit(devicesTypes.mutations.SET_DEVICES_CUSTOMER_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [devicesTypes.actions.SESSIONS_BY_DEVICE]: ({ commit, }, { query, user_id, device_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/customers/${user_id}/devices/${device_id}/sessions?${query}`)
        .then((res: AxiosResponse) => {
          commit(devicesTypes.mutations.SET_SESSIONS_BY_DEVICE, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
