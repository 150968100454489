/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { ifAuthenticated } from '../routeValidation';
const myProfile = () =>
  import(
    /* webpackChunkName: "my-profile" */
    '@/views/MyProfile/profile.vue'
  );
const myProfileConfig = () =>
  import(
    /* webpackChunkName: "my-profile" */
    '@/views/MyProfile/config.vue'
  );

const myProfileRoute = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/mi-perfil',
      component: myProfile,
      meta: {
        navbarType: 'light',
      },
      children: [
        {
          name: 'my-profile',
          path: '',
          component: require('@/components/Staff/profile.vue').default,
          meta: {
            navbarType: 'light',
          },
        }
      ],
    },
    {
      path: '/configuracion',
      name: 'my-profile-config',
      component: myProfileConfig,
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default myProfileRoute;
