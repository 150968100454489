import { responseCollection } from '@/store/interfaces/json-api/generics';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  CompanyCommerceState,
  CompanyCommerceParams
} from '@/store/interfaces/company_commerce';

// Establece los types de los modulos store
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import company_commerce from '../types/company_commerce';
import company from '../types/company';

const namespaced = true;

const initialState = (): CompanyCommerceState => {
  return {
    companyCommerceByCompany: { data: [], pagination: {}, },
    singleCompanyCommerce: {
      code: '',
      status: 'active',
      delivery_fee_cents: 0,
      delivery_fee_curency: 'USD',
      branches_count: 0,
      tip_percentage: 0,
      company_commerce_payment_gateway_param: {
        retailer_id: '',
        payment_gateway_id: '',
        terminal_id: '',
      },
      currencies: [],
      liquidation_kind_slug: 'agregador',
      naka_company_commerce_configuration: {
        id: '',
        tag_name: '',
      },
      commerce: {
        banner_url: null,
        branches_count: 0,
        commerce_kind: {
          id: '',
          name: '',
        },
        company_commerces_count: 0,
        created_at: '',
        description: '',
        id: '',
        is_event: false,
        is_franchise: false,
        logo_url: null,
        merchant_logo_url: null,
        name: '',
      },
    },
  };
};

const state: CompanyCommerceState = initialState();

const getters: GetterTree<CompanyCommerceState, any> = {
  [company_commerce.getters.GET_COMPANY_COMMERCE_BY_COMPANY]: state => {
    return state.companyCommerceByCompany;
  },
  [company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE]: state => {
    return state.singleCompanyCommerce;
  },
};
const mutations: MutationTree<CompanyCommerceState> = {
  [company_commerce.mutations.SET_COMPANY_COMMERCE_BY_COMPANY]: (
    state,
    commerces: responseCollection<CompanyCommerceParams>
  ) => {
    state.companyCommerceByCompany.data = commerces.data;
    state.companyCommerceByCompany.pagination = commerces.meta.pagination;
  },
  [company_commerce.mutations.SET_SINGLE_COMPANY_COMMERCE]: (
    state,
    company_commerce: CompanyCommerceParams
  ) => {
    state.singleCompanyCommerce = company_commerce;
  },
  [company_commerce.mutations.SET_CHANGE_COMPANY_COMMERCE_FROM_COMPANY]: (
    state,
    company_commerce_id: string
  ) => {
    state.companyCommerceByCompany.data = state.companyCommerceByCompany.data.filter(
      (company_commerce: CompanyCommerceParams) =>
        company_commerce.id !== company_commerce_id
    );
  },
  [company_commerce.mutations.SET_UPDATE_TABLE]: (
    state,
    company_commerce: CompanyCommerceParams
  ) => {
    const pos = state.companyCommerceByCompany.data
      .map(e => e.id)
      .indexOf(company_commerce.id);

    state.companyCommerceByCompany.data[pos].status = company_commerce.status;
    state.companyCommerceByCompany.data[pos].tip_percentage =
      company_commerce.tip_percentage;
  },
};

const actions: ActionTree<CompanyCommerceState, any> = {
  [company_commerce.actions.COMPANY_COMMERCE_BY_COMPANY]: (
    { commit, },
    { query, company_id, commerce_id, }
  ) => {
    commerce_id = commerce_id || '';
    return new Promise((resolve, reject) => {
      http
        .get(
          `backoffice/companies/${company_id}/company_commerces?commerce_id_eq=${commerce_id}${query}`
        )
        .then((res: AxiosResponse) => {
          commit(
            company_commerce.mutations.SET_COMPANY_COMMERCE_BY_COMPANY,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [company_commerce.actions.UPDATE_COMPANY_COMMERCE]: (
    { commit, dispatch, },
    { company_commerce_id, data, company_id_by_url, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/company_commerces/${company_commerce_id}`, {
          data,
        })
        .then((res: AxiosResponse) => {
          commit(company_commerce.mutations.SET_UPDATE_TABLE, res.data.data);
          if (company_id_by_url) {
            dispatch(
              `companyModule/${company.actions.SINGLE_COMPANY}`,
              company_id_by_url,
              { root: true, }
            );
          }
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
