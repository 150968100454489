export default {
  mutations: {
    SET_INTERMEDIARIES: 'SET_INTERMEDIARIES',
  },
  getters: {
    GET_INTERMEDIARIES: 'GET_INTERMEDIARIES',
  },
  actions: {
    INTERMEDIARIES: 'INTERMEDIARIES',
  },
};
