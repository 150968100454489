















































// vuex
import root from '@/store/types/root';
import { ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ActionConfirm',
  props: {
    title: {
      required: false,
      default: 'Restablecer contraseña',
      type: String,
    },
    viewModal: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    confirm: '',
    code: '',
  }),
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
  },
  created(): void {
    this.code = this.generateRandomString(10);
  },
  methods: {
    generateRandomString(length: number): string {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async contactAction(): Promise<void> {
      const confirm = this.confirm;
      const code = this.code;
      if (confirm === code) {
        this.$emit('confirm', true);
        this.confirm = '';
        (this.$refs.formResetConfirmation as InstanceType<typeof ValidationProvider>).reset();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any = [];
        errors.codigo = 'El código no coincide';
        (this.$refs.formResetConfirmation as InstanceType<typeof ValidationProvider>).setErrors(errors);
      }
    },
    cancel(): void {
      this.syncedViewModal = false;
      this.$emit('cancel', true);
    },
  },
});
