































import company_commerce from '@/store/types/company_commerce';
import {
  CompanyCommerceParams,
  companyCommercePaymentGatewayParam
} from '@/store/interfaces/company_commerce';
import actionConfirm from '@/components/modals/actionConfirm.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import modelConfigurationForm from '../forms/modelConfiguration.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'UpdateLiquidationModel',
  components: {
    actionConfirm,
    modelConfigurationForm,
  },
  data: () => ({
    confirmAction: false,
    cleanValidation: false,
    model: {
      payment_gateway_id: '',
      retailer_id: '',
      terminal_id: '',
    } as companyCommercePaymentGatewayParam,
  }),
  computed: {
    ...mapGetters({
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
    }),
  },
  methods: {
    ...mapActions({
      updateCompanyCommerce: `companyCommerceModule/${company_commerce.actions.UPDATE_COMPANY_COMMERCE}`,
    }),
    confirmChangeStatus(): void {
      this.confirmAction = true;
    },
    assignValueToModel(commerce: CompanyCommerceParams): void {
      if (commerce.company_commerce_payment_gateway_param) {
        this.model.retailer_id =
        commerce.company_commerce_payment_gateway_param.retailer_id;
        this.model.terminal_id =
        commerce.company_commerce_payment_gateway_param.terminal_id;
        this.model.payment_gateway_id =
        commerce.company_commerce_payment_gateway_param.payment_gateway_id;
      } else {
        this.model.payment_gateway_id = '';
        this.model.retailer_id = '';
        this.model.terminal_id = '';
      }
    },

    updateLiquidationModel(): void {
      this.$data.confirmAction = false;
      const data = {
        company_commerce_id: this.singleCompanyCommerce.id,
        data: {
          company_commerce_payment_gateway_param: { ...this.model, },
        },
      };
      this.updateCompanyCommerce(data)
        .then(() => {
          this.$emit('success', true);
          this.cleanValidation = true;
        })
        .catch(() => {
          const notification = new TeipNotifications();
          notification.warning('¡Error!', 'Algo ha salido mal :(');
        })
        .finally(() => {
          this.cleanValidation = false;
        });
    },
  },
});
