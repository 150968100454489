











































































import {
  allowAny,
  TeipTable,
  tPropsToSearch,
  tTableColumns
} from 'teip-components';
import pos from '@/store/types/pos';
import { posParams, tNewPosUser, tUpdatePosUser } from '@/store/interfaces/pos';
import posUserModal from './modal/posUser.vue';
import showPassword from './modal/showPassword.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosError } from 'axios';
import ActionConfirm from '../../modals/actionConfirm.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';

export default Vue.extend({
  name: 'BranchPos',
  components: { posUserModal, showPassword, ActionConfirm, },
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 250,
        formatter(params: posParams): string {
          return `${params.profile.first_name} ${params.profile.last_name}`;
        },
      },
      {
        prop: 'email',
        label: 'Email',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'hardware_configuration.identifier',
        label: 'Numero de serie',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'hardware_configuration.terminal_id',
        label: 'Terminal ID',
        minWidth: 250,
        sortable: true,
      }
    ] as tTableColumns,
    showNewUserModal: false,
    isUserSaved: false,
    alreadyToShowPassword: false,
    isUpdate: false,
    confirmIfDeleteUser: false,
    pos: {
      branch_id: '',
      branch: {
        id: '',
        name: '',
        status: 'inactive',
      },
      email: '',
      hardware_configuration: {
        id: '',
        identifier: '',
        terminal_id: '',
      },
      password: '',
      profile: {
        id: '',
        first_name: '',
        last_name: '',
      },
      role: '',
    } as posParams,
    errors: {},
  }),
  computed: {
    ...mapGetters({
      getPosList: `posModule/${pos.getters.GET_POS_LIST}`,
    }),
  },
  methods: {
    ...mapActions({
      deletePosUser: `posModule/${pos.actions.REMOVE_POS_USER}`,
      posList: `posModule/${pos.actions.POS_LIST}`,
      savePosUser: `posModule/${pos.actions.SAVE_POS_USER}`,
      updatePosUser: `posModule/${pos.actions.UPDATE_POS_USER}`,
    }),

    rowSelected(row: posParams): void {
      this.pos = row;
    },

    showModalUserForm(isUpdate = false): void {
      this.isUpdate = isUpdate;
      this.showNewUserModal = true;
    },

    async saveOrUpdate(user: tNewPosUser): Promise<void> {
      if (this.isUpdate) {
        await this.updateUser(user);
      } else {
        await this.saveUser(user);
      }
    },

    async saveUser(user: tNewPosUser): Promise<void> {
      try {
        const { data, }: { data: { data: posParams } } = await this.savePosUser({
          ...user,
          branch_id: this.$route.params.sucursal_id,
        });
        (this.$refs.posUserTable as InstanceType<typeof TeipTable>).fetchData();
        this.isUserSaved = true;
        this.alreadyToShowPassword = true;
        this.pos.password = data.data.password;
        this.pos.profile.first_name = data.data.profile.first_name;
        this.pos.profile.last_name = data.data.profile.last_name;
        this.pos.email = data.data.email;
      } catch (error) {
        const err = error as AxiosError<{
          code: null;
          errors: Record<string, string[]>;
          status: number;
        }>;

        this.showingServerErrors(err);

        this.throwNotifications('error', 'Algo ha salido mal :(');
      }
    },

    async updateUser(user: tNewPosUser): Promise<void> {
      const updateUserInfo: tUpdatePosUser = {
        branch_id: this.$route.params.sucursal_id,
        profile: { ...user.profile, },
        hardware_configuration: { ...user.hardware_configuration, },
      };

      try {
        await this.updatePosUser({ id: this.pos.id, user: updateUserInfo, });
        (this.$refs.posUserTable as InstanceType<typeof TeipTable>).fetchData();
        this.throwNotifications('success', 'Actualizado correctamente :)');
        this.isUserSaved = true;
      } catch (error) {
        this.showingServerErrors(error as AxiosError);
        this.throwNotifications('error', 'Algo ha salido mal :(');
      }
    },

    showingServerErrors(errors: AxiosError): void {
      const setErrors: Record<string, allowAny> = [];
      Object.entries(errors?.response?.data.error).forEach(([key, value]) => {
        if (key.includes('terminal_id')) {
          setErrors.terminal = value;
        } else if (key.includes('identifier')) {
          setErrors.identificador = value;
        } else {
          setErrors[key] = value;
        }
      });
      this.errors = setErrors;
    },

    async throwNotifications(type: string, message: string): Promise<void> {
      const notification = new TeipNotifications();
      if (type === 'success') {
        notification.success(message);
      } else {
        notification.warning(message);
      }
    },

    async removePosUser(): Promise<void> {
      try {
        await this.deletePosUser(this.pos.id);
        this.throwNotifications(
          'success',
          'Usuario eliminado satisfactoriamente'
        );
        (this.$refs.posUserTable as InstanceType<typeof TeipTable>).fetchData();
        this.confirmIfDeleteUser = false;
      } catch (error) {
        this.throwNotifications('error', 'Algo ha salido mal :(');
      }
    },
  },
});
