









import { status } from '@/util/transformString/translationsDecorator';
import { statusColor } from '@/util/badgesColor';
import Vue from 'vue';

export default Vue.extend({
  name: 'StatusBadges',
  props: {
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    statusTranslate(): string {
      return status(this.status);
    },
    badgesColor(): string {
      return statusColor(this.status);
    },
  },
});
