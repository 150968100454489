























































import uploadImagesMixin from '@/mixins/uploadImagesMixin';
import Vue from 'vue';

export default Vue.extend({
  name: 'UploadImages',
  mixins: [uploadImagesMixin],
});
