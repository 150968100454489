
















































import company_commerce from '@/store/types/company_commerce';
import configurations from '@/store/types/configurations';
import { TeipNotifications } from '@/util/teipNotifications';
import { ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'CodeAndStatus',
  data() {
    return {
      status: false,
      code: '',
      updating: false,

      message: `Ten en cuenta que si desactivas este ${this.$t(
        'menu.commerce.singular.informal'
      )}, no podrá realizar transacciones.`,
    };
  },
  computed: {
    ...mapGetters({
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  watch: {
    singleCompanyCommerce: {
      handler(): void {
        this.code = this.singleCompanyCommerce.code || '';
        this.status = this.singleCompanyCommerce.status === 'active';

        if (!this.status) {
          this.message = `Activa el ${this.$t(
            'menu.commerce.singular.informal'
          )} para que empiece a realizar transacciones.`;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      updateCompanyCommerce: `companyCommerceModule/${company_commerce.actions.UPDATE_COMPANY_COMMERCE}`,
    }),
    updateCodeAndStatus(): void {
      this.updating = true;
      const status = this.status ? 'active' : 'inactive';

      const data = {
        company_commerce_id: this.singleCompanyCommerce.id,
        data: {
          code: this.code,
          status,
        },
      };

      this.updateCompanyCommerce(data)
        .then(() => {
          this.$emit('success', true);
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            (
              this.$refs.formUpdateCode as InstanceType<
                typeof ValidationProvider
              >
            ).setErrors(err.response?.data.error);
            return;
          }
          const notification = new TeipNotifications();
          notification.warning('¡Error!', 'Algo ha salido mal :(');
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
});
