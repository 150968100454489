export default {
  mutations: {
    SET_COMPANY_COMMERCE_BY_COMPANY: 'SET_COMPANY_COMMERCE_BY_COMPANY',
    SET_UPDATE_COMPANY_COMMERCE_FROM_COMPANY:
      'SET_UPDATE_COMPANY_COMMERCE_FROM_COMPANY',
    SET_SINGLE_COMPANY_COMMERCE: 'SET_SINGLE_COMPANY_COMMERCE',
    SET_CHANGE_COMPANY_COMMERCE_FROM_COMPANY:
      'SET_CHANGE_COMPANY_COMMERCE_FROM_COMPANY',

    SET_UPDATE_TABLE: 'SET_UPDATE_TABLE',
  },
  getters: {
    GET_COMPANY_COMMERCE_BY_COMPANY: 'GET_COMPANY_COMMERCE_BY_COMPANY',
    GET_SINGLE_COMPANY_COMMERCE: 'GET_SINGLE_COMPANY_COMMERCE',
  },
  actions: {
    COMPANY_COMMERCE_BY_COMPANY: 'COMPANY_COMMERCE_BY_COMPANY',
    UPDATE_COMPANY_COMMERCE: 'UPDATE_COMPANY_COMMERCE',
  },
};
