






























































import { AxiosResponse } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import root from '@/store/types/root';
import company from '@/store/types/company';
import { CompanyParams } from '@/store/interfaces/company';
import actionConfirm from '@/components/modals/actionConfirm.vue';
import company_commerce from '@/store/types/company_commerce';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'ChangeToCompany',
  components: {
    actionConfirm,
  },
  data: () => ({
    confirmAction: false,
    isLoading: true,
    company_id: '',
    company_commerce_id: '',
    companies: [] as CompanyParams[],
  }),
  async mounted(): Promise<void> {
    const company_id = this.$route.params.company_id;
    await this.companiesList().then((result: AxiosResponse) => {
      this.companies = result.data.data.filter(
        (filter_by: CompanyParams) => filter_by.id !== company_id
      );
    });

    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
    }),
  },
  methods: {
    ...mapActions({
      companiesList: `companyModule/${company.actions.COMPANY_LIST}`,
      updateCompanyCommerce: `companyCommerceModule/${company_commerce.actions.UPDATE_COMPANY_COMMERCE}`,
    }),
    changeCommerceToCompany(): void {
      this.$data.confirmAction = true;
    },
    confirmMoveCommerce(): void {
      const company_commerce_id = this.singleCompanyCommerce.id;
      const data = {
        company_commerce_id,
        data: {
          company_id: this.$data.company_id,
        },
        company_id_by_url: this.$route.params.company_id,
      };

      this.updateCompanyCommerce(data)
        .then(() => {
          this.$emit('success', true);
          this.$store.commit(
            `companyCommerceModule/${company_commerce.mutations.SET_CHANGE_COMPANY_COMMERCE_FROM_COMPANY}`,
            company_commerce_id
          );
        })
        .catch(() => {
          const notification = new TeipNotifications();
          notification.warning(
            '¡Error!',
            `Algo ha salido mal al tratar de mover el ${this.$t(
              'menu.commerce.singular.informal'
            )} de la ${this.$t('menu.company.singular.informal')}. :(`
          );
        })
        .finally(() => {
          this.$data.confirmAction = false;
        });
    },
  },
});
