var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('b-row',{staticClass:"d-flex flex-col"},[_c('b-col',{attrs:{"md":"auto"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Sesiones para:")])]),(Object.keys(_vm.getUserDevices.data).length > 0)?_c('b-col',[_c('div',[_c('strong',[_vm._v("Dispositivo")]),_vm._v(":\n              "+_vm._s(_vm.getUserDevices.data[0].vendor)+"\n              "+_vm._s(_vm.getUserDevices.data[0].device_name)+"\n              "),_c('strong',[_vm._v("Sistema")]),_vm._v(":\n              "+_vm._s(_vm.getUserDevices.data[0].system_name)+"\n              "+_vm._s(_vm.getUserDevices.data[0].system_version)+"\n            ")])]):_vm._e()],1)],1),_c('teip-table',{attrs:{"props-to-search":_vm.propsToSearch,"table-columns":_vm.tableColumns,"table-data":_vm.getSessions.data,"fetch-callback":_vm.sessionDevice,"host":_vm.companyHost,"ids":{
          user_id: _vm.$route.params.user_id,
          device_id: _vm.$route.params.device_id,
        },"table-pagination":_vm.getSessions.pagination,"can-search":false,"table":"sesiones","trigger-drop-down":"hover","dropdown":false},scopedSlots:_vm._u([{key:"status",fn:function(ref){
        var row = ref.row;
return [_c('session-active',{attrs:{"label":_vm.sessionEvaluation(row) ? 'Activo' : 'Inactivo',"validated":_vm.sessionEvaluation(row),"validation":{
              valid: 'Activo',
              invalid: 'Inactivo',
            }}})]}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }