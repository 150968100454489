export default {
  mutations: {
    SET_PAYMENTS_LINK: 'SET_PAYMENTS_LINK',
  },
  getters: {
    GET_PAYMENTS_LINK: 'GET_PAYMENTS_LINK',
  },
  actions: {
    PAYMENTS_LINK: 'PAYMENTS_LINK',
  },
};
