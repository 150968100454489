



















































































import staffPermissionType from '@/store/types/staffPermissions';
import userStaffType from '@/store/types/userStaff';
import { TeipNotifications } from '@/util/teipNotifications';
import root from '@/store/types/root';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import IntermediarySelect from '@/components/Reusable/IntermediarySelect.vue';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'NewStaffUser',
  components: {
    IntermediarySelect,
  },
  data() {
    return {
      user: {
        email: '',
        permission_group_ids: '',
        intermediary_id: '',
        profile: {
          first_name: '',
          last_name: '',
        },
      },
      loading: true,
      clean: false,
    };
  },
  methods: {
    ...mapActions({
      PermissionsList: `staffPermissions/${staffPermissionType.actions.PERMISSIONS_LIST}`,
      saveUserStaff: `userStaff/${userStaffType.actions.SAVE_USER_STAFF}`,
    }),
    firstFormSubmit(): void {
      const teipNotification = new TeipNotifications();

      this.saveUserStaff(this.user)
        .then(() => {
          teipNotification.success('Usuario staff guardado');
          this.clean = true;
          this.user = this.initData().user;
          (this.$refs.formNewUserStaff as InstanceType<typeof ValidationProvider>).reset();
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          if (err.response.status === 422) {
            const errors = err.response.data.error;
            (this.$refs.formNewUserStaff as InstanceType<typeof ValidationProvider>).setErrors(errors);
          }
        })
        .finally(() => {
          this.clean = false;
        });
    },
    returnList(): void {
      this.$router.push({
        name: 'staff-home',
      });
    },
    initData() {
      return {
        user: {
          email: '',
          permission_group_ids: '',
          intermediary_id: '',
          profile: {
            first_name: '',
            last_name: '',
          },
        },
      };
    },
  },
  async mounted(): Promise<void> {
    await this.PermissionsList('staff');
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getProccess: root.getters.GET_START_PROCESS,
      getPermissionsList: `staffPermissions/${staffPermissionType.getters.GET_PERMISSIONS_LIST}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
});
