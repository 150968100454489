/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  DraftsState,
  DraftCompanyParams
} from '@/store/interfaces/drafts';

// Establece los types de los modulos store
import DraftsType from '@/store/types/drafts';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { responseCollection, responseSingle } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): DraftsState => {
  return {
    draftCompanies: {
      data: [],
      pagination: {},
    },
    singleDraftCompany: {
      accountant_contact: false,
      accountant_contact_email: '',
      accountant_contact_name: '',
      accountant_contact_phone: '',
      address_municipality: '',
      address_state_code: '',
      address_summary: '',
      bank_account: {
        account_holder: '',
        account_number: '',
        account_type: '',
        bank: {
          code: '',
          country_code: '',
          created_at: '',
          discarded_at: null,
          id: '',
          name: '',
          updated_at: '',
        },
        billing_address: null,
        billing_type: '',
        id: '',
        receipt_url: null,
      },
      id: '',
      banner_url: null,
      branches: [
        {
          address: '',
          contact_name: '',
          contact_phone: '',
          id: '',
          name: '',
          phone: '',
        }
      ],
      business_company_documents: [],
      business_name: '',
      business_nit: '',
      business_nrc: '',
      commerce_name: '',
      commission: null,
      company_kind: 'natural',
      dui: '',
      email: '',
      line: '',
      logo_url: null,
      name: '',
      nit: '',
      nrc: '',
      phone: '',
      register_contact: false,
      register_contact_email: null,
      register_contact_name: null,
      register_contact_phone: null,
      representative_company_documents: [],
      status: 'created',
    },
  };
};

const state: DraftsState = initialState();

const getters: GetterTree<DraftsState, any> = {
  [DraftsType.getters.GET_DRAFTS_COMPANIES]: state => {
    return state.draftCompanies;
  },
  [DraftsType.getters.GET_DRAFT_COMPANY]: state => {
    return state.singleDraftCompany;
  },
};
const mutations: MutationTree<DraftsState> = {
  [DraftsType.mutations.SET_DRAFTS_COMPANY]: (
    state,
    memberships: responseCollection<DraftCompanyParams>
  ) => {
    state.draftCompanies.data = memberships.data;
    state.draftCompanies.pagination = memberships.meta.pagination;
  },
  [DraftsType.mutations.SET_SINGLE_DRAFT_COMPANY]: (
    state,
    membership: responseSingle<DraftCompanyParams>
  ) => {
    state.singleDraftCompany = membership.data;
    state.singleDraftCompany.company_kind = membership.data.company_kind;
  },
};
const actions: ActionTree<DraftsState, any> = {
  [DraftsType.actions.DRAFTS_COMPANIES_LIST]: (
    { commit, },
    queryParams = ''
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          `backoffice/drafts/companies?${queryParams}&sort=created_at&status_eq=created`
        )
        .then((res: AxiosResponse) => {
          commit(DraftsType.mutations.SET_DRAFTS_COMPANY, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [DraftsType.actions.SINGLE_DRAFT_COMPANY]: (
    { commit, },
    draftCompanyId: string
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/drafts/companies/${draftCompanyId}`)
        .then((res: AxiosResponse) => {
          commit(DraftsType.mutations.SET_SINGLE_DRAFT_COMPANY, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
