export default {
  mutations: {
    SET_TRANSFERS: 'SET_TRANSFERS',
    SET_TRANSFERS_BY_CUSTOMER_USER: 'SET_TRANSFERS_BY_CUSTOMER_USER',
  },
  getters: {
    GET_TRANSFER: 'GET_TRANSFER',
    GET_TRANSFER_BY_CUSTOMER_USER: 'GET_TRANSFER_BY_CUSTOMER_USER',
  },
  actions: {
    TRANSFER_LIST: 'TRANSFER_LIST',
    TRANSFER_VOUCHER_DOWNLOAD: 'TRANSFER_VOUCHER_DOWNLOAD',
    TRANSFER_LIST_BY_CUSTOMER_USER: 'TRANSFER_LIST_BY_CUSTOMER_USER',
  },
};
