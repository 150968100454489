































































































import contactTypes from '@/store/types/contact';
import { ContactKindParams, ContactParams } from '@/store/interfaces/contact';
import root from '@/store/types/root';
import copyObj from '@/util/copyObj';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';
import { phoneMask } from '@/const/phoneNumber';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'ContactModal',
  props: {
    userUpdate: {
      type: Object as PropType<ContactParams>,
      required: true,
    },
    errors: {
      type: Object as PropType<Record<string, string | number>>,
      required: true,
    },
    viewModal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      phoneMask,
      messageButton: 'Guardar contacto',
      update: false,
      model: {
        name: '',
        email: '',
        phone: '',
        phone2: '',
        contact_kind_id: '',
      } as ContactParams,
      positionContactKind: 1,
      loading: true,
      contactKind: [],
    };
  },
  methods: {
    ...mapActions({
      listContactKind: `contactModule/${contactTypes.actions.LIST_CONTACT_KIND}`,
    }),
    cleanModel(): void {
      const values = {
        name: '',
        email: '',
        phone: '',
        phone2: '',
        contact_kind_id: '',
      };
      if (this.getContactKind.data.length > 0) {
        values.contact_kind_id = this.getContactKind.data[0].id;
      }
      this.model = values;
    },

    contactAction(): void {
      const modelFormat: ContactParams = {
        ...this.model,
        phone: this.model.phone.replace(/ /g, ''),
        phone2: this.model.phone2?.replace(/ /g, ''),
      };

      if (modelFormat.phone2 === '') {
        delete modelFormat.phone2;
      }

      let contactInfo = {
        update: false,
        model: modelFormat,
      };
      if (this.update) {
        contactInfo = {
          update: true,
          model: modelFormat,
        };
      }
      this.$emit('contactInfo', contactInfo);
    },
    cancel(): void {
      this.syncedViewModal = false;
      if (this.update) this.model = this.$props.userUpdate;
    },
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      getContactKind: `contactModule/${contactTypes.getters.GET_CONTACT_KIND}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
  },
  watch: {
    errors: {
      immediate: true,
      deep: true,
      handler(errors: string[]): void {
        if (this.$refs.formValidatorContactModal) {
          (this.$refs.formValidatorContactModal as InstanceType<typeof ValidationProvider>).setErrors(errors);
        }
      },
    },
    userUpdate: {
      immediate: true,
      deep: true,
      handler(val: ContactParams): void {
        if (Object.keys(val).length > 0) {
          this.update = true;
          this.messageButton = 'Actualizar';
          const contactkind: undefined | string = val.contact_kind?.id;
          const pos = this.getContactKind.data.findIndex(
            (e: ContactKindParams) => e.id === contactkind
          );
          this.positionContactKind = pos + 1;
          this.model = copyObj(val);
        } else {
          this.update = false;
          this.messageButton = 'Guardar contacto';
          this.positionContactKind = 1;
          this.cleanModel();
        }
      },
    },
  },
  async created(): Promise<void> {
    if (this.getContactKind.data.length === 0) {
      await this.listContactKind();
    }

    this.contactKind = copyObj(this.getContactKind.data);
    this.loading = false;
  },
});
