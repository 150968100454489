export default {
  mutations: {
    SET_STATS: 'SET_STATS',
  },
  getters: {
    STATS: 'STATS',
  },
  actions: {
    GET_STATS: 'GET_STATS',
  },
};
