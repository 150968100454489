export default {
  mutations: {
    SET_FLAGGED_TRANSACTIONS: '  SET_FLAGGED_TRANSACTIONS',
    SET_FLAGGED_TRANSACTION: '  SET_FLAGGED_TRANSACTION',
    SET_SINGLE_COMMERCE: 'SET_SINGLE_COMMERCE',
    SET_POSTMORTEM_TRANSACTIONS: '  SET_POSTMORTEM_TRANSACTIONS',
    SET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED: 'SET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED',
  },
  getters: {
    GET_FLAGGED_TRANSACTIONS: 'GET_FLAGGED_TRANSACTIONS',
    GET_FLAGGED_TRANSACTION: 'GET_FLAGGED_TRANSACTION',
    GET_SINGLE_FLAGGED_TRANSACTION: 'GET_SINGLE_FLAGGED_TRANSACTION',
    GET_POSTMORTEM_TRANSACTIONS: 'GET_POSTMORTEM_TRANSACTIONS',
    GET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED: 'GET_TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED',
    GET_SINGLE_POSTMORTEM_TRANSACTION: 'GET_SINGLE_POSTMORTEM_TRANSACTION',
  },
  actions: {
    FLAGGED_TRANSACTION_LIST: 'FLAGGED_TRANSACTION_LIST',
    FLAGGED_TRANSACTION: 'FLAGGED_TRANSACTION',
    POSTMORTEM_TRANSACTION_LIST: 'POSTMORTEM_TRANSACTION_LIST',
    TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED: 'TRANSACTIONS_LIST_FOR_COMMERCE_NOT_AFFILIATED',
    MONITOR_TRANSACTION_LIST: 'MONITOR_TRANSACTION_LIST',
    POSTMORTEM_TRANSACTION: 'POSTMORTEM_TRANSACTION',
    PAYMENT_REFUND: 'PAYMENT_REFUND',
  },
};
