/*!
teip
*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store';
import auth from './store/types/auth';

// import filters
import './util/filters';
// router setup
import router from './routes/router';

// i18n setup
import i18n from './i18n';

import NProgress from 'nprogress';

// Directive for v-mask
import { VueMaskDirective } from 'v-mask';

// Sentry config
import './config/sentry';
// plugin setup
Vue.use(DashboardPlugin);

NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});
Vue.directive('mask', VueMaskDirective);

store.watch(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: any) => state.startprocess,
  (newVal: number, oldVal: number) => {
    if (newVal === 0) return NProgress.done();
    if (oldVal === 0) return NProgress.start();
    NProgress.set(1.8 / Math.max(oldVal, newVal));
  }
);

if (store.getters[`authModule/${auth.getters.IS_AUTHENTICATED}`]) {
  store.dispatch(`authModule/${auth.actions.EXPIRES_IN}`);
}

/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  render: h => h(App),
  router,
  store,
});
