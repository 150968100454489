/* eslint-disable @typescript-eslint/no-explicit-any */

import { RootState } from '@/store/interfaces/rootState';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

// Establece los types de los modulos store
import { companyHost } from '@/const/companyHost';
import i18n from '@/i18n';
import { http } from '@/util/HTTP';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { allowAny } from 'teip-components';
import { ConfigurationState, iCompanyConfiguration, ihostConfig } from '../interfaces/configurations';
import { responseSingle } from '../interfaces/json-api/generics';
import auth from '../types/auth';
import configurations from '../types/configurations';
import hostConfig from '@/config/index.json';

const namespaced = true;

const state: ConfigurationState = {
  configuration: {
    contact_phone: null,
    features: {
      'transactions.p2p_transactions': false,
      commerces: false,
      customers: false,
      staff: false,
      'staff.users': false,
      'commerces.companies': false,
      transactions: false,
      'transactions.summary': false,
      'transactions.p2b_transactions': false,
      'transactions.quickpay': false,
      support: false,
      buyers: false,
      settlements: false,
      'settlements.liquidations': false,
      'settlements.settlements': false,
      'commerces.commerces': false,
      'commerces.inbound': false,
      'commerces.categories': false,
      marketing: false,
      'marketing.discounts': false,
      'marketing.cashbacks': false,
      withdrawals: false,
      'staff.permissions': false,
    },
    translations: {
      commerce: {
        plural: '',
        singular: '',
      },
      company: {
        plural: '',
        singular: '',
      },
      transactions: {
        plural: '',
        singular: '',
      },
    },

  },
  hostConfig,
};

const getters: GetterTree<ConfigurationState, RootState> = {
  [configurations.getters.GET_CONFIGURATION]: state => {
    return state.configuration;
  },
  [configurations.getters.GET_HOST_CONFIGURATION]: state => {
    return state.hostConfig;
  },
};
const mutations: MutationTree<ConfigurationState> = {
  [configurations.mutations.SET_CONFIGURATION]: (state, response: responseSingle<iCompanyConfiguration>) => {
    state.configuration = response.data;
  },
  [configurations.mutations.SET_I18N_LOCAL_MESSAGE]: (_state, response: { data: iCompanyConfiguration }) => {
    const commerceTranslation = response.data.translations.commerce;
    const companyTranslation = response.data.translations.company;
    const transactionTranslation = response.data.translations.transactions;
    const teipCompany = i18n.messages.teip as allowAny;

    const translations = {
      commerce: {
        plural: {
          formal: commerceTranslation.plural,
          informal: commerceTranslation.plural.toLowerCase(),
        },
        singular: {
          formal: commerceTranslation.singular,
          informal: commerceTranslation.singular.toLowerCase(),
        },
      },
      company: {
        plural: {
          formal: companyTranslation.plural,
          informal: companyTranslation.plural.toLowerCase(),
        },
        singular: {
          formal: companyTranslation.singular,
          informal: companyTranslation.singular.toLowerCase(),
        },
      },
      transactions: {
        plural: {
          formal: transactionTranslation.plural,
          informal: transactionTranslation.plural.toLowerCase(),
        },
        singular: {
          formal: transactionTranslation.singular,
          informal: transactionTranslation.singular.toLowerCase(),
        },
      },
    };

    const newMessages = {
      teip: {
        menu: { ...teipCompany.menu, },
        commerce: { ...teipCompany.commerce, },
        company: { ...teipCompany.company, },
      },
      [i18n.locale]: {
        menu: translations,
        commerce: { ...teipCompany.commerce, },
        company: { ...teipCompany.company, },
      },
    };

    i18n.setLocaleMessage(i18n.locale, { ...newMessages[i18n.locale], });

    window.localStorage.setItem('translations', JSON.stringify(i18n.messages));
  },
  [configurations.mutations.SET_HOST_CONFIGURATION]: (state, config: ihostConfig) => {
    state.hostConfig = {
      ...hostConfig,
      globals: {
        ...hostConfig.globals,
        ...config.globals,
      },
      commerce: {
        ...hostConfig.commerce,
        ...config.commerce,
      },
      companies: {
        ...hostConfig.companies,
        ...config.companies,
      },
      commissions: {
        ...hostConfig.commissions,
        ...config.commissions,
      },
      company_users: {
        ...hostConfig.company_users,
        ...config.company_users,
      },
    };
  },
};
const actions: ActionTree<ConfigurationState, RootState> = {
  [configurations.actions.CONFIGURATION]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/configurations')
        .then((res: AxiosResponse) => {
          if (Object.keys(res.data).length > 0) {
            commit(configurations.mutations.SET_CONFIGURATION, res.data);
            commit(
              `authModule/${auth.mutations.SET_CONCAT_FEATURES_FLAG_TO_THE_PERMISSIONS}`,
              res.data,
              { root: true, }
            );
            commit(configurations.mutations.SET_I18N_LOCAL_MESSAGE, res.data);
          }
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [configurations.actions.HOST_CONFIGURATION]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      const url = `https://assets.teip.io/hosts/${companyHost}/config.json`;
      axios
        .get(url)
        .then((res: AxiosResponse<ihostConfig>) => {
          commit(configurations.mutations.SET_HOST_CONFIGURATION, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
