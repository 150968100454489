














import { BranchParams } from '@/store/interfaces/branchs';
import Vue from 'vue';
import newBranch from './../newBranch.vue';

export default Vue.extend({
  name: 'NewBranchModal',
  components: {
    newBranch,
  },
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
  },
  methods: {
    createdBranch(branch: BranchParams): void {
      this.$emit('branch', branch);
      this.syncedViewModal = false;
    },
    cancelAndClose(): void {
      this.syncedViewModal = false;
    },
  },
});
