/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { CountryState, CountryParams } from '@/store/interfaces/country';
import { RootState } from '@/store/interfaces/rootState';

// Establece los types de los modulos store
import locationType from '@/store/types/country';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { StateParams } from '../interfaces/state';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): CountryState => {
  return {
    countries: [],
    states: [],
  };
};

const state: CountryState = initialState();

const getters: GetterTree<CountryState, RootState> = {
  [locationType.getters.GET_COUNTRY]: state => {
    return state.countries;
  },
  [locationType.getters.GET_STATE]: state => {
    return state.states;
  },
};
const mutations: MutationTree<CountryState> = {
  [locationType.mutations.SET_STATE]: (state, states: responseCollection<StateParams>) => {
    state.states = states.data;
  },
  [locationType.mutations.SET_COUNTRY]: (state, countries: responseCollection<CountryParams>) => {
    state.countries = countries.data;
  },
};
const actions: ActionTree<CountryState, RootState> = {
  [locationType.actions.COUNTRY_LIST]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/countries')
        .then((res: AxiosResponse) => {
          commit(locationType.mutations.SET_COUNTRY, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [locationType.actions.STATE_LIST]: ({ commit, }) => {
    const countryCode = process.env.VUE_APP_COUNTRY_CODE || 'SV';

    return new Promise((resolve, reject) => {
      http
        .get(`resources/countries/${countryCode}/states`)
        .then((res: AxiosResponse) => {
          commit(locationType.mutations.SET_STATE, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
