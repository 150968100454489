import BaseInput from '@/components/Argon/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/Argon/BaseDropdown.vue';
import Card from '@/components/Argon/Cards/Card.vue';
import StatsCard from '@/components/Argon/Cards/StatsCard.vue';
import BaseButton from '@/components/Argon/BaseButton.vue';
import BaseCheckbox from '@/components/Argon/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/Argon/BaseSwitch.vue';
import BaseRadio from '@/components/Argon/Inputs/BaseRadio.vue';
import BaseProgress from '@/components/Argon/BaseProgress.vue';
import BasePagination from '@/components/Argon/BasePagination.vue';
import BaseAlert from '@/components/Argon/BaseAlert.vue';
import BaseNav from '@/components/Argon/Navbar/BaseNav.vue';
import BaseHeader from '@/components/Argon/BaseHeader.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover } from 'element-ui';
import LoadingPanel from '@/components/Argon/LoadingPanel.vue';
import Badge from '@/components/Argon/Badge.vue';

/**
 * teip globals components
 */
import HeaderBase from '@/components/teip-globals/HeaderBase.vue';
import HeaderBaseLink from '@/components/teip-globals/HeaderBaseLink.vue';
import { VueConstructor } from 'vue';

const GlobalComponents = {
  install(Vue: VueConstructor): void {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component('el-input', Input);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.component('loading-panel', LoadingPanel);
    Vue.use(Tooltip);
    Vue.use(Popover);

    // Components globals for teip
    Vue.component('header-base', HeaderBase);
    Vue.component('header-base-link', HeaderBaseLink);
  },
};

export default GlobalComponents;
