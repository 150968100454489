export default {
  mutations: {
    SET_PERMISSIONS_GROUP_LIST: 'SET_PERMISSIONS_GROUP_LIST',
    SET_PERMISSION_SECTION_LIST: 'SET_PERMISSION_SECTION_LIST',
    SET_SINGLE_PERMISSION_GROUP: 'SET_SINGLE_PERMISSION_GROUP',
  },
  getters: {
    GET_PERMISSIONS_GROUP_LIST: 'GET_PERMISSIONS_GROUP_LIST',
    GET_PERMISSION_SECTION_LIST: 'GET_PERMISSION_SECTION_LIST',
    GET_SINGLE_PERMISSION_GROUP: 'GET_SINGLE_PERMISSION_GROUP',
  },
  actions: {
    PERMISSION_GROUP_LIST: 'PERMISSION_GROUP_LIST',
    PERMISSION_SECTION_LIST: 'PERMISSION_SECTION_LIST',
    SAVE_PERMISSION_GROUP: 'SAVE_PERMISSION_GROUP',
    UPDATE_PERMISSION_GROUP: 'UPDATE_PERMISSION_GROUP',
    SINGLE_PERMISSION_GROUP: 'SINGLE_PERMISSION_GROUP',
  },
};
