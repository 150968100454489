






























































import branchs from '@/store/types/branchs';
import commerce from '@/store/types/commerce';
import user from '@/store/types/branchsUser';
import SweetAlert from '@/util/classes/SweetAlert';
import { TeipNotifications } from '@/util/teipNotifications';
import resetPasswordConfirmation from '@/components/modals/actionConfirm.vue';
import passwordType from '@/store/types/password';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { BranchUserParams } from '@/store/interfaces/branchsUser';
import { companyHost } from '@/const/companyHost';

export default Vue.extend({
  name: 'BranchUsers',
  components: {
    resetPasswordConfirmation,
  },
  data: () => ({
    companyHost,
    showModal: false,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 250,
        sortable: true,
        formatter: (row: BranchUserParams) =>
          `${row.profile.first_name} ${row.profile.last_name}`,
      },
      {
        prop: 'email',
        label: 'Email',
        minWidth: 250,
        sortable: true,
      }
    ] as tTableColumns,
    user: {},
  }),

  computed: {
    ...mapGetters({
      getUserBranch: `commerceModule/branches/users/${user.getters.GET_USER_BRANCHES}`,
      getSingleBranch: `commerceModule/branches/${branchs.getters.GET_SINGLE_BRANCH}`,
      getSingleCommerce: `commerceModule/${commerce.getters.GET_SINGLE_COMMERCE}`,
    }),
  },
  created(): void {
    this.cleanSingleBranchUser();
  },
  methods: {
    ...mapActions({
      userBranchList: `commerceModule/branches/users/${user.actions.USER_BRANCH_LIST}`,
      deleteBranchUser: `commerceModule/branches/users/${user.actions.DELETE_DRAFT_BRANCH_USER}`,
      resetPass: `passwordModule/${passwordType.actions.RESET_PASSWORD}`,
    }),

    ...mapMutations({
      setSingleBranchUser: `commerceModule/branches/users/${user.mutations.SET_SINGLE_USER_BRANCH}`,
      cleanSingleBranchUser: `commerceModule/branches/users/${user.mutations.SET_CLEAN_SINGLE_USER}`,
    }),

    rowselect(row: unknown): void {
      this.$data.user = row;
    },

    newUser(): void {
      this.$router.push({
        name: 'new-user-brach',
        hash: '#nuevo-usuario',
      });
    },

    deleteUser(): void {
      const swal = new SweetAlert();
      swal
        .askingSweetAlert({
          title: '¿Eliminar usuario de sucursal?',
          text: 'Esta acción no podrá ser revertida.',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sí, eliminar',
          alertType: 'warning',
        })
        .then((res: boolean) => {
          if (res) {
            this.deleteBranchUser(this.$data.user.id)
              .then(() => {
                swal.simpleSweetAlert({
                  title: 'Eliminado',
                  message: 'Usuario de sucursal eliminado satisfactoriamente',
                  alertType: 'success',
                });
              })
              .catch(() => {
                const notification = new TeipNotifications();
                notification.warning(
                  '¡Error!',
                  'Algo ha salido mal al eliminar :('
                );
              });
          }
        });
    },

    confirmResetPassword(): void {
      const notification = new TeipNotifications();
      this.resetPass({
        model: 'users',
        user_id: this.$data.user.id,
      })
        .then(() => {
          this.$data.showModal = false;
          notification.success(
            'Contraseña restablecida',
            'Se ha enviado un email de restablecimiento'
          );
        })
        .catch(() => {
          notification.warning(
            '¡Error!',
            'No hemos podido restablecer contraseña :('
          );
        });
    },

    updateUser(): void {
      this.setSingleBranchUser(this.user);
      this.$router.push({
        name: 'update-user-brach',
        hash: '#actualizar-usuario',
      });
    },
  },
});
