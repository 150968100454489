export default {
  mutations: {
    SET_COMMERCES: 'SET_COMMERCES',
    SET_COMMERCE_BRANCH: 'SET_COMMERCE_BRANCH',
    SET_SINGLE_COMMERCE: 'SET_SINGLE_COMMERCE',
    SET_COMMERCE_BRANCHES: 'SET_COMMERCE_BRANCHES',
    SET_COMMERCES_BY_COMPANY: 'SET_COMMERCES_BY_COMPANY',
    SET_NEW_COMMERCE_PARAMS: 'SET_NEW_COMMERCE_PARAMS',
    SET_EMPTY_NEW_COMMERCE_PARAMS: 'SET_EMPTY_NEW_COMMERCE_PARAMS',
    SET_COMPANY_COMMERCE_BY_COMMERCE_ID_LIST:
      'SET_COMPANY_COMMERCE_BY_COMMERCE_ID_LIST',

  },
  getters: {
    GET_COMMERCES: 'GET_COMMERCES',
    GET_COMMERCE_BRANCHES: 'GET_COMMERCE_BRANCHES',
    GET_SINGLE_COMMERCE: 'GET_SINGLE_COMMERCE',
    GET_COMMERCES_BY_COMPANY: 'GET_COMMERCES_BY_COMPANY',
    GET_NEW_COMMERCE_PARAMS: 'GET_NEW_COMMERCE_PARAMS',
  },
  actions: {
    SAVE_COMMERCE: 'SAVE_COMMERCE',
    COMMERCE_LIST: 'COMMERCE_LIST',
    COMMERCE_BRANCH_LIST: 'COMMERCE_BRANCH_LIST',
    COMMERCES_BY_COMPANY: 'COMMERCES_BY_COMPANY',
    UPDATE_COMMERCE: 'UPDATE_COMMERCE',
    SINGLE_COMMERCE: 'SINGLE_COMMERCE',
  },
};
