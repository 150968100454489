import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import orderKindType from '@/store/types/orderKinds';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { OrderKindParams, OrderKindState } from '@/store/interfaces/orderKinds';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;
const initialState = (): OrderKindState => {
  return {
    orderKinds: {
      data: [],
      pagination: {},
    },
  };
};

const state: OrderKindState = initialState();
const getters: GetterTree<OrderKindState, RootState> = {
  [orderKindType.getters.GET_ORDER_KINDS]: state => {
    return state.orderKinds;
  },
};

const mutations: MutationTree<OrderKindState> = {
  [orderKindType.mutations.SET_ORDER_KINDS]: (
    state,
    branch: responseCollection<OrderKindParams>
  ) => {
    state.orderKinds.data = branch.data;
    state.orderKinds.pagination = branch.meta.pagination;
  },
};
const actions: ActionTree<OrderKindState, RootState> = {
  [orderKindType.actions.ORDER_KINDS]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/order_kinds')
        .then((res: AxiosResponse) => {
          commit(orderKindType.mutations.SET_ORDER_KINDS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
