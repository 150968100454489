export default {
  mutations: {
    SET_COMPANY_USERS: 'SET_COMPANY_USERS',
    SET_SINGLE_COMPANY_USER: 'SET_SINGLE_COMPANY_USER',
    SET_SEARCH_USER: 'SET_SEARCH_USER',
  },
  getters: {
    GET_COMPANY_USERS: 'GET_COMPANY_USERS',
    GET_SINGLE_COMPANY_USER: 'GET_SINGLE_COMPANY_USER',
  },
  actions: {
    COMPANY_USERS_LIST: 'COMPANY_USERS_LIST',
    SINGLE_COMPANY_USER: 'SINGLE_COMPANY_USER',
    UPDATE_COMPANY_USER: 'UPDATE_COMPANY_USER',
    DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
    SAVE_COMPANY_USER: 'SAVE_COMPANY_USER',
    SEARCH_USER: 'SEARCH_USER',
  },
};
