



































import BussinessRegistration from '@/components/Naka/BussinessRegistration.vue';
import CurrenciesFiatAndTransactions from '@/components/Naka/CurrenciesFiatAndTransactions.vue';
import companyType from '@/store/types/company';
import root from '@/store/types/root';
import copyObj from '@/util/copyObj';
import { TeipNotifications } from '@/util/teipNotifications';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'NakaBindingForm',
  components: {
    BussinessRegistration,
    CurrenciesFiatAndTransactions,
  },
  computed: {
    ...mapGetters({
      getNewCompanyParams: `companyModule/${companyType.getters.GET_NEW_COMPANY_PARAMS}`,
      getProcess: root.getters.GET_START_PROCESS,
    }),
  },
  methods: {
    ...mapActions({
      updateCompany: `companyModule/${companyType.actions.UPDATE_COMPANY}`,
    }),
    updateNakaInfo() {
      const notificacion = new TeipNotifications();
      const companyToUpdate = copyObj(this.getNewCompanyParams);

      const toUpdate = {
        data: {
          naka_company_configuration: companyToUpdate.naka_company_configuration,
        },
        company_id: this.$route.params.company_id,
      };

      this.updateCompany(toUpdate)
        .then(() => {
          notificacion.success('Actualizado correctamente');
          this.$emit('cancel', true);
        })
        .catch(() => {
          notificacion.warning('Algo ha salido mal');
        });
    },
    cancel() {
      this.$emit('cancel', true);
    },
  },
});

