import store from '@/store';
import authType from '@/store/types/auth';
import { NavigationGuardNext, Route } from 'vue-router';

export const ifNotAuthenticated = (to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  if (!store.getters[`authModule/${authType.getters.IS_AUTHENTICATED}`]) {
    if (to.path === '/') {
      next('/login');
    } else {
      next();
    }
    return;
  }
  next('/');
};
export const ifAuthenticated = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  if (store.getters[`authModule/${authType.getters.IS_AUTHENTICATED}`]) {
    next();
    return;
  }
  next('/login');
};

export const checkPermissions = (
  _to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>,
  permission: string | string[]): void => {
  if (typeof permission === 'string') {
    permission = [permission];
  }
  const permissionsFromServer = store.getters[`authModule/${authType.getters.PERMISSIONS}`];
  const hasPermission = permission.every((p) => permissionsFromServer.includes(p));

  if (!hasPermission) {
    next('/403');
  }
  next();
};
