import { AuthTokens } from '@/store/interfaces/auth';
import store from '@/store';
import auth from '@/store/types/auth';
import dayjs from 'dayjs';

export default class RefreshTime {
  private time: string = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');
  private exceedTime = 2_147_483_647 // 24.855 days

  private userSession: AuthTokens = {
    access_token: '',
    created_at: 0,
    expires_in: 0,
    refresh_token: '',
    scope: '',
    token_type: '',
  };

  /**
   *
   * @param userSession
   */
  constructor(userSession: AuthTokens) {
    this.userSession = userSession;
  }

  /**
   * @returns it return the diference between current date and the time of
   * creation of token in seconds. The diference is calculated with unix time;
   */
  private calcDiferenceToDateInSeconds(): number {
    const time = dayjs(this.time);
    const createdAt = dayjs.unix(this.userSession.created_at)
      .format('YYYY-MM-DDTHH:mm:ssZ');

    return time.diff(createdAt, 'second');
  }

  /**
   * @returns it return the time in miliseconds
   */
  public timeToRefresh(): number {
    const dateInSecond = this.calcDiferenceToDateInSeconds();
    const differenceInSeconds = this.userSession.expires_in - dateInSecond;
    const RefreshTokenIn = Math.round(
      differenceInSeconds - differenceInSeconds * 0.05
    ) * 1000;

    if (RefreshTokenIn >= this.exceedTime) {
      this.expiresInInvalid();
    }
    return RefreshTokenIn;
  }

  /**
   * @return it return true if the time generated by function
   * timeToRefresh if more great than expired_in in miliseconds
   */
  public requiredNewToken(): boolean {
    const expires_in = this.userSession.expires_in * 1000;
    const expiredInMilliseconds = expires_in - (expires_in - expires_in * 0.05);
    const time = this.timeToRefresh();
    if (expiredInMilliseconds >= time && time > 0) {
      return true;
    }
    return false;
  }

  /**
   * Time valid for expires in is 2,147,483,647 milisecond, please dont exceeded this time.
   * @returns new Error;
   */
  private expiresInInvalid(): never {
    store.dispatch(`authModule/${auth.actions.AUTH_LOGOUT}`);
    const message = `Time to refresh token not valid, it has been exceeded to
    2_147_483_647 miliseconds supported by setTimeout function
    please contact with backend team for change time of refresh token`;

    // eslint-disable-next-line no-console
    console.error(`There have been a problem with token session,
      please contact with support@teip.io`);

    throw new Error(message);
  }
}
