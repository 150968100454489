import { responseCollection } from '@/store/interfaces/json-api/generics';
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  CompanyUserState,
  CompanyUserParams
} from '@/store/interfaces/companyUser';
import userType from '@/store/types/companyUser';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';

const namespaced = true;

const initialState = (): CompanyUserState => {
  return {
    companyUsers: {
      data: [],
      pagination: {},
    },
    companyUser: {},
  };
};

const state: CompanyUserState = initialState();

const getters: GetterTree<CompanyUserState, any> = {
  [userType.getters.GET_COMPANY_USERS]: state => {
    return state.companyUsers;
  },
  [userType.getters.GET_SINGLE_COMPANY_USER]: state => {
    return state.companyUser;
  },
};
const mutations: MutationTree<CompanyUserState> = {
  [userType.mutations.SET_COMPANY_USERS]: (
    state,
    users: responseCollection<CompanyUserParams>
  ) => {
    const usersFormated: Array<CompanyUserParams> = [];
    users.data.map((user: CompanyUserParams) => {
      let userFormate: CompanyUserParams;
      userFormate = user;

      usersFormated.push(userFormate);
    });
    state.companyUsers.data = usersFormated;
    state.companyUsers.pagination = users.meta.pagination;
  },
  [userType.mutations.SET_SINGLE_COMPANY_USER]: (state, user: CompanyUserParams) => {
    state.companyUser = user;
  },
};
const actions: ActionTree<CompanyUserState, any> = {
  [userType.actions.COMPANY_USERS_LIST]: ({ commit, }, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/company/company_users?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_COMPANY_USERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SEARCH_USER]: ({ commit, }, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/company/users?${queryParams}`)
        .then((res: AxiosResponse) => {
          const user = res.data.data[0] ? res.data.data[0] : {};
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, user);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SINGLE_COMPANY_USER]: ({ commit, }, user_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/company/company_users/${user_id}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.UPDATE_COMPANY_USER]: ({ commit, }, { user_id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/company/company_users/${user_id}`, { data, })
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SAVE_COMPANY_USER]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/company/company_users', { data, })
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.DELETE_COMPANY_USER]: (_, id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/company/company_users/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
