













































































































import {
  CompanyUserParams,
  iUserSearched,
  NewCompanyUser
} from '@/store/interfaces/companyUser';
import permissionGroup from '@/store/types/permissionGroup';
import { ValidationProvider } from 'vee-validate';
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CommerceBranchSelect from './form/commerceBranchSelect.vue';
import SearchUserByEmail from './form/searchUser.vue';
import configurations from '@/store/types/configurations';

interface formValidatorContactModal
  extends InstanceType<typeof ValidationProvider> {
  fields: {
    email: {
      valid: boolean;
    };
  };
}
type SearchUserInterface = InstanceType<typeof SearchUserByEmail>;

export default Vue.extend({
  name: 'AddTeipBizUser',
  components: {
    SearchUserByEmail,
    CommerceBranchSelect,
  },
  props: {
    userUpdate: {
      type: Object as PropType<CompanyUserParams>,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    saveIsSuccess: {
      type: Boolean,
      required: true,
    },
    viewModal: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    messageButton: 'Guardar usuario',
    update: false,
    commerceBranch: {
      branch_id: '',
      commerce_id: '',
    },
    code: '',
    recieveNotification: false,
    bizUser: {
      permission_group_id: '',
      user: {
        email: '',
        profile: {
          first_name: '',
          last_name: '',
        },
      },
      payment_confirmation_email: false,
    } as NewCompanyUser,
    positionContactKind: 1,
    userFound: false,
    contactKind: [],
    showAllForm: false,
  }),
  computed: {
    ...mapGetters({
      permissions: `permissionGroupModule/${permissionGroup.getters.GET_PERMISSIONS_GROUP_LIST}`,
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.$props.viewModal;
      },
      set(val: boolean): void {
        if (this.$data.update) this.cleanModel();
        this.$emit('update:viewModal', val);
      },
    },
  },
  async created() {
    await this.permissionGroupList('kind_eq=1');
  },
  methods: {
    ...mapActions({
      permissionGroupList: `permissionGroupModule/${permissionGroup.actions.PERMISSION_GROUP_LIST}`,
    }),
    userSearched({ user, }: { user: iUserSearched | null }) {
      if (user) {
        this.userFound = true;
        this.bizUser.user.profile = user.profile;
      } else {
        this.bizUser.user.profile.first_name = '';
        this.bizUser.user.profile.last_name = '';
        this.userFound = false;
      }

      this.showAllForm = true;
    },
    cleanModel() {
      this.userFound = false;
      this.code = '';

      this.commerceBranch.branch_id = '';
      this.commerceBranch.commerce_id = '';

      this.bizUser = {
        permission_group_id: '',
        user: {
          email: '',
          profile: {
            first_name: '',
            last_name: '',
          },
        },
        payment_confirmation_email: false,
      };

      if (this.$refs.formValidatorContactModal as formValidatorContactModal) {
        (
          this.$refs.formValidatorContactModal as formValidatorContactModal
        ).reset();
      }
      if (this.$refs.searchUserByEmail as SearchUserInterface) {
        (
          (this.$refs.searchUserByEmail as SearchUserInterface).$refs
            .formValidatorSearchUserByEmail as formValidatorContactModal
        ).reset();
      }
    },
    contactAction() {
      const user: NewCompanyUser = { ...this.bizUser, };

      if (this.commerceBranch.branch_id) {
        user.branch_id = this.commerceBranch.branch_id;
      }

      if (this.commerceBranch.commerce_id) {
        user.commerce_id = this.commerceBranch.commerce_id;
      }

      if (this.code) {
        user.code = this.code;
      }

      user.payment_confirmation_email = this.recieveNotification;

      user.company_id = this.$route.params.company_id;

      if (Object.keys(this.userUpdate).length > 0) {
        user.user_id = this.userUpdate.user_id;

        if (this.userUpdate.branch_id) {
          user.branch_id = this.userUpdate.branch_id;
        }

        if (this.userUpdate.commerce_id) {
          user.commerce_id = this.userUpdate.commerce_id;
        }
      }

      let contactInfo = {
        update: false,
        user,
        companyUserId: '',
      };
      if (this.update) {
        contactInfo = {
          update: true,
          user,
          companyUserId: this.userUpdate.id,
        };
      }
      this.$emit('contactInfo', contactInfo);
    },
    cancel() {
      this.cleanModel();
      this.syncedViewModal = false;
    },
  },
  watch: {
    errors: {
      immediate: true,
      deep: true,
      handler(errorsToServer: string[]) {
        if (this.$refs.formValidatorContactModal as formValidatorContactModal) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errors: any = [];
          Object.entries(errorsToServer).forEach(([key, value]) => {
            const inputKeyName = key.split('.')[1];
            if (inputKeyName === 'email') {
              errors.email = value;
            } else {
              errors[key] = value;
            }
          });
          (
            this.$refs.formValidatorContactModal as formValidatorContactModal
          ).setErrors(errors);
        }
      },
    },
    saveIsSuccess: {
      deep: true,
      handler(success: boolean) {
        if (success) this.cleanModel();
      },
    },
    userUpdate: {
      immediate: true,
      deep: true,
      handler(val: CompanyUserParams) {
        if (Object.keys(val).length > 0) {
          this.showAllForm = true;
          this.update = true;
          this.messageButton = 'Actualizar usuario';
          this.recieveNotification = val.payment_confirmation_email;

          this.bizUser = {
            permission_group_id: val.permission_group_id || '',
            user_id: val.user_id || '',
            user: {
              email: val.user.email || '',
              profile: {
                first_name: val.user.profile.first_name || '',
                last_name: val.user.profile.last_name || '',
              },
            },
            payment_confirmation_email: val.payment_confirmation_email,
          };
          this.code = val.code || '';
        } else {
          this.showAllForm = false;
          this.update = false;
          this.messageButton = 'Guardar usuario';
          this.recieveNotification = false;
          this.cleanModel();
        }
      },
    },
  },
});
