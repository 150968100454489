













































import { tPropsToSearch, tTableColumns } from 'teip-components';
import { tPaymentsLinkParams } from '@/store/interfaces/paymentsLink';
import paymentsLink from '@/store/types/paymentsLink';
import BaseButton from '@/components/Argon/BaseButton.vue';
import { TeipNotifications } from '@/util/teipNotifications';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';

export default Vue.extend({
  components: { BaseButton, },
  name: 'PaymentsLink',
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre de Sucursal',
        minWidth: 250,
        formatter: (row: tPaymentsLinkParams): string => {
          return row.branch.name;
        },
      },
      {
        prop: 'url',
        label: 'Link de pago',
        minWidth: 250,
      }
    ] as tTableColumns,
    paymentLink: {
      id: '',
      url: null,
      branch: {
        id: '',
        name: '',
      },
    } as tPaymentsLinkParams,
  }),
  computed: {
    ...mapGetters({
      getPaymentsLinksList: `paymentsLinkModule/${paymentsLink.getters.GET_PAYMENTS_LINK}`,
    }),
  },
  methods: {
    ...mapActions({
      paymentsLinkList: `paymentsLinkModule/${paymentsLink.actions.PAYMENTS_LINK}`,
    }),
    paymentLinkSelected(link: tPaymentsLinkParams): void {
      this.paymentLink = link;
    },
    copyLink(link: tPaymentsLinkParams): void {
      const notification = new TeipNotifications();
      const url = `${link.url}`;

      navigator.clipboard.writeText(url);

      notification.success('Copiado!!', url);
    },
  },
});
