export default {
  mutations: {
    SET_GATEWAY_PAYMENT: 'SET_GATEWAY_PAYMENT',
  },
  getters: {
    GET_GATEWAY_PAYMENT: 'GET_GATEWAY_PAYMENT',
  },
  actions: {
    GATEWAY_PAYMENT: 'GATEWAY_PAYMENT_LIST',
  },
};
