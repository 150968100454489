export default {
  mutations: {
    SET_DRAFTS_COMPANY: 'SET_DRAFTS_COMPANY',
    SET_SINGLE_DRAFT_COMPANY: 'SET_SINGLE_DRAFT_COMPANY',
  },
  getters: {
    GET_DRAFTS_COMPANIES: 'GET_DRAFTS_COMPANIES',
    GET_DRAFT_COMPANY: 'GET_DRAFT_COMPANY',
  },
  actions: {
    DRAFTS_COMPANIES_LIST: 'DRAFTS_COMPANIES_LIST',
    SINGLE_DRAFT_COMPANY: 'SINGLE_DRAFT_COMPANY',
  },
};
