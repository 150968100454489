import Vue from 'vue';

export class TeipNotifications extends Vue {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  warning(title: string, message = ''): void {
    this.$notify({
      type: 'warning',
      title,
      message,
      iconClass: 'ni ni-fat-remove',
      duration: 500,
    });
  }

  success(title: string, message = ''): void {
    this.$notify({
      type: 'success',
      title,
      message,
      iconClass: 'ni ni-check-bold',
      duration: 500,
    });
  }

  error(title: string, message = ''): void {
    this.$notify({
      type: 'error',
      title,
      message,
      iconClass: 'ni ni-bell-55',
      duration: 500,
    });
  }
}
