export default {
  mutations: {
    SET_BRANCHES_BY_COMPANY: 'SET_BRANCHES_BY_COMPANY',
    SET_NEW_BRANCH_PARAMS: 'SET_NEW_BRANCH_PARAMS',
    SET_EMPTY_NEW_BRANCH_PARAMS: 'SET_EMPTY_NEW_BRANCH_PARAMS',
    SET_SINGLE_BRANCH: 'SET_SINGLE_BRANCH',
    SET_BRANCH_BY_COMMERCE: 'SET_BRANCH_BY_COMMERCE',
    SET_BRANCHS_SIGNATURES: 'SET_BRANCHS_SIGNATURES',
  },
  getters: {
    GET_BRANCH_BY_COMPANY: 'GET_BRANCH_BY_COMPANY',
    GET_NEW_BRANCH_PARAMS: 'GET_NEW_BRANCH_PARAMS',
    GET_SINGLE_BRANCH: 'GET_SINGLE_BRANCH',
    GET_BRANCH_BY_COMMERCE: 'GET_BRANCH_BY_COMMERCE',
    GET_BRANCHS_SIGNATURES: 'GET_BRANCHS_SIGNATURES',
  },
  actions: {
    BRANCHES_BY_COMPANY: 'BRANCHES_BY_COMPANY',
    SAVE_BRANCH: 'SAVE_BRANCH',
    LIST_BRANCHES_BY_COMMERCE: 'LIST_BRANCHES_BY_COMMERCE',
    SINGLE_BRANCH: 'SINGLE_BRANCH',
    UPDATE_BRANCH: 'UPDATE_BRANCH',
    BRANCHS_SIGNATURES: 'BRANCHS_SIGNATURES',
  },
};
