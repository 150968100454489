/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { checkPermissions, ifAuthenticated } from '../routeValidation';
import { NavigationGuardNext, Route } from 'vue-router';
import Vue from 'vue';
import { commercePermissions, companiesPermissions } from './permissions/index';

// Branch
const userBranch = () =>
  import(
    /* webpackChunkName: "branch" */
    '@/views/Commerce/Partners/Branch/Branchs.vue'
  );

// Commerce
const newCommerce = () =>
  import(
    /* webpackChunkName: "new_commerce" */ '@/views/Commerce/NewCommerce.vue'
  );
const CommerceInbound = () =>
  import(
    /* webpackChunkName: "commerce_approve" */ '@/views/Commerce/CommerceInbound.vue'
  );
const CommerceApprobeDetail = () =>
  import(
    /* webpackChunkName: "commerce_approve_see" */ '@/views/Commerce/Approbe/CommerceApprobeDetail.vue'
  );
const Company = () =>
  import(/* webpackChunkName: "companies" */ '@/views/Commerce/Company/Company.vue');

const CommerceAffiliate = () =>
  import(
    /* webpackChunkName: "commerce_affiliate" */
    '@/views/Commerce/CommercePartners.vue'
  );
const CommerceAffiliateDetail = () =>
  import(
    /* webpackChunkName: "commerce_affiliate_detail" */
    '@/views/Commerce/Partners/commercePartnersDetail.vue'
  );
const NewCompany = () =>
  import(
    /* webpackChunkName: "new_company" */
    '@/views/Commerce/Company/NewCompany.vue'
  );
const CompanyProfile = () =>
  import(
    /* webpackChunkName: "company_profile" */
    '@/views/Commerce/Company/Profile.vue'
  );
const commercePages = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  name: 'Commerce',
  children: [
    {
      path: '/commerce/new',
      name: 'new',
      component: newCommerce,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_company_commerces');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/new-commerce/company/:company_id',
      name: 'new-commerce-from-company',
      component: newCommerce,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_company_commerces');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/inbound',
      name: 'commerce-inbound',
      component: CommerceInbound,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'index_drafts_companies');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/company/new',
      name: 'new-company',
      component: NewCompany,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_companies');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/company/:company_id',
      component: CompanyProfile,
      meta: {
        navbarType: 'light',
      },
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_companies');
      },
      children: [
        {
          path: '',
          name: 'company-profile',
          component: require('@/components/Company/Profile/Profile.vue').default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'naka',
          name: 'company-naka-binding',
          component: require('@/components/Company/Profile/Naka.vue').default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'documentos',
          name: 'company-documents',
          component: require('@/components/Company/Profile/Documents.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_company_documents');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'contactos',
          name: 'company-contacts',
          component: require('@/components/Company/Profile/Contacts.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_contacts');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'contacts-documents/:contact_id',
          name: 'contacts-documents',
          component: require('@/components/Company/Profile/Documents.vue').default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_contact_documents');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'sucursales',
          name: 'company-branches',
          component: require('@/components/Company/Profile/Branches.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'branches_companies');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'comercios',
          name: 'company-commerce',
          component: require('@/components/Company/Profile/Commerces.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_company_commerces');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'cuentas',
          name: 'bank-accounts',
          component: require('@/components/Company/Profile/bankAccount.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'show_bank_accounts');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'users',
          name: 'company-users',
          component: require('@/components/Company/Profile/CompanyUsers.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_company_company_users');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'qrs',
          name: 'company-qr-code',
          component: require('@/components/Company/Profile/QrCodeGeneration.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'branch_signatures_companies');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          path: 'payments-link',
          name: 'payments-link',
          component: require('@/components/Company/Profile/PaymentsLink.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'branch_payment_links_companies');
          },
          meta: {
            navbarType: 'light',
          },
        }
      ],
    },
    {
      path: '/commerce/inbound/:commerce_id',
      component: CommerceApprobeDetail,
      meta: {
        navbarType: 'light',
      },
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_drafts_companies');
      },
      children: [
        {
          path: '',
          name: 'commerce-inbound-id',
          component: require('@/components/Inbound/newVersion/profile.vue')
            .default,
          meta: {
            navbarType: 'light',
          },
        }
      ],
    },
    {
      path: '/commerce/company',
      name: 'company',
      component: Company,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, companiesPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/partners',
      name: 'commerce-partners',
      component: CommerceAffiliate,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, commercePermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/partners/:commerce_id',
      component: CommerceAffiliateDetail,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_commerces');
      },
      meta: {
        navbarType: 'light',
      },
      children: [
        {
          name: 'partners-profile',
          path: '/',
          component: require('@/components/commerce/Partners/profile.vue')
            .default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'partners-branchs',
          path: 'sucursales',
          component: require('@/components/commerce/Partners/branchs.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'branches_commerces');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'partners-companies',
          path: 'empresas',
          component: require('@/components/commerce/Partners/companies.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'companies_commerces');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'partners-banner-logo',
          path: 'logos-banner',
          component: require('@/components/commerce/Partners/updateImages.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'companies_commerces');
          },
          meta: {
            navbarType: 'light',
          },
        }
      ],
    },
    {
      path: '/commerce/partners/:commerce_id/sucursal/:sucursal_id/users',
      name: 'commerce-partners-branches-user',
      component: userBranch,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'index_users');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/commerce/partners/:commerce_id/sucursal/:sucursal_id',
      component: userBranch,
      meta: {
        navbarType: 'light',
      },
      children: [
        {
          name: 'users-branch-profile',
          path: '/',
          component: require('@/components/commerce/branches/profile.vue')
            .default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'users-branch-contacts',
          path: 'contactos',
          component: require('@/components/commerce/branches/contacts.vue')
            .default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'users-braches',
          path: 'usuarios',
          component: require('@/components/commerce/branches/users.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_users');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'pos-braches',
          path: 'pos',
          component: require('@/components/commerce/branches/pos.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_point_of_sales');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'new-user-brach',
          path: 'nuevo-usuario',
          component: require('@/components/commerce/branches/newUser.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'create_point_of_sales');
          },
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'update-user-brach',
          path: 'actualizar-usuario',
          component: require('@/components/commerce/branches/updateUser.vue')
            .default,
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'update_point_of_sales');
          },
          meta: {
            navbarType: 'light',
          },
        }
      ],
    }
  ],
};
export default commercePages;
