
































import { CompanyCommerceParams } from '@/store/interfaces/company_commerce';
import company_commerce from '@/store/types/company_commerce';
import { http } from '@/util/HTTP';
import { TeipNotifications } from '@/util/teipNotifications';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'NakaTags',
  data() {
    return {
      tag: '',
      tags: [],
      updating: false,
    };
  },
  computed: {
    ...mapGetters({
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
    }),
  },
  async mounted() {
    await this.tagList();
    this.tag =
      this.singleCompanyCommerce.naka_company_commerce_configuration.tag_name;
  },
  methods: {
    tagList: async function () {
      try {
        const response = await http.get('backoffice/naka/collections/tags');
        this.tags = response.data.data;
      } catch (error) {
        const notification = new TeipNotifications();
        notification.warning('¡Error!', 'No se pudo cargar la lista de tags');
      }
    },
    async updateNakaTag(): Promise<void> {
      const notification = new TeipNotifications();
      const id = this.singleCompanyCommerce.id;
      this.updating = true;

      http
        .put(`backoffice/company_commerces/${id}`, {
          data: {
            naka_company_commerce_configuration: {
              tag_name: this.tag,
            },
          },
        })
        .then((res: AxiosResponse<{ data: CompanyCommerceParams }>) => {
          notification.success('¡Éxito!', 'Etiqueta actualizada correctamente');
          this.$store.commit(
            `companyCommerceModule/${company_commerce.mutations.SET_SINGLE_COMPANY_COMMERCE}`,
            res.data.data,
            { root: true, }
          );
          this.$emit('success');
        })
        .catch(() => {
          notification.warning('¡Error!', 'Algo ha salido mal :(');
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
});
