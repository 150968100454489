/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import paymentIntentType from '@/store/types/paymentIntents';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { paymentIntentParams, paymentsIntentState } from '@/store/interfaces/paymentIntent';
import { responseCollection } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const state: paymentsIntentState = {
  paymentIntents: {
    data: [],
    pagination: {},
  },
};
const getters: GetterTree<paymentsIntentState, any> = {
  [paymentIntentType.getters.GET_PAYMENTS_INTENT_BY_TRANSACTION]: state => {
    return state.paymentIntents;
  },
};
const mutations: MutationTree<paymentsIntentState> = {
  [paymentIntentType.mutations.SET_PAYMENTS_INTENT_BY_TRANSACTION]: (
    state,
    paymentsIntent: responseCollection<paymentIntentParams>
  ) => {
    state.paymentIntents.data = paymentsIntent.data;
    state.paymentIntents.pagination = paymentsIntent.meta.pagination;
  },
};
const actions: ActionTree<paymentsIntentState, any> = {
  [paymentIntentType.actions.PAYMENTS_INTENT_BY_TRANSACTION]: (
    { commit, },
    { query, user_id, payment_id, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          `backoffice/customers/${user_id}/payment_requests/${payment_id}/payment_intents?${query}`
        )
        .then((res: AxiosResponse) => {
          commit(
            paymentIntentType.mutations.SET_PAYMENTS_INTENT_BY_TRANSACTION,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
