

































































import resetPasswordConfirmation from '@/components/modals/actionConfirm.vue';
import { companyHost } from '@/const/companyHost';
import { UserStaffParams } from '@/store/interfaces/staffUser';
import passwordType from '@/store/types/password';
import userStaffType from '@/store/types/userStaff';
import { TeipNotifications } from '@/util/teipNotifications';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'UserStaffList',
  components: {
    resetPasswordConfirmation,
  },
  data: () => ({
    companyHost,
    userSelected: {
      email: '',
      id: '',
      permission_groups: [
        { id: '', name: '', kind: 'staff', }
      ],
      profile: {
        first_name: '',
        id: '',
        last_name: '',
      },
    } as UserStaffParams,
    showModal: false,
    account: '',
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 250,
        sortable: true,
        formatter: (params: UserStaffParams): string => {
          return `${params.profile.first_name} ${params.profile.last_name}`;
        },
      },
      {
        prop: 'email',
        label: 'Correo',
        minWidth: 275,
        sortable: true,
      }
    ] as tTableColumns,
    accountFilter: [
      {
        id: '1',
        value: 'Cuentas',
      },
      {
        id: '2',
        value: 'Activos',
      },
      {
        id: '3',
        value: 'Bloqueados',
      }
    ],
  }),
  computed: {
    ...mapGetters({
      getlistUserStaff: `userStaff/${userStaffType.getters.GET_USER_STAFF}`,
    }),
  },
  methods: {
    ...mapMutations({
      setUserStaff: `userStaff/${userStaffType.mutations.SET_SINGLE_USER_STAFF}`,
    }),
    ...mapActions({
      listUserStaff: `userStaff/${userStaffType.actions.USER_STAFF}`,
      resetPass: `passwordModule/${passwordType.actions.RESET_PASSWORD}`,
    }),
    rowSelect(user: UserStaffParams): void {
      this.userSelected = user;
    },
    seeProfile(): void {
      this.setUserStaff(this.userSelected);
      this.$router.push({
        path: `staff/${this.userSelected.id}`,
      });
    },
    restartPassword(): void {
      this.showModal = true;
    },
    confirmResetPassword(): void {
      const notification = new TeipNotifications();
      this.resetPass({ user_id: this.userSelected.id, })
        .then(() => {
          this.showModal = false;
          notification.success(
            'Contraseña restablecida',
            'Se ha enviado un email de restablecimiento'
          );
        })
        .catch(() => {
          notification.warning(
            '¡Error!',
            'No hemos podido restablecer contraseña :('
          );
        });
    },
  },
});
