



















































































































import { CompanyParams } from '@/store/interfaces/company';
import { liquidationKind } from '@/store/interfaces/liquidations';
import configurations from '@/store/types/configurations';
import { phoneFormat } from '@/util/phoneFormat';
import { taxPayer } from '@/util/transformString/translationsDecorator';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ShowCompanyInfo',
  props: {
    company: {
      required: true,
      type: Object as PropType<CompanyParams>,
    },
  },
  data: () => ({
    phoneFormat,
    taxPayer,
  }),
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
  },
  methods: {
    isValidationKindValid(liquidation: liquidationKind | null): string {
      return liquidation ? liquidation.name : '';
    },
  },
});
