export default {
  mutations: {
    SET_BANK_ACCOUNT_TYPES: 'SET_BANK_ACCOUNT_TYPES',
    SET_BILLING_TYPES: 'SET_BILLING_TYPES',
    SET_PAYMENT_REQUEST_STATUSES: 'SET_PAYMENT_REQUEST_STATUSES',
    SET_PAYMENT_DISTRIBUTION_KINDS: 'SET_PAYMENT_DISTRIBUTION_KINDS',
    SET_TAXPAYER_TYPES: 'SET_TAXPAYER_TYPES',
  },
  getters: {
    GET_BANK_ACCOUNT_TYPES: 'GET_BANK_ACCOUNT_TYPES',
    GET_BILLING_TYPES: 'GET_BILLING_TYPES',
    GET_PAYMENT_REQUEST_STATUSES: 'GET_PAYMENT_REQUEST_STATUSES',
    GET_PAYMENT_DISTRIBUTION_KINDS: 'GET_PAYMENT_DISTRIBUTION_KINDS',
    GET_TAXPAYER_TYPES: 'GET_TAXPAYER_TYPES',
  },
  actions: {
    BANK_ACCOUNT_TYPES: 'BANK_ACCOUNT_TYPES',
    BILLING_TYPES: 'BILLING_TYPES',
    PAYMENT_REQUEST_STATUSES: 'PAYMENT_REQUEST_STATUSES',
    PAYMENT_DISTRIBUTION_KINDS: 'PAYMENT_DISTRIBUTION_KINDS',
    TAXPAYER_TYPES: 'TAXPAYER_TYPES',
  },
};
