<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar :logo="logo">
      <template #links>
        <!-- <sidebar-item
          :link="{
            name: 'Cuentas',
            icon: 'ni ni-archive-2 text-blue',
            path: '/accounts-list',
          }"
        /> -->

        <sidebar-item
          v-can:at-least-one="[
            ...transactionHistoryMonitorPermissions,
            ...transactionHistoryPermissions,
            ...transactionHistoryNotAffiliatedPermissions,
            ...transferHistoryPermissions,
            'transactions',
          ]"
          :link="{
            name: $t('menu.transactions.plural.formal'),
            icon: 'ni ni-money-coins text-primary',
          }"
        >
          <sidebar-item
            v-can="transactionHistoryMonitorPermissions"
            :link="{ name: 'Monitor', path: '/transactions-tickets/monitor' }"
          />
          <sidebar-item
            v-can="transactionHistoryPermissions"
            :link="{
              // name: 'Historial Transacciones',
              name: `Historial de ${$t('menu.transactions.plural.informal')}`,
              path: '/transactions-tickets/record',
            }"
          />
          <sidebar-item
            v-can="transactionHistoryNotAffiliatedPermissions"
            :link="{
              name: 'Historial de no afiliados',
              path: '/transactions-tickets/affiliated',
            }"
          />
          <sidebar-item
            v-can="transferHistoryPermissions"
            :link="{
              name: 'Historial Transferencias',
              path: '/transfer/history',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-can:at-least-one="[
            ...liquidationPermissions,
            ...settlementsPermissions,
            'settlements',
          ]"
          :link="{
            name: 'Liquidaciones',
            icon: 'ni ni-ungroup text-orange',
          }"
        >
          <sidebar-item
            v-can="settlementsPermissions"
            :link="{
              name: 'Reportes',
              path: '/liquidations-report',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-can:at-least-one="[
            ...companiesPermissions,
            ...commercePermissions,
            ...draftCompanyPermissions,
            ...commerceKindsPermissions,
            'commerces',
          ]"
          :link="{
            name: $t('menu.commerce.plural.formal'),
            icon: 'ni ni-shop text-info',
          }"
        >
          <sidebar-item
            v-can="companiesPermissions"
            :link="{
              name: $t('menu.company.plural.formal'),
              path: '/commerce/company',
              exact: 'true',
            }"
          />
          <sidebar-item
            v-can="commercePermissions"
            :link="{
              name: $t('menu.commerce.plural.formal'),
              path: '/commerce/partners',
            }"
          />
          <sidebar-item
            v-can="draftCompanyPermissions"
            :link="{ name: 'Inbound', path: '/commerce/inbound' }"
          />
          <sidebar-item
            v-can="commerceKindsPermissions"
            :link="{
              name: 'Categorías',
              path: '/commerce/category',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-can="customerUserPermissions"
          :link="{
            name: 'Usuarios',
            icon: 'ni ni-circle-08 text-pink',
            path: '/accounts',
          }"
        />

        <sidebar-item
          v-can="buyersPermissions"
          :link="{
            name: 'Clientes',
            icon: 'ni ni-satisfied text-default',
            path: '/buyers',
          }"
        />

        <sidebar-item
          v-can="ticketPermissions"
          :link="{
            name: 'Soporte al cliente',
            icon: 'ni ni-vector text-red',
            path: '/customer-support',
          }"
        />

        <sidebar-item
          v-can:at-least-one="[
            ...staffPermissions,
            ...permissionsOfPermissions,
            'staff',
          ]"
          :link="{
            name: 'Gestión de staff',
            icon: 'ni ni-badge text-info',
          }"
        >
          <sidebar-item
            v-can="staffPermissions"
            :link="{
              name: 'Staff',
              path: '/staff',
            }"
          />
          <sidebar-item
            v-can="permissionsOfPermissions"
            :link="{
              name: 'Permisos',
              path: '/permisos',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-can:at-least-one="[
            ...discountPermissions,
            ...cashbackPermissions,
            'marketing',
          ]"
          :link="{
            name: 'Mercadeo',
            icon: 'ni ni-spaceship text-default',
          }"
        >
          <!-- <sidebar-item
            :link="{
              name: 'Banners',
              path: '/noveltiesAndPromotions/banners/',
            }"
          /> -->
          <sidebar-item
            v-can="discountPermissions"
            :link="{
              name: 'Descuentos',
              path: '/noveltiesAndPromotions/discounts',
            }"
          />
          <sidebar-item
            v-can="cashbackPermissions"
            :link="{
              name: 'Cashback',
              path: '/noveltiesAndPromotions/cashback',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-can="withdrawalsPermissions"
          :link="{
            name: 'Retiros a cuentas bancarias',
            icon: 'ni ni-archive-2 text-pink',
            path: '/refund-by-transfer',
          }"
        />
      </template>
      <template #links-after>
        <small ref="version" class="version">Versión: {{ version }}</small>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" />

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view />
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { version } from '@/../package.json';
import {
  buyersPermissions,
  cashbackPermissions,
  commerceKindsPermissions,
  commercePermissions,
  companiesPermissions,
  customerUserPermissions,
  discountPermissions,
  draftCompanyPermissions,
  liquidationPermissions,
  permissionsOfPermissions,
  settlementsPermissions,
  staffPermissions,
  ticketPermissions,
  transactionHistoryMonitorPermissions,
  transactionHistoryNotAffiliatedPermissions,
  transactionHistoryPermissions,
  transferHistoryPermissions,
  withdrawalsPermissions
} from '@/routes/menus/permissions/index';
import authTypes from '@/store/types/auth';
import Vue from 'vue';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';
import DashboardNavbar from './DashboardNavbar.vue';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default Vue.extend({
  components: {
    DashboardNavbar,
    FadeTransition,
  },
  data() {
    return {
      buyersPermissions,
      cashbackPermissions,
      commerceKindsPermissions,
      commercePermissions,
      companiesPermissions,
      customerUserPermissions,
      discountPermissions,
      draftCompanyPermissions,
      liquidationPermissions,
      logo: `${process.env.VUE_APP_LOGO}`,
      permissionsOfPermissions,
      settlementsPermissions,
      staffPermissions,
      ticketPermissions,
      transactionHistoryMonitorPermissions,
      transactionHistoryNotAffiliatedPermissions,
      transactionHistoryPermissions,
      transferHistoryPermissions,
      version,
      withdrawalsPermissions,
    };
  },
  methods: {
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      `authModule/${authTypes.getters.AUTH_PERMISSIONS}`
      // ...
    ]),
  },
  mounted() {
    this.initScrollbar();
  },
});
</script>
<style lang="scss">
.version {
  display: block;
  margin: 0 0 0.7rem 0;
  color: gray;
  font-size: 0.7rem;
}
</style>
