























import branchsUser from '@/store/types/branchsUser';
import { AxiosError } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import newUserForm from './form/user.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'NewBranchUsers',
  components: {
    newUserForm,
  },
  data: () => ({
    autogenerateDataModel: {
      branch_id: '',
    },
    errors: {},
    success: false,
  }),
  mounted(): void {
    this.$data.autogenerateDataModel.branch_id = this.$route.params.sucursal_id;
  },
  computed: {
    ...mapGetters({
      getSingleBranchUser: `commerceModule/branches/users/${branchsUser.getters.GET_SINGLE_USER_BRANCH}`,
      getUserBranch: `commerceModule/branches/users/${branchsUser.getters.GET_USER_BRANCHES}`,
    }),
  },
  methods: {
    ...mapActions({
      newUser: `commerceModule/branches/users/${branchsUser.actions.NEW_BRANCH_USER}`,
    }),
    newBranchUser(): void {
      const dataToSend = {
        ...this.getSingleBranchUser,
        ...this.$data.autogenerateDataModel,
      };
      const teipNotification = new TeipNotifications();

      this.newUser(dataToSend)
        .then(() => {
          teipNotification.success('Usuario guardado correctamente :)');
          this.$data.success = true;
          this.$router.push({
            name: 'users-braches',
            hash: '#usuarios',
          });
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 422) {
            this.$data.errors = err.response.data.error;
          } else {
            teipNotification.warning(
              'Ha ocurrido un error al tratar de guardar el usuario :('
            );
          }
        })
        .finally(() => {
          this.$data.success = false;
        });
    },
  },
});
