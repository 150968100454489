/* eslint-disable @typescript-eslint/no-explicit-any */

import { ActionTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import passwordType from '@/store/types/password';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';

const namespaced = true;

const actions: ActionTree<any, RootState> = {
  [passwordType.actions.RESET_PASSWORD]: async (_, { model = 'staffs', user_id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/${model}/${user_id}/password`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [passwordType.actions.RESET_PASSWORD_CURRENT_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .put('backoffice/password', { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  actions,
};
