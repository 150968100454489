export default {
  mutations: {
    SET_REFUND_BY_TRANSFER: 'SET_REFUND_BY_TRANSFER',
    SET_REFUND_BY_USER_TRANSFER: 'SET_REFUND_BY_USER_TRANSFER',
  },
  getters: {
    GET_REFUND_BY_TRANSFER: 'GET_REFUND_BY_TRANSFER',
    GET_REFUND_BY_USER_TRANSFER: 'GET_REFUND_BY_USER_TRANSFER',
  },
  actions: {
    REFUND_BY_TRANSFER_LIST: 'REFUND_BY_TRANSFER_LIST',
    REFUND_BY_TRANSFER_USER_LIST: 'REFUND_BY_TRANSFER_USER_LIST',
    WITHDRAWALS_REFUND: 'WITHDRAWALS_REFUND',
  },
};
