
















































































import CreditCard from '@/components/Reusable/CreditCard.vue';
import { transactionsCustomerParams } from '@/store/interfaces/transactionsCustomer';
import paymentIntentType from '@/store/types/paymentIntents';
import { responseDataPagination } from '@/store/interfaces/json-api/generics';
import transactionsCustomer from '@/store/types/transactionsCustomer';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import DatesFormat from '@/util/classes/datesFormat';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
import { moneyFormat } from '@/util/filters';
const formatDate = new DatesFormat();

export default Vue.extend({
  name: 'UserPaymentIntentsTransactions',
  components: {
    CreditCard,
    StatusBadges,
  },
  data: () => ({
    companyHost,
    moneyFormat,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'amount_cents',
        label: 'Monto',
        minWidth: 120,
        sortable: true,
        slotName: 'amount_cents',
      },
      {
        prop: 'network_card',
        label: 'Tarjeta',
        minWidth: 120,
        sortable: true,
        slotName: 'card',
      },
      {
        prop: 'response_code',
        label: 'Código de respuesta',
        minWidth: 250,
        sortable: true,
      },
      {
        prop: 'authorization_number',
        label: 'Número de autorización',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'reference_number',
        label: 'Número de referencia',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'status',
        label: 'Estado',
        minWidth: 220,
        sortable: true,
        slotName: 'status',
      },
      {
        prop: 'biometrics',
        label: 'Biometría',
        minWidth: 120,
        sortable: true,
        formatter: (row: transactionsCustomerParams): string => {
          return row.biometrics ? 'Si' : 'No';
        },
      },
      {
        prop: 'created_at',
        label: 'Fecha y hora',
        minWidth: 220,
        sortable: true,
        formatter: (row: transactionsCustomerParams): string => {
          return formatDate.formatDateTime(row.created_at);
        },
      }
    ] as tTableColumns,
  }),
  async mounted(): Promise<void> {
    this.transactionList({
      user_id: this.$route.params.user_id,
      transaction_id: this.$route.params.transaction_id,
      query: `id_eq=${this.$route.params.transaction_id}`,
    });
  },
  computed: {
    ...mapGetters({
      getPaymentIntentList:
        `usersApp/transactions/paymentIntent/${paymentIntentType.getters.GET_PAYMENTS_INTENT_BY_TRANSACTION}`,
      getTransactionList: `usersApp/transactions/${transactionsCustomer.getters.GET_TRANSACTIONS_BY_CUSTOM_USER}`,
    }),
    successTransactions(): transactionsCustomerParams {
      const successTransactions = (
        this
          .getTransactionList as responseDataPagination<transactionsCustomerParams>
      ).data.find((trn) => trn.status === 'success');

      if (successTransactions) return successTransactions;

      return this.getTransactionList.data[0];
    },
  },
  methods: {
    ...mapActions({
      transactionList: `usersApp/transactions/${transactionsCustomer.actions.TRANSACTIONS_LIST_BY_CUSTOM_USER}`,
      paymentIntent: `usersApp/transactions/paymentIntent/${paymentIntentType.actions.PAYMENTS_INTENT_BY_TRANSACTION}`,
    }),
  },
});
