
























































import devicesUserCustomer from '@/store/types/devicesUserCustomer';
import {
  DeviceSessionsParams
} from '@/store/interfaces/devicesUserCustomer';
import DatesFormat from '@/util/classes/datesFormat';
import sessionActive from '@/components/Reusable/badgeRedGreen.vue';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
const formatDate = new DatesFormat();

export default Vue.extend({
  name: 'SessionDevices',
  components: { sessionActive, },
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'created_at',
        label: 'Fecha de creación',
        minWidth: 200,
        sortable: true,
        formatter: (row: DeviceSessionsParams) => {
          return formatDate.formatDateTime(row.created_at);
        },
      },
      {
        prop: 'expires_at',
        label: 'Fecha de expiración',
        minWidth: 200,
        sortable: true,
        formatter: (row: DeviceSessionsParams) => {
          return formatDate.formatDateTime(row.expires_at);
        },
      },
      {
        prop: 'revoked_at',
        label: 'Fecha de revocación',
        minWidth: 200,
        sortable: true,
        formatter: (row: DeviceSessionsParams) => {
          return formatDate.formatDateTime(row.revoked_at);
        },
      },
      {
        prop: 'status',
        label: 'Estado',
        minWidth: 150,
        sortable: true,
        slotName: 'status',
      }
    ] as tTableColumns,
  }),
  async mounted(): Promise<void> {
    await this.UserDevices({
      user_id: this.$route.params.user_id,
      query: `id_eq=${this.$route.params.device_id}`,
    });
  },
  computed: {
    ...mapGetters({
      getUserDevices: `usersApp/devices/${devicesUserCustomer.getters.GET_DEVICES_CUSTOMER_LIST}`,
      getSessions: `usersApp/devices/${devicesUserCustomer.getters.GET_SESSIONS_BY_DEVICE}`,
    }),
  },
  methods: {
    ...mapActions({
      sessionDevice: `usersApp/devices/${devicesUserCustomer.actions.SESSIONS_BY_DEVICE}`,
      UserDevices: `usersApp/devices/${devicesUserCustomer.actions.DEVICES_CUSTOMER_LIST}`,
    }),
    sessionEvaluation(session: DeviceSessionsParams): boolean {
      return (
        !!session.salt &&
      session.revoked_at == null &&
      this.expiredAtEvaluation(session.expires_at)
      );
    },

    expiredAtEvaluation(expired: string): boolean {
      return Date.parse(expired) < Date.parse(new Date().toDateString());
    },
  },
});

