import {
  StaffPermissionsParams,
  PermissionState
} from './../interfaces/staffPermissions';
import { responseCollection } from '@/store/interfaces/json-api/generics';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';

// Establece los types de los modulos store
import staffPermissionType from '@/store/types/staffPermissions';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';

const namespaced = true;

const initialState = (): PermissionState => {
  return {
    permissions: {
      data: [],
      pagination: {},
    },
  };
};
const state: PermissionState = initialState();
const getters: GetterTree<PermissionState, RootState> = {
  [staffPermissionType.getters.GET_PERMISSIONS_LIST]: state => {
    return state.permissions;
  },
};
const mutations: MutationTree<PermissionState> = {
  [staffPermissionType.mutations.SET_PERMISSIONS_LIST]: (state,
    permissions: responseCollection<StaffPermissionsParams>) => {
    state.permissions.data = permissions.data;
    state.permissions.pagination = permissions.meta.pagination;
  },
};
const actions: ActionTree<PermissionState, RootState> = {
  [staffPermissionType.actions.PERMISSIONS_LIST]: ({ commit, }, filter = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/permission_groups?kind_eq=${filter}`)
        .then((res: AxiosResponse) => {
          commit(staffPermissionType.mutations.SET_PERMISSIONS_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
