import crypto from 'crypto';

export const base64URLEncode = (str: Buffer): string => {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const sha256 = (buffer: string): Buffer => {
  return crypto.createHash('sha256').update(buffer).digest();
};

export const codeChallenge = (): string => {
  const verifier = base64URLEncode(crypto.randomBytes(32));
  return base64URLEncode(sha256(verifier));
};
