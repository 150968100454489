<template>
  <base-nav
    v-if="isAuth"
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Navbar links -->
    <b-navbar-nav class=" align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light'
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
            <i class="sidenav-toggler-line" />
          </div>
        </div>
      </li>
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in" />
        </a>
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a slot="title-container" href="#" class="nav-link pr-0" @click.prevent>
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" :src="unknowUser" />
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{
                `${currentUser.profile.first_name} ${currentUser.profile.last_name}`
              }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">¡Bienvenido!</h6>
          </b-dropdown-header>
          <b-dropdown-item @click="myProfile">
            <i class="ni ni-single-02" />
            <span>Mi perfil</span>
          </b-dropdown-item>
          <b-dropdown-item @click="config">
            <i class="fas fa-cog" />
            <span>Configuración</span>
          </b-dropdown-item>
          <b-dropdown-item @click="logout()">
            <i class="ni ni-user-run" />
            <span>Cerrar sesión</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { BaseNav } from '@/components';
import authType from '@/store/types/auth';
import { mapGetters } from 'vuex';
import unknowUser from '@/assets/images/unknow-user.png';
import Vue from 'vue';

export default Vue.extend({
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
  },
  computed: {
    routeName() {
      const { name, } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters({
      currentUser: `authModule/${authType.getters.GET_AUTH_USER}`,
      isAuth: `authModule/${authType.getters.IS_AUTHENTICATED}`,
    }),
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      unknowUser,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch(`authModule/${authType.actions.AUTH_LOGOUT}`);
      this.$router.push({
        name: 'Login',
      });
    },
    myProfile() {
      this.$router.push({
        name: 'my-profile',
      });
    },
    config() {
      this.$router.push({
        name: 'my-profile-config',
      });
    },
  },
});
</script>
