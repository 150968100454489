




















import updateImagesForm, {
  updateImageTypesRecieved
} from '@/components/Reusable/updateImagesForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'UpdateLogoBanner',
  components: {
    updateImagesForm,
  },
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
    success: {
      required: true,
      type: Boolean,
    },
    updateFor: {
      required: false,
      default: 'banner',
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    clean: false,
    processing: false,
    reloadImageForm: 1,
  }),
  computed: {
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(val: boolean): void {
        this.$emit('update:viewModal', val);
      },
    },
  },
  methods: {
    sendResourcesInfo(files: updateImageTypesRecieved): void {
      this.$emit('sources', files);
      this.syncedViewModal = false;
    },
    cancel(): void {
      this.syncedViewModal = false;
    },
  },
  watch: {
    success(val: boolean): void {
      if (val) {
        this.reloadImageForm++;
      }
    },
  },
});
