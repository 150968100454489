























































import { companyHost } from '@/const/companyHost';
import usersApp from '@/store/types/usersApp';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CreditCard from '../Reusable/CreditCard.vue';
import { UserCreditCard } from '@/store/interfaces/usersApp';
import ActionConfirm from '../modals/actionConfirm.vue';
import { TeipNotifications } from '@/util/teipNotifications';

export default Vue.extend({
  name: 'CardsUserApp',
  components: {
    CreditCard,
    ActionConfirm,
  },
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'network',
        label: 'Tarjeta',
        minWidth: 150,
        slotName: 'network',
        sortable: true,
      },
      {
        prop: 'status',
        label: 'Estado',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'discarded',
        label: 'Eliminada',
        minWidth: 150,
        sortable: true,
        formatter: (value: UserCreditCard) => {
          return value.discarded ? 'Sí' : 'No';
        },
      }
    ] as tTableColumns,
    cardSelected: {
      id: '',
      network: 'mastercard',
      reference_number: '',
      status: 'tryout',
      discarded: false,
    } as UserCreditCard,
    showConfirmModal: false,
  }),
  computed: {
    ...mapGetters({
      getUserCards: `usersApp/${usersApp.getters.GET_USER_CREDIT_CARDS}`,
    }),
    cardNotIsUnverifiable(): boolean {
      return this.cardSelected.status !== 'unverifiable';
    },
  },
  methods: {
    ...mapActions({
      UserCreditCard: `usersApp/${usersApp.actions.USER_CREDIT_CARDS}`,
      UserCreditCardResetVerification: `usersApp/${usersApp.actions.USER_CREDIT_CARDS_RESET_VERIFICATION}`,
    }),
    retryCardVerification() {
      this.showConfirmModal = true;
    },
    setCardSelected(card: UserCreditCard) {
      this.cardSelected = card;
    },
    async confirmResetCardVerification() {
      const notification = new TeipNotifications();
      try {
        await this.UserCreditCardResetVerification({
          user_id: this.$route.params.user_id,
          id: this.cardSelected.id,
        });
        notification.success('Verificación de tarjeta restablecida');
      } catch (error) {
        notification.warning(
          'Error al restablecer la verificación de la tarjeta'
        );
      }
      this.showConfirmModal = false;
    },
  },
});
