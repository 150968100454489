










import contacts from '@/components/Reusable/contacts.vue';
import contact from '@/store/types/contact';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BranchContact',
  components: {
    contacts,
  },
  computed: {
    ...mapGetters({
      getListContactByBranch: `contactModule/${contact.getters.GET_CONTACT_BY_BRANCHES}`,
    }),
  },
  methods: {
    ...mapActions({
      ListContactByBranch: `contactModule/${contact.actions.LIST_CONTACT_BY_BRANCH}`,
    }),
  },
});
