





























import tTransferTable from '@/components/Transfer/tTable.vue';
import transfer from '@/store/types/transfer';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Transfers',
  components: { tTransferTable, },
  data() {
    return {
      query: '',
    };
  },
  methods: {
    ...mapActions({
      transferList: `transfers/${transfer.actions.TRANSFER_LIST_BY_CUSTOMER_USER}`,
    }),
  },
  computed: {
    ...mapGetters({
      getTransferList: `transfers/${transfer.getters.GET_TRANSFER_BY_CUSTOMER_USER}`,
    }),
  },
});
