import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { teipTranslation } from './locales/teip';
import { companyHost } from '@/const/companyHost';
Vue.use(VueI18n);

const translationsMessage = JSON.parse(window.localStorage.getItem('translations') || '{}');

let messages = teipTranslation as LocaleMessages;

if (Object.keys(translationsMessage).length === 0) {
  messages[companyHost] = {
    menu: teipTranslation.teip.menu,
    commerce: teipTranslation.teip.commerce,
    company: teipTranslation.teip.company,
  };
} else {
  messages = translationsMessage;
}

export default new VueI18n({
  locale: companyHost,
  fallbackLocale: 'teip',
  silentFallbackWarn: true,
  messages,
});
