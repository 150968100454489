








import contacts from '@/components/Reusable/contacts.vue';
import contactType from '@/store/types/contact';

import companyType from '@/store/types/company';
import { navLink } from '@/ts/navegationLinks';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'ContactCompany',
  components: {
    contacts,
  },
  data: () => ({
    navlink: [
      {
        name: '', // assigned in created function
        active: false,
        to: '/commerce/company',
      },
      {
        name: 'Contactos',
        active: true,
      }
    ] as navLink[],
  }),
  created() {
    this.navlink[0].name = this.$t('menu.company.singular.formal').toString();
  },
  methods: {
    ...mapActions({
      ListContactByCompany: `contactModule/${contactType.actions.LIST_CONTACT_BY_COMPANY}`,
      singleCompanyAction: `companyModule/${companyType.actions.SINGLE_COMPANY}`,
    }),
  },
  computed: {
    ...mapGetters({
      getListContactByCompany: `contactModule/${contactType.getters.GET_CONTACT_BY_COMPANY}`,
      getSingleCompany: `companyModule/${companyType.getters.GET_SINGLE_COMPANY}`,
    }),
  },
});
