














/* eslint-disable */
import Vue from 'vue';

type keyValidator = {
  valid: string,
  invalid: string,
}

export default Vue.extend({
  name: 'BadgeRedGreen',
  props: {
    validation: {
      required: false,
      type: Object,
      default: () => {
        return { valid: 'Verificado', invalid: 'Sin verificar', };
      },
      validator: (value: keyValidator) => {
        const validKeys = ['valid', 'invalid'];

        const copyValue = Object.keys(value).slice();
        let isValid = true;
        for (const key of validKeys) {
          if (!copyValue.includes(key)) {
            // eslint-disable-next-line no-console
            console.warn(
              'Prop validator must be contain an object with key: valid and invalid'
            );
            isValid = false;
            break;
          }
        }
        return isValid;
      },
    },
    validated: {
      required: true,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
  },
});
