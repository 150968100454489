export default {
  mutations: {
    SET_USERS: 'SET_USERS',
    SET_SINGLE_USER: 'SET_SINGLE_USER',
    SET_USER_CREDIT_CARDS: 'SET_USER_CREDIT_CARDS',
    SET_USER_BALANCE: 'SET_USER_BALANCE',
    SET_NEW_VALUE_CARD_RESETED: 'SET_NEW_VALUE_CARD_RESETED',
  },
  getters: {
    GET_USERS: 'GET_USERS',
    GET_SINGLE_USER: 'GET_SINGLE_USER',
    GET_USER_BALANCE: 'GET_USER_BALANCE',
    GET_USER_CREDIT_CARDS: 'GET_USER_CREDIT_CARDS',
  },
  actions: {
    USERS_LIST: 'USERS_LIST',
    USER_CREDIT_CARDS: 'USER_CREDIT_CARDS',
    USER_CREDIT_CARDS_RESET_VERIFICATION: 'USER_CREDIT_CARDS_RESET_VERIFICATION',
    SINGLE_USER: 'SINGLE_USER',
    USER_BALANCE: 'USER_BALANCE',
  },
};
