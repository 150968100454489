




















































































import document from '@/store/types/document';
import deleteDocument from '@/components/modals/actionConfirm.vue';
import updateDocument from './modals/updateDocument.vue';
import updateImageDocument from './modals/updateDocumentImage.vue';
import uploadDocumentModal from './modals/uploadDocumentModal.vue';
import company from '@/store/types/company';
import { Route } from 'vue-router';
import contact from '@/store/types/contact';
import imagePreview from '@/components/Reusable/imagePreview.vue';
import { AxiosResponse } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import uploadimage from '@/store/types/uploadimage';
import { imageForEdit } from '@/ts/image';
import { BlobImage } from '@/store/interfaces/BlobImage';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

type updateDoc = {
  data: imageForEdit;
  document_id: string;
  url: string;
};

type tDocument = {
  company_id: string;
  document: string;
  document_kind_id: string;
  document_type_name: string;
  id: string;
  identity_code: string;
  reference: string;
  status: 'active' | 'inactive';
};

export default Vue.extend({
  name: 'CompanyDocuments',
  components: {
    uploadDocumentModal,
    updateDocument,
    imagePreview,
    deleteDocument,
    updateImageDocument,
  },
  data: () => ({
    showModalConfirm: false,
    showEditModal: false,
    showEditImageModal: false,
    showmodal: false,
    documents: [] as tDocument[],
    loading: true,
    contact_validate: false,
    contact_validate_message: '',
    title_message: '',
    documentFor: 'company',
    documentSelectedId: '',
    documentForEdit: {},
  }),
  async created(): Promise<void> {
    this.title_message = `de ${this.$t('menu.company.singular.informal')}`;
    if (this.getDocumentKindList.data.length === 0) {
      await this.listDocumentKind();
    }
    await this.getDocumentByUrl();

    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getDocumentKindList: `documentModule/${document.getters.GET_DOCUMENT_KIND}`,
      getSingleCompany: `companyModule/${company.getters.GET_SINGLE_COMPANY}`,
      getDocumentsImages: `documentModule/${document.getters.GET_DOCUMENTS_IMAGES}`,
      getSingleContact: `contactModule/${contact.getters.GET_SINGLE_CONTACT}`,
    }),
  },
  methods: {
    ...mapActions({
      listDocumentKind: `documentModule/${document.actions.LIST_DOCUMENT_KIND}`,
      updateDocumentCompanyContact: `documentModule/${document.actions.UPDATE_DOCUMENT_COMPANY_CONTACT}`,
      documentsByCompany: `documentModule/${document.actions.DOCUMENTS_BY_COMPANY}`,
      singleCompanyAction: `companyModule/${company.actions.SINGLE_COMPANY}`,
      deleteCompanyDocuments: `companyModule/${company.actions.DELETE_COMPANY_CONTACT_DOCUMENTS}`,
      documentsByContact: `documentModule/${document.actions.DOCUMENTS_BY_CONTACT}`,
      singleContact: `contactModule/${contact.actions.SINGLE_CONTACT}`,
      uploadImage: `uploadImage/${uploadimage.actions.UPLOAD_IMAGE}`,
      saveNewDocumentUpdated: `documentModule/${document.actions.SAVE_NEW_DOCUMENT_UPDATED}`,
    }),
    trash(id: string): void {
      this.$data.showModalConfirm = true;
      this.$data.documentSelectedId = id;
    },
    edit(id: string): void {
      this.$data.showEditModal = true;
      this.$data.documentForEdit = this.$data.documents.filter(
        (docu: imageForEdit) => docu.id === id
      )[0];
    },
    confirmRemoveDocument(): void {
      let referenceUrl = 'company_documents';
      if (this.$data.documentFor === 'contact') {
        referenceUrl = 'contact_documents';
      }
      const id = this.$data.documentSelectedId;
      const data = {
        id,
        referenceUrl,
      };
      this.deleteCompanyDocuments(data)
        .then(() => {
          this.$data.documents = this.$data.documents.filter(
            (value: imageForEdit) => value.id !== id
          );
          this.$data.showModalConfirm = false;
        })
        .catch(() => {
          const notification = new TeipNotifications();
          notification.warning('Upps!', 'Algo salió mal :(');
        });
    },
    async getDocumentByUrl(): Promise<void> {
      const contact_id = this.$route.params.contact_id;
      if (contact_id !== undefined) {
        await this.documentsByContact(contact_id);
      } else {
        await this.documentsByCompany(this.$route.params.company_id);
      }

      this.documents = this.getDocumentsImages.data.reverse();
    },
    async getDocuments(): Promise<void> {
      const contact_id = this.$route.params.contact_id;
      if (contact_id !== undefined) {
        this.documentFor = 'contact';
        await this.singleContact(contact_id);
        if (
          this.getSingleContact.contact_kind?.name !== 'Representante Legal'
        ) {
          this.contact_validate = false;
          this.contact_validate_message = `${this.getSingleContact.name} no es un contacto válido para documentos`;
        } else {
          this.contact_validate = true;
        }
        this.title_message = `de contacto: ${this.getSingleContact.name}`;
      } else {
        this.documentFor = 'company';
        this.title_message = ` de ${this.$t('menu.company.singular.informal')}`;
        this.contact_validate = true;
      }
    },
    updateDocument(dataToSend: updateDoc): void {
      this.updateDocumentCompanyContact(dataToSend).then(
        (result: AxiosResponse) => {
          this.updateRefDocument(result, dataToSend);
        }
      );
    },
    updateDocumentImage({
      uploadFile,
      dataToSend,
    }: {
      uploadFile: BlobImage;
      dataToSend: updateDoc;
    }): void {
      this.uploadImage(uploadFile)
        .then(() => {
          this.saveNewDocumentUpdated(dataToSend).then(
            (resultDocument: AxiosResponse) => {
              this.updateRefDocument(resultDocument, dataToSend);
            }
          );
        })
        .catch(() => {
          const notification = new TeipNotifications();
          notification.warning(
            'Algo ha salido mal al subir la imágen',
            'Inténtalo de nuevo más tarde.'
          );
        });
    },
    updateRefDocument(result: AxiosResponse, dataToSend: updateDoc): void {
      this.$data.showEditModal = false;
      this.$data.showEditImageModal = false;
      const post = this.$data.documents.findIndex(
        (docu: imageForEdit) => docu.id === dataToSend.document_id
      );
      this.$data.documents[post] = result.data.data;
    },
    async allDocuments(): Promise<void> {
      await this.getDocuments();
      const company_id = this.$route.params.company_id;
      if (
        Object.keys(this.getSingleCompany).length === 0 ||
        this.getSingleCompany.id !== company_id
      ) {
        await this.singleCompanyAction(company_id);
      }
    },
    updateImage(): void {
      this.$data.showEditModal = false;
      this.$data.showEditImageModal = true;
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler(val: Route): Promise<void> {
        if (
          val.name === 'company-documents' ||
          val.name === 'contacts-documents'
        ) {
          await this.getDocumentByUrl();
          await this.allDocuments();
        }
      },
    },
  },
});
