/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetterTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import rules from '@/store/types/rulesDiscountAndCashbacks';
import { RulesState, tRulesDiscountAndCashbacks } from '../interfaces/discountAndCashbackRules';

const namespaced = true;

const initialState = (): RulesState => {
  return {
    rulesValidated: [],
  };
};

const state: RulesState = initialState();
const getters: GetterTree<RulesState, any> = {
  [rules.getters.GET_RULES_VALIDATED]: (state) => {
    return state.rulesValidated;
  },
};
const mutations: MutationTree<RulesState> = {
  [rules.mutations.SET_RULES_VALIDATED]: (
    state,
    rule: tRulesDiscountAndCashbacks | tRulesDiscountAndCashbacks[]
  ) => {
    if (typeof rule === 'string') {
      state.rulesValidated.push(rule);

      if (rule === 'commerces') {
        state.rulesValidated.push('commerce_kinds');
        state.rulesValidated.push('branches');
      }

      if (rule === 'commerce_kinds') {
        state.rulesValidated.push('commerces');
        state.rulesValidated.push('branches');
      }

      if (rule === 'branches') {
        state.rulesValidated.push('commerces');
        state.rulesValidated.push('commerce_kinds');
      }

      return;
    }
    state.rulesValidated = rule;
  },
  [rules.mutations.SET_REMOVE_RULES_VALIDATES]: (
    state,
    rule: tRulesDiscountAndCashbacks
  ) => {
    state.rulesValidated = state.rulesValidated.filter(r => {
      const rules = [rule];

      if (rule === 'commerces') {
        rules.push('commerce_kinds');
        rules.push('branches');
      }
      if (rule === 'commerce_kinds') {
        rules.push('commerces');
        rules.push('branches');
      }

      if (rule === 'branches') {
        rules.push('commerces');
        rules.push('commerce_kinds');
      }

      return !rules.includes(r);
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
