import { checkPermissions } from '@/routes/routeValidation';
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { commerceKindsPermissions } from './permissions/index';
const CommerceKindList = () =>
  import(
    /* webpackChunkName: "list-commerce-kind" */
    '@/views/CommerceKind/list.vue'
  );

const commerceKindRoutes = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/commerce/category',
      component: CommerceKindList,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, commerceKindsPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default commerceKindRoutes;
