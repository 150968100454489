/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { checkPermissions } from '../routeValidation';
import { settlementsPermissions } from './permissions/index';

const LiquidationsReport = () =>
  import(
    /* webpackChunkName: "liquidations_liquidations_home" */
    '@/views/Liquidations/Reports.vue'
  );
const LiquidationsDetailReport = () =>
  import(
    /* webpackChunkName: "liquidations_liquidations_home" */
    '@/views/Liquidations/Liquidations.vue'
  );

const liquidationsPages = {
  path: '/',
  component: DashboardLayout,
  name: 'Liquidations',
  children: [
    {
      path: '/liquidations-report',
      name: 'transactions-report',
      component: LiquidationsReport,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, settlementsPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/liquidations-report/:id',
      name: 'liquidation-report-list',
      component: LiquidationsDetailReport,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_settlements_settlement');
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default liquidationsPages;
