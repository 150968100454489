var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":_vm.logo},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('sidebar-item',{directives:[{name:"can",rawName:"v-can:at-least-one",value:(_vm.transactionHistoryMonitorPermissions.concat( _vm.transactionHistoryPermissions,
          _vm.transactionHistoryNotAffiliatedPermissions,
          _vm.transferHistoryPermissions,
          ['transactions'] )),expression:"[\n          ...transactionHistoryMonitorPermissions,\n          ...transactionHistoryPermissions,\n          ...transactionHistoryNotAffiliatedPermissions,\n          ...transferHistoryPermissions,\n          'transactions',\n        ]",arg:"at-least-one"}],attrs:{"link":{
          name: _vm.$t('menu.transactions.plural.formal'),
          icon: 'ni ni-money-coins text-primary',
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.transactionHistoryMonitorPermissions),expression:"transactionHistoryMonitorPermissions"}],attrs:{"link":{ name: 'Monitor', path: '/transactions-tickets/monitor' }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.transactionHistoryPermissions),expression:"transactionHistoryPermissions"}],attrs:{"link":{
            // name: 'Historial Transacciones',
            name: ("Historial de " + (_vm.$t('menu.transactions.plural.informal'))),
            path: '/transactions-tickets/record',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.transactionHistoryNotAffiliatedPermissions),expression:"transactionHistoryNotAffiliatedPermissions"}],attrs:{"link":{
            name: 'Historial de no afiliados',
            path: '/transactions-tickets/affiliated',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.transferHistoryPermissions),expression:"transferHistoryPermissions"}],attrs:{"link":{
            name: 'Historial Transferencias',
            path: '/transfer/history',
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can:at-least-one",value:(_vm.liquidationPermissions.concat( _vm.settlementsPermissions,
          ['settlements'] )),expression:"[\n          ...liquidationPermissions,\n          ...settlementsPermissions,\n          'settlements',\n        ]",arg:"at-least-one"}],attrs:{"link":{
          name: 'Liquidaciones',
          icon: 'ni ni-ungroup text-orange',
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.settlementsPermissions),expression:"settlementsPermissions"}],attrs:{"link":{
            name: 'Reportes',
            path: '/liquidations-report',
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can:at-least-one",value:(_vm.companiesPermissions.concat( _vm.commercePermissions,
          _vm.draftCompanyPermissions,
          _vm.commerceKindsPermissions,
          ['commerces'] )),expression:"[\n          ...companiesPermissions,\n          ...commercePermissions,\n          ...draftCompanyPermissions,\n          ...commerceKindsPermissions,\n          'commerces',\n        ]",arg:"at-least-one"}],attrs:{"link":{
          name: _vm.$t('menu.commerce.plural.formal'),
          icon: 'ni ni-shop text-info',
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.companiesPermissions),expression:"companiesPermissions"}],attrs:{"link":{
            name: _vm.$t('menu.company.plural.formal'),
            path: '/commerce/company',
            exact: 'true',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.commercePermissions),expression:"commercePermissions"}],attrs:{"link":{
            name: _vm.$t('menu.commerce.plural.formal'),
            path: '/commerce/partners',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.draftCompanyPermissions),expression:"draftCompanyPermissions"}],attrs:{"link":{ name: 'Inbound', path: '/commerce/inbound' }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.commerceKindsPermissions),expression:"commerceKindsPermissions"}],attrs:{"link":{
            name: 'Categorías',
            path: '/commerce/category',
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.customerUserPermissions),expression:"customerUserPermissions"}],attrs:{"link":{
          name: 'Usuarios',
          icon: 'ni ni-circle-08 text-pink',
          path: '/accounts',
        }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.buyersPermissions),expression:"buyersPermissions"}],attrs:{"link":{
          name: 'Clientes',
          icon: 'ni ni-satisfied text-default',
          path: '/buyers',
        }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.ticketPermissions),expression:"ticketPermissions"}],attrs:{"link":{
          name: 'Soporte al cliente',
          icon: 'ni ni-vector text-red',
          path: '/customer-support',
        }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can:at-least-one",value:(_vm.staffPermissions.concat( _vm.permissionsOfPermissions,
          ['staff'] )),expression:"[\n          ...staffPermissions,\n          ...permissionsOfPermissions,\n          'staff',\n        ]",arg:"at-least-one"}],attrs:{"link":{
          name: 'Gestión de staff',
          icon: 'ni ni-badge text-info',
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.staffPermissions),expression:"staffPermissions"}],attrs:{"link":{
            name: 'Staff',
            path: '/staff',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.permissionsOfPermissions),expression:"permissionsOfPermissions"}],attrs:{"link":{
            name: 'Permisos',
            path: '/permisos',
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can:at-least-one",value:(_vm.discountPermissions.concat( _vm.cashbackPermissions,
          ['marketing'] )),expression:"[\n          ...discountPermissions,\n          ...cashbackPermissions,\n          'marketing',\n        ]",arg:"at-least-one"}],attrs:{"link":{
          name: 'Mercadeo',
          icon: 'ni ni-spaceship text-default',
        }}},[_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.discountPermissions),expression:"discountPermissions"}],attrs:{"link":{
            name: 'Descuentos',
            path: '/noveltiesAndPromotions/discounts',
          }}}),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.cashbackPermissions),expression:"cashbackPermissions"}],attrs:{"link":{
            name: 'Cashback',
            path: '/noveltiesAndPromotions/cashback',
          }}})],1),_c('sidebar-item',{directives:[{name:"can",rawName:"v-can",value:(_vm.withdrawalsPermissions),expression:"withdrawalsPermissions"}],attrs:{"link":{
          name: 'Retiros a cuentas bancarias',
          icon: 'ni ni-archive-2 text-pink',
          path: '/refund-by-transfer',
        }}})]},proxy:true},{key:"links-after",fn:function(){return [_c('small',{ref:"version",staticClass:"version"},[_vm._v("Versión: "+_vm._s(_vm.version))])]},proxy:true}])}),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }