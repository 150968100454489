/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';

import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { responseCollection } from '../interfaces/json-api/generics';
import { CashbackState, tCashbackParams } from '../interfaces/cashback';
import cashback from '../types/cashback';

const namespaced = true;

const initialState = (): CashbackState => {
  return {
    cashback: {
      data: [],
      pagination: {},
    },
  };
};

const state: CashbackState = initialState();

const getters: GetterTree<CashbackState, RootState> = {
  [cashback.getters.GET_CASHBACK]: state => {
    return state.cashback;
  },
};
const mutations: MutationTree<CashbackState> = {
  [cashback.mutations.SET_CASHBACK]: (state, states: responseCollection<tCashbackParams>) => {
    state.cashback.data = states.data;
    state.cashback.pagination = states.meta.pagination;
  },
};
const actions: ActionTree<CashbackState, RootState> = {
  [cashback.actions.CASHBACK_LIST]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('backoffice/cashbacks')
        .then((res: AxiosResponse) => {
          commit(cashback.mutations.SET_CASHBACK, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [cashback.actions.SAVE_CASHBACK]: (_, { data, }) => {
    return new Promise((resolve, reject) => {
      http
        .post(
          'backoffice/cashbacks',
          { data, }
        )
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [cashback.actions.DELETE_CASHBACK]: (_, id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/cashbacks/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [cashback.actions.UPDATE_CASHBACK]: (_, { id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/cashbacks/${id}`, { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [cashback.actions.SINGLE_CASHBACK]: (_, { id, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/cashbacks/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
