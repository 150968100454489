







































import { companyHost } from '@/const/companyHost';
import { BranchParams } from '@/store/interfaces/branchs';
import branchs from '@/store/types/branchs';
import { phoneFormat } from '@/util/phoneFormat';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BranchesCompany',
  data: () => ({
    companyHost,
    propsToSearch: [] as tPropsToSearch,
    tableColumns: [
      {
        prop: 'name',
        label: 'Nombre',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'address.description',
        label: 'Dirección',
        minWidth: 220,
        sortable: true,
      },
      {
        prop: 'address.phone',
        label: 'Teléfono',
        minWidth: 140,
        sortable: true,
        formatter: (value: BranchParams) => {
          return phoneFormat(value.address?.phone);
        },
      },
      {
        prop: 'contacts[0].name',
        label: 'Encargado',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'contacts[0].email',
        label: 'email',
        minWidth: 150,
        sortable: true,
      },
      {
        prop: 'contacts[0].phone',
        label: 'Teléfono encargado',
        minWidth: 250,
        sortable: true,
        formatter: (value: BranchParams) => {
          return phoneFormat(value.contacts[0]?.phone);
        },
      }
    ] as tTableColumns,
    rowSelected: {},
  }),
  computed: {
    ...mapGetters({
      getBranchesByCompany: `commerceModule/branches/${branchs.getters.GET_BRANCH_BY_COMPANY}`,
    }),
  },
  methods: {
    ...mapActions({
      branchesByCompany: `commerceModule/branches/${branchs.actions.BRANCHES_BY_COMPANY}`,
    }),
    rowselect(row: BranchParams): void {
      this.$data.rowSelected = row;
    },
    goProfile(): void {
      this.$router.push({
        name: 'users-branch-profile',
        params: {
          sucursal_id: this.$data.rowSelected.id,
          commerce_id: this.$data.rowSelected.commerce_id,
        },
      });
    },
  },
  created() {
    this.branchesByCompany({ company_id: this.$route.params.company_id, });
  },
});
