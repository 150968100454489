/* eslint-disable @typescript-eslint/no-var-requires */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import UserStaff from '@/views/UsersStaff/list.vue';
import UserStaffProfile from '@/views/UsersStaff/profile.vue';
import NewUserStaff from '@/views/UsersStaff/new-users.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { checkPermissions } from '../routeValidation';
import { staffPermissions } from './permissions/index';

const usersStaff = {
  path: '/',
  component: DashboardLayout,
  name: 'staff',
  children: [
    {
      path: '/staff/new',
      name: 'new-staff',
      component: NewUserStaff,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'create_staffs');
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/staff',
      name: 'staff-home',
      component: UserStaff,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, staffPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/staff/:user_id',
      component: UserStaffProfile,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, 'show_staffs');
      },
      meta: {
        navbarType: 'light',
      },
      children: [
        {
          name: 'user-profile-staff',
          path: '',
          component: require('@/components/Staff/profile.vue').default,
          meta: {
            navbarType: 'light',
          },
        },
        {
          name: 'user-staff-permissions',
          path: 'permissions',
          beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
            checkPermissions(to, from, next, 'index_permission_groups');
          },
          component: require('@/components/Staff/permissions.vue').default,
          meta: {
            navbarType: 'light',
          },
        }
      ],
    }
  ],
};

export default usersStaff;
