/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  CommerceState,
  CommerceCollection,
  CommerceParams,
  NewCommerceParams
} from '@/store/interfaces/commerce';
import commerceKindModule from '@/store/modules/childrens/commerceKind';
import branchModule from '@/store/modules/childrens/branchs';

// Establece los types de los modulos store
import commerceType from '@/store/types/commerce';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';

const namespaced = true;

const initialState = (): CommerceState => {
  return {
    commerces: {
      data: [],
      pagination: {},
    },
    singlecommerce: {},
    commerceBranchs: [],
    commerceByCompany: [],
    companyCommerceByCompany: [],
    newCommerceParams: {
      code: '',
      status: 'inactive',
      commerce_id: '',
      tip_percentage: '',
      commerce: {
        name: '',
        description: '',
        is_franchise: false,
        is_event: false,
        commerce_kind_id: '',
      },
    },
  };
};

const state: CommerceState = initialState();

const getters: GetterTree<CommerceState, any> = {
  [commerceType.getters.GET_COMMERCES]: state => {
    return state.commerces;
  },
  [commerceType.getters.GET_COMMERCE_BRANCHES]: (state) => {
    return { data: state.commerceBranchs, };
  },
  [commerceType.getters.GET_SINGLE_COMMERCE]: state => {
    return state.singlecommerce;
  },
  [commerceType.getters.GET_COMMERCES_BY_COMPANY]: state => {
    return state.commerceByCompany;
  },
  [commerceType.getters.GET_NEW_COMMERCE_PARAMS]: state => {
    return state.newCommerceParams;
  },
};
const mutations: MutationTree<CommerceState> = {
  [commerceType.mutations.SET_COMMERCES]: (
    state,
    commerces: CommerceCollection
  ) => {
    state.commerces.data = commerces.data;
    state.commerces.pagination = commerces.meta.pagination;
  },
  [commerceType.mutations.SET_COMMERCE_BRANCHES]: (state, commerceBranchs) => {
    state.commerceBranchs = commerceBranchs.data;
  },
  [commerceType.mutations.SET_COMMERCES_BY_COMPANY]: (
    state,
    commerces: Array<CommerceParams>
  ) => {
    state.commerceByCompany = commerces;
  },
  [commerceType.mutations.SET_COMPANY_COMMERCE_BY_COMMERCE_ID_LIST]: (
    state,
    companyCommerceByCompany: any
  ) => {
    state.companyCommerceByCompany = companyCommerceByCompany.data;
  },
  [commerceType.mutations.SET_NEW_COMMERCE_PARAMS]: (
    state,
    commerce: NewCommerceParams
  ) => {
    state.newCommerceParams = commerce;
  },
  [commerceType.mutations.SET_EMPTY_NEW_COMMERCE_PARAMS]: state => {
    // Reset Commerce
    state.newCommerceParams.commerce.is_franchise = false;
    state.newCommerceParams.commerce.is_event = false;
    state.newCommerceParams.tip_percentage = '';
    state.newCommerceParams.commerce_id = '';
    state.newCommerceParams.code = '';

    const black_list = ['is_franchise', 'is_event'];

    const keyCommerce = Object.keys(state.newCommerceParams.commerce);
    keyCommerce.forEach((val) => {
      if (!black_list.includes(val)) {
        state.newCommerceParams.commerce[val] = '';
      }
    });
  },
  [commerceType.mutations.SET_SINGLE_COMMERCE]: (
    state,
    commerce_id: string
  ) => {
    if (typeof commerce_id === 'string') {
      const commerce = state.commerces.data.filter(
        (commerce: CommerceParams) => commerce.id === commerce_id
      )[0];
      state.singlecommerce = commerce !== undefined ? commerce : {};
    } else {
      state.singlecommerce = commerce_id;
    }
  },
};

const actions: ActionTree<CommerceState, any> = {
  [commerceType.actions.SAVE_COMMERCE]: ({ commit, }, { company_id, data, }) => {
    const dataToSend = {
      data,
    };
    return new Promise((resolve, reject) => {
      http
        .post(
          `backoffice/companies/${company_id}/company_commerces`,
          dataToSend
        )
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_EMPTY_NEW_COMMERCE_PARAMS);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.COMMERCE_LIST]: (
    { commit, },
    queryParams = '250'
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerces?per_page=${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_COMMERCES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.COMMERCES_BY_COMPANY]: ({ commit, }, company_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/companies/${company_id}/commerces?per_page=100`)
        .then((res: AxiosResponse) => {
          commit(
            commerceType.mutations.SET_COMMERCES_BY_COMPANY,
            res.data.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.COMMERCE_BRANCH_LIST]: (
    { commit, },
    { id, queryParams, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerces/${id}/branches?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_COMMERCE_BRANCHES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.UPDATE_COMMERCE]: (
    { commit, },
    { commerce_id, data, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/commerces/${commerce_id}`, { data: data, })
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_SINGLE_COMMERCE, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [commerceType.actions.SINGLE_COMMERCE]: ({ commit, }, commerce_id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/commerces/${commerce_id}`)
        .then((res: AxiosResponse) => {
          commit(commerceType.mutations.SET_SINGLE_COMMERCE, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules: {
    commerceKind: commerceKindModule,
    branches: branchModule,
  },
};
