









import Vue from 'vue';

export default Vue.extend({
  name: 'DescriptionValue',
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: false,
      type: [String, Number],
      default: '',
    },
  },
});
