import {
  tNativeBillingTypes,
  ResourcesState, tNativeBankAccountTypes,
  tPaymentRequestStatuses,
  tTaxpayerTypes,
  tPaymentDistributionsKinds
} from '@/store/interfaces/resources';

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import resources from '@/store/types/resources';
import { AxiosError, AxiosResponse } from 'axios';
import { bankAccountType, billingType } from '@/util/transformString/translationsDecorator';
import { http } from '@/util/HTTP';
import { allowAny } from 'teip-components';
import { responseDataPagination } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): ResourcesState => {
  return {
    bankAccountTypes: [],
    billingTypes: [],
    paymentRequestStatuses: [],
    taxpayersTypes: [],
    distributionKinds: [],
  };
};

const state: ResourcesState = initialState();

const getters: GetterTree<ResourcesState, allowAny> = {
  [resources.getters.GET_PAYMENT_DISTRIBUTION_KINDS]: state => {
    return state.distributionKinds;
  },
  [resources.getters.GET_BANK_ACCOUNT_TYPES]: state => {
    return state.bankAccountTypes;
  },
  [resources.getters.GET_BILLING_TYPES]: state => {
    return state.billingTypes;
  },
  [resources.getters.GET_PAYMENT_REQUEST_STATUSES]: state => {
    return state.paymentRequestStatuses;
  },
  [resources.getters.GET_TAXPAYER_TYPES]: state => {
    return state.taxpayersTypes;
  },
};
const mutations: MutationTree<ResourcesState> = {
  [resources.mutations.SET_BILLING_TYPES]: (state, billing: { data: tNativeBillingTypes[] }) => {
    state.billingTypes = billing.data
      .map((bll: tNativeBillingTypes) => {
        return {
          name: billingType(bll.name),
          id: bll.name,
        };
      });
  },
  [resources.mutations.SET_BANK_ACCOUNT_TYPES]: (state, accountType: { data: tNativeBankAccountTypes[] }) => {
    state.bankAccountTypes = accountType.data
      .map((bll: tNativeBankAccountTypes) => {
        return {
          name: bankAccountType(bll.name),
          id: bll.name,
        };
      });
  },
  [resources.mutations.SET_PAYMENT_REQUEST_STATUSES]: (
    state,
    statuses: responseDataPagination<tPaymentRequestStatuses>
  ) => {
    state.paymentRequestStatuses = statuses.data;
  },
  [resources.mutations.SET_TAXPAYER_TYPES]: (
    state,
    statuses: responseDataPagination<tTaxpayerTypes>
  ) => {
    state.taxpayersTypes = statuses.data;
  },
  [resources.mutations.SET_PAYMENT_DISTRIBUTION_KINDS]: (
    state,
    statuses: responseDataPagination<tPaymentDistributionsKinds>
  ) => {
    state.distributionKinds = statuses.data;
  },
};
const actions: ActionTree<ResourcesState, allowAny> = {
  [resources.actions.BANK_ACCOUNT_TYPES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/bank_account_types')
        .then((res: AxiosResponse) => {
          commit(resources.mutations.SET_BANK_ACCOUNT_TYPES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [resources.actions.BILLING_TYPES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/billing_types')
        .then((res: AxiosResponse) => {
          commit(resources.mutations.SET_BILLING_TYPES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [resources.actions.PAYMENT_REQUEST_STATUSES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/enums/payment_request_statuses')
        .then((res: AxiosResponse) => {
          commit(resources.mutations.SET_PAYMENT_REQUEST_STATUSES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [resources.actions.PAYMENT_DISTRIBUTION_KINDS]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/enums/payment_distribution_kinds')
        .then((res: AxiosResponse) => {
          commit(resources.mutations.SET_PAYMENT_DISTRIBUTION_KINDS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [resources.actions.TAXPAYER_TYPES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/enums/company_taxpayer_types')
        .then((res: AxiosResponse) => {
          commit(resources.mutations.SET_TAXPAYER_TYPES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
