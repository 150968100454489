/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  TicketParams, CustomerSupportState
} from '@/store/interfaces/customerSupport';

import { RootState } from '@/store/interfaces/rootState';

// Establece los types de los modulos store
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import tickets from '../types/customerSupport';
import { responseCollection, responseSingle } from '../interfaces/json-api/generics';
const namespaced = true;

const initialState = (): CustomerSupportState => {
  return {
    tickets: {
      data: [],
      pagination: {},
    },
    filterBy: '',
    pagintationFor: '&page=1&per_page=10',
  };
};

const state: CustomerSupportState = initialState();

const getters: GetterTree<CustomerSupportState, RootState> = {
  [tickets.getters.GET_TICKET_LIST]: state => {
    return state.tickets;
  },
};
const mutations: MutationTree<CustomerSupportState> = {
  [tickets.mutations.SET_TICKET_LIST]: (
    state,
    tickets: responseCollection<TicketParams>
  ) => {
    state.tickets.data = tickets.data;
    state.tickets.pagination = tickets.meta.pagination;
  },
  [tickets.mutations.SET_CLEAN_FILTER]: state => {
    state.filterBy = '';
  },
  [tickets.mutations.SET_STATUS_TICKET]: (
    state,
    ticket: responseSingle<TicketParams>
  ) => {
    const position = state.tickets.data.findIndex(
      tic => tic.id === ticket.data.id
    );
    state.tickets.data[position].status = ticket.data.status;
    state.tickets.data[position].updated_at = ticket.data.updated_at;
  },
};
const actions: ActionTree<CustomerSupportState, RootState> = {
  [tickets.actions.TICKET_LIST]: ({ commit, state, }, queryParams: string) => {
    return new Promise((resolve, reject) => {
      if (
        queryParams &&
        !queryParams.includes('page') &&
        !queryParams.includes('per_page')
      ) {
        state.filterBy = queryParams;
      } else {
        if (queryParams) {
          state.pagintationFor = queryParams;
        } else {
          state.filterBy = '';
        }
      }
      const filterBy = state.filterBy.replace('?', '');
      let query = `${filterBy}${state.pagintationFor}`;
      if (query[0] === '&') {
        query = query.replace('&', '');
      }
      http
        .get(`backoffice/tickets?${query}`)
        .then((res: AxiosResponse) => {
          commit(tickets.mutations.SET_TICKET_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [tickets.actions.UPDATE_STATUS_TICKET]: ({ commit, }, { id, data, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/tickets/${id}`, { data, })
        .then((res: AxiosResponse) => {
          commit(tickets.mutations.SET_STATUS_TICKET, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
