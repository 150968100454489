export function statusColor(status: string): string {
  const statusParser: { [key: string]: string; } = {
    pending: 'bg-orange',
    success: 'bg-green',
    completed: 'bg-green',
    failure: 'bg-red',
    rejected: 'bg-red',
    cancelled: 'bg-blue',
    expired: 'bg-danger',
    active: 'bg-green',
    inactive: 'bg-red',
    created: 'bg-green',
    registered: 'bg-green',
    tryout: 'bg-blue',
    unverified: 'bg-orange',
    verifying: 'bg-blue',
    verified: 'bg-green',
    applied: 'bg-green',
  };
  return statusParser[status] ? statusParser[status] : 'bg-primary';
}
