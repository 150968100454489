































































import changeCommerceToCompanyModal from '@/components/commerce/Partners/modals/changeCommerceCompanyModal.vue';
import { CompanyCommerceParams } from '@/store/interfaces/company_commerce';
import company_commerce from '@/store/types/company_commerce';
import { tPropsToSearch, tTableColumns, tTeipTableRef } from 'teip-components';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import { responseDataPagination } from '@/store/interfaces/json-api/generics';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';
import { moneyCents } from '@/util/filters';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'CommerceCompany',
  components: {
    changeCommerceToCompanyModal,
    StatusBadges,
  },
  data() {
    return {
      moneyCents,
      companyHost,
      showModal: false,
      propsToSearch: [] as tPropsToSearch,
      tableColumns: [
        {
          prop: 'commerce.name',
          label: '', // assigned in created
          minWidth: 250,
          sortable: true,
        },
        {
          prop: 'commerce.commerce_kind.name',
          label: '', // assigned in created
          minWidth: 200,
          sortable: true,
        },
        {
          prop: 'status',
          label: 'Estado',
          minWidth: 150,
          sortable: true,
          slotName: 'status',
        },
        {
          prop: 'tip_percentage',
          label: 'Propina',
          minWidth: 150,
          sortable: true,
          formatter: (row: CompanyCommerceParams): string => {
            return `% ${row.tip_percentage}`;
          },
        },
        {
          prop: 'delivery_fee_cents',
          label: 'Delivery fee',
          minWidth: 150,
          sortable: true,
          slotName: 'delivery',
        },
        {
          prop: 'code',
          label: 'Código interno',
          minWidth: 150,
          sortable: true,
        }
      ] as tTableColumns,
      rowSelect: {
        status: 'inactive',
        tip_percentage: 0,
        delivery_fee_cents: 0,
        delivery_fee_curency: 'USD',
        branches_count: 0,
        commerce: {
          name: '',
          branches_count: 0,
          description: '',
          is_event: false,
          is_franchise: false,
          commerce_kind: { id: '', name: '', },
          logo_url: null,
          banner_url: null,
          merchant_logo_url: null,
          created_at: '',
          company_commerces_count: 0,
        },
        company_commerce_payment_gateway_param: null,
        liquidation_kind_slug: 'tradicional',
      } as CompanyCommerceParams,
    };
  },
  created() {
    this.tableColumns[0].label = `Nombre del ${this.$t(
      'menu.commerce.singular.informal'
    )}`;
    this.tableColumns[1].label = `Tipo de ${this.$t(
      'menu.commerce.singular.informal'
    )}`;

    if (!this.hostConfig.globals.tip) {
      this.tableColumns.splice(3, 1); // delete tip column
    }

    if (!this.hostConfig.globals.delivery_fee) {
      const findColumn = this.tableColumns.findIndex(
        (column) => column.prop === 'delivery_fee_cents'
      );
      this.tableColumns.splice(findColumn, 1);
    }

    if (!this.hostConfig.commerce.code) {
      const findColumn = this.tableColumns.findIndex(
        (column) => column.prop === 'code'
      );
      this.tableColumns.splice(findColumn, 1);
    }
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
      getCompanyCommerces: `companyCommerceModule/${company_commerce.getters.GET_COMPANY_COMMERCE_BY_COMPANY}`,
    }),
    dataCompanyCommerces(): responseDataPagination<CompanyCommerceParams> {
      if ('data' in this.getCompanyCommerces) {
        return this.getCompanyCommerces;
      }
      return { data: [], pagination: {}, };
    },
  },
  methods: {
    ...mapActions({
      commerceByCompany: `companyCommerceModule/${company_commerce.actions.COMPANY_COMMERCE_BY_COMPANY}`,
    }),
    ...mapMutations({
      setSingleCompanyCommerce: `companyCommerceModule/${company_commerce.mutations.SET_SINGLE_COMPANY_COMMERCE}`,
    }),
    updateIsSuccessful(): void {
      (this.$refs.commerceTable as tTeipTableRef).fetchData();
    },
    rowselect(row: CompanyCommerceParams): void {
      this.setSingleCompanyCommerce(row);
      this.rowSelect = row;
    },
    statusValue(status: string): boolean {
      return status === 'active';
    },
    viewProfile(): void {
      this.$router.push({
        path: `/commerce/partners/${this.rowSelect.commerce.id}`,
      });
    },
    newCommerce(): void {
      this.$router.push({
        name: 'new-commerce-from-company',
      });
    },
  },
});
