export default {
  mutations: {
    SET_DOCUMENT_KIND: 'SET_DOCUMENT_KIND',
    SET_DELETE_DOCUMENT_KIND: 'SET_DELETE_DOCUMENT_KIND',
    SET_UPDATE_DOCUMENT_KIND: 'SET_UPDATE_DOCUMENT_KIND',
    SET_SAVE_DOCUMENT_KIND: 'SET_SAVE_DOCUMENT_KIND',
    SET_DOCUMENTS_IMAGES: 'SET_DOCUMENTS_IMAGES',
    SET_SINGLE_DOCUMENT_IMAGE: 'SET_SINGLE_DOCUMENT_IMAGE',
  },
  getters: {
    GET_DOCUMENT_KIND: 'GET_DOCUMENT_KIND',
    GET_DOCUMENT_BY_COUNTRY: 'GET_DOCUMENT_BY_COUNTRY',
    GET_DOCUMENTS_IMAGES: 'GET_DOCUMENTS_IMAGES',
  },
  actions: {
    LIST_DOCUMENT_KIND: 'LIST_DOCUMENT_KIND',
    SAVE_DOCUMENT_KIND: 'SAVE_DOCUMENT_KIND',
    UPDATE_DOCUMENT_KIND: 'UPDATE_DOCUMENT_KIND',
    DELETE_DOCUMENT_KIND: 'DELETE_DOCUMENT_KIND',
    DOCUMENTS_BY_COMPANY: 'DOCUMENTS_BY_COMPANY',
    DOCUMENTS_BY_CONTACT: 'DOCUMENTS_BY_CONTACT',
    UPDATE_DOCUMENT_COMPANY_CONTACT: 'UPDATE_DOCUMENT_COMPANY_CONTACT',
    SAVE_NEW_DOCUMENT_UPDATED: 'SAVE_NEW_DOCUMENT_UPDATED',
  },
};
