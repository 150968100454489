









import flatPicker from 'vue-flatpickr-component';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import 'flatpickr/dist/flatpickr.css';
import Vue, { PropType } from 'vue';

type flatpickrConfig = {
  minDate: string;
};

export default Vue.extend({
  name: 'FlatPicker',
  components: {
    flatPicker,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Fecha',
    },
    value: {
      type: String,
      required: true,
    },
    conf: {
      type: Object as PropType<flatpickrConfig>,
      default: () => ({}),
    },
  },
  data() {
    return {
      config: {
        locale: Spanish,
      },
    };
  },
  computed: {
    syncedConf: {
      get(): flatpickrConfig {
        return this.$props.conf;
      },
      set(val: flatpickrConfig) {
        this.$emit('update:conf', val);
      },
    },
    inputValue: {
      get(): string {
        return this.$props.value;
      },
      set(val: string) {
        this.$emit('input', val);
      },
    },
  },
});
