












































import { companyProfile } from '@/store/interfaces/company';
import company from '@/store/types/company';
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AggregatorModel',
  props: {
    companiesProfile: {
      type: Array as PropType<companyProfile[]>,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getNewCompanyParams: `companyModule/${company.getters.GET_NEW_COMPANY_PARAMS}`,
    }),
  },
});
