export default {
  mutations: {
    SET_POS_LIST: 'SET_POS_LIST',
  },
  getters: {
    GET_POS_LIST: 'GET_POS_LIST',
  },
  actions: {
    POS_LIST: 'POS_LIST',
    SAVE_POS_USER: 'SAVE_POS_USER',
    UPDATE_POS_USER: 'UPDATE_POS_USER',
    REMOVE_POS_USER: 'REMOVE_POS_USER',
  },
};
