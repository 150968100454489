









































































































import DescriptionValue from '@/components/Reusable/descriptionValue.vue';
import drafts from '@/store/types/drafts';
import location from '@/store/types/country';
import { mapActions, mapGetters } from 'vuex';
import InboundCard from './inboundCard.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { InboundCard, DescriptionValue, },
  data() {
    return {
      isStateCountryLoading: true,
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      draftCompany: `drafts/${drafts.getters.GET_DRAFT_COMPANY}`,
    }),
    ownerOfCompany(): string {
      return this.draftCompany.company_kind === 'natural'
        ? 'propietario'
        : 'representante legal';
    },
  },

  async mounted(): Promise<void> {
    await this.ListState();
    this.isStateCountryLoading = false;
  },
  methods: {
    ...mapActions({
      ListState: `country/${location.actions.STATE_LIST}`,
    }),
    editThrows(value: boolean): void {
      this.edit = value;
    },
  },
});
