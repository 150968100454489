








import masterCard from '@/assets/images/mastercard.svg';
import visa from '@/assets/images/visa.svg';
import Vue from 'vue';

export default Vue.extend({
  name: 'CreditCard',
  props: {
    cardNetwork: {
      required: true,
      type: String,
    },
    cardLastDigits: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      visa,
      masterCard,
    };
  },
  computed: {
    creditCardTitle(): string {
      return this.cardLastDigits ? `****${this.cardLastDigits}` : '';
    },
  },
});
