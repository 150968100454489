/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Checksum, checksumDataGenerator } from '@/util/classes/Checksum';
import { AxiosResponse } from 'axios';
import uploadimageType from '@/store/types/uploadimage';
import { TeipNotifications } from '@/util/teipNotifications';
import { mapActions } from 'vuex';
import Vue from 'vue';
import { hosterButtonPrimary } from '@/util/hoster';

export default Vue.extend({
  props: {
    options: {
      type: Object,
    },
    id: {
      type: String,
    },
    clean: {
      type: Boolean,
    },
    url: {
      type: String,
      default: 'http://locahost:3000',
      required: false,
    },
    messageButton: {
      type: String,
      default: 'Click aqui para subir',
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    folder: {
      required: false,
      default: 'Documents',
      type: String,
    },
    active: {
      required: false,
      type: String,
      default: 'active',
    },
    previewClasses: {
      required: false,
      type: [String, Array],
    },
    imageSize: {
      required: false,
      type: Number,
      default: 3, // MB
    },
  },
  data: () => ({
    currentFile: null as File | null,
    files: [] as File[],
    showList: false,
    data: '',
    showReference: false,
    imageSizeSupport: 0, // bytes
    filesImageInfo: [] as File[],
    currentFileURL: '',
  }),
  methods: {
    ...mapActions({
      presignedUrl: `uploadImage/${uploadimageType.actions.PRESIGNED_URL}`,
    }),

    cleanImage(): void {
      this.$data.currentFile = null;
      this.$data.files = [];
      this.$data.showList = false;
      this.$data.showReference = false;
      this.$data.data = '';
      this.$data.references = '';
      const cleanView: any = document.getElementById(this.$props.id);
      if (cleanView) {
        const classes = 'dropzone mb-3 dz-clickable dropzone-single';
        cleanView.className = classes;
      }
    },

    getBase64ImageURL(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async initDropzone(): Promise<void> {
      let Dropzone: any = await import('dropzone');
      Dropzone = Dropzone.default;
      Dropzone.autoDiscover = false;
      const preview: any = this.$props.multiple
        ? this.$refs.previewMultiple
        : this.$refs.previewSingle;

      const previewTemplate =
        preview !== undefined ? preview.innerHTML : '';
      const self = this;
      const typeButton = `btn-${hosterButtonPrimary('primary')}`;

      const finalOptions = {
        ...this.$props.options,
        url: this.$props.url,
        dictDefaultMessage: this.$props.messageButton,
        thumbnailWidth: null,
        thumbnailHeight: null,
        autoProcessQueue: false,
        previewsContainer: preview,
        previewTemplate,
        maxFiles: !this.$props.multiple ? 1 : null,
        acceptedFiles: !this.$props.multiple ? 'image/*' : null,
        init: function () {
          const classButton: any = document.querySelectorAll('.dz-button');
          classButton.forEach((element: Element) => {
            element.classList.add('btn', typeButton, 'btn-sm');
          });
          this.on('addedfile', function (file: any) {
            self.$data.currentFile = file;
          });
          this.on('thumbnail', function (file: any) {
            if (file.size > self.$data.imageSizeSupport) {
              const notification = new TeipNotifications();
              notification.warning('Imágen demasiado grande; tamaño permitido ' + self.$props.imageSize + ' MB');
              self.cleanImage();
            }
          });
        },
      };
      const dropzone: any = new Dropzone(this.$el, finalOptions);
      // eslint-disable-next-line no-unused-expressions
      preview !== undefined ? preview.innerHTML = '' : '';
      const evtList = [
        'drop',
        'dragstart',
        'dragend',
        'dragenter',
        'dragover',
        'addedfile',
        'removedfile',
        'thumbnail',
        'error',
        'processing',
        'uploadprogress',
        'sending',
        'success',
        'complete',
        'canceled',
        'maxfilesreached',
        'maxfilesexceeded',
        'processingmultiple',
        'sendingmultiple',
        'successmultiple',
        'completemultiple',
        'canceledmultiple',
        'totaluploadprogress',
        'reset',
        'queuecomplete'
      ];
      evtList.forEach(evt => {
        dropzone.on(evt, (data: any) => {
          this.$emit(evt, data);
          if (evt === 'addedfile') {
            this.$data.files.push(data);
            this.$emit('change', this.$data.files);
          } else if (evt === 'removedfile') {
            const index = this.$data.files.findIndex(
              (f: any) => f.upload.uuid === data.upload.uuid
            );
            if (index !== -1) {
              this.$data.files.splice(index, 1);
            }
            this.$emit('change', this.$data.files);
          }
        });
      });
    },

  },
  mounted(): void {
    this.imageSizeSupport = 1024 * 1024 * this.imageSize;
    this.initDropzone();
  },
  watch: {
    clean(val: boolean): void {
      if (val) {
        this.cleanImage();
      }
    },
    currentFile(val: File): void {
      if (val != null && !this.$props.multiple) {
        this.$data.showReference = true;
        this.$emit('input', val);
        this.$data.currentFileURL = this.getBase64ImageURL(val);
        const checksumGenerate = new Checksum(val, this.$props.folder);
        if (val.size < this.$data.imageSizeSupport) {
          checksumGenerate.generateData().then((res: checksumDataGenerator) => {
            const data = {
              data: res,
            };
            this.presignedUrl(data).then((result: AxiosResponse) => {
              if (result.status === 200) {
                this.$data.data = result.data;
                this.$emit('result', result.data);
              }
            });
          });
        }
      }
    },
    files: {
      immediate: true,
      deep: true,
      handler(images: Array<File>): void {
        if (images.length > 0) {
          this.$data.filesImageInfo = [];
          images.map(async (image: File) => {
            const url = await this.getBase64ImageURL(image);
            const size = Number((image.size / 1000000).toFixed(2)) + ' MB';
            this.$data.filesImageInfo.unshift({
              name: image.name,
              size,
              url,
            });
          });
        }
      },
    },
  },
});
