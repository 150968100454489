export default {
  mutations: {
    SET_CASHBACK: 'SET_CASHBACK',
  },
  getters: {
    GET_CASHBACK: 'GET_CASHBACK',
  },
  actions: {
    CASHBACK_LIST: 'CASHBACK_LIST',
    SAVE_CASHBACK: 'SAVE_CASHBACK',
    UPDATE_CASHBACK: 'UPDATE_CASHBACK',
    SINGLE_CASHBACK: 'SINGLE_CASHBACK',
    DELETE_CASHBACK: 'DELETE_CASHBACK',
  },
};
