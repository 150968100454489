























import ShowBranchInfo from './profile/show.vue';
import EditBranchForm from './profile/editForm.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'BranchProfile',
  components: {
    ShowBranchInfo,
    EditBranchForm,
  },
  data: () => ({
    edit: false,
    active: false,
  }),
});
