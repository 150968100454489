export default {
  mutations: {
    SET_TRANSACTIONS_BY_CUSTOM_USER: 'SET_TRANSACTIONS_BY_CUSTOM_USER',
  },
  getters: {
    GET_TRANSACTIONS_BY_CUSTOM_USER: 'GET_TRANSACTIONS_BY_CUSTOM_USER',
  },
  actions: {
    TRANSACTIONS_LIST_BY_CUSTOM_USER: 'TRANSACTIONS_LIST_BY_CUSTOM_USER',
  },
};
