















































import {
  tPaymentDistributions,
  tPayTransactionInfo
} from '@/store/interfaces/transactions';
import CreditCard from '@/components/Reusable/CreditCard.vue';
import PaymentMethods from '@/components/Reusable/PaymentMethods.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'Pay',
  components: { CreditCard, PaymentMethods, },
  props: {
    pay: {
      required: true,
      type: Object as PropType<tPayTransactionInfo>,
    },
  },
  computed: {
    paymentDistributionsWithoutCreditCard(): tPaymentDistributions[] {
      if ('payment_distributions' in this.pay) {
        return this.pay.payment_distributions.filter(
          (distribution: tPaymentDistributions) =>
            distribution.kind !== 'credit_card'
        );
      }
      return [];
    },
    creditCardPaymentDistributions(): undefined | tPaymentDistributions {
      if ('payment_distributions' in this.pay) {
        return this.pay.payment_distributions.find(
          (distribution: tPaymentDistributions) =>
            distribution.kind === 'credit_card'
        );
      }
      return undefined;
    },
  },
});
