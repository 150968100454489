












































import { AxiosError } from 'axios';
import { TeipNotifications } from '@/util/teipNotifications';
import root from '@/store/types/root';
import company_commerce from '@/store/types/company_commerce';
import { currencyKeyboardEventValidation } from '@/util/CurrencyValidation';
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'UpdateTipPercentCompany',
  data: () => ({
    tip_percentage: 0,
    delivery_fee_cents: 0,
    currencyKeyboardEventValidation,
  }),
  created() {
    this.$data.tip_percentage = this.singleCompanyCommerce.tip_percentage;
    this.$data.delivery_fee_cents = this.singleCompanyCommerce.delivery_fee_cents / 100;
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
      getProcess: root.getters.GET_START_PROCESS,
      singleCompanyCommerce: `companyCommerceModule/${company_commerce.getters.GET_SINGLE_COMPANY_COMMERCE}`,
    }),
  },
  methods: {
    ...mapActions({
      updateCompanyCommerce: `companyCommerceModule/${company_commerce.actions.UPDATE_COMPANY_COMMERCE}`,
    }),
    updateTipPercent(): void {
      const data = {
        company_commerce_id: this.singleCompanyCommerce.id,
        data: {
          tip_percentage: this.$data.tip_percentage,
          delivery_fee_cents: this.$data.delivery_fee_cents * 100,
        },
      };

      this.updateCompanyCommerce(data)
        .then(() => {
          this.$emit('success', true);
        })
        .catch((err: AxiosError) => {
          const notification = new TeipNotifications();
          notification.warning('¡Error!', 'Algo ha salido mal :(');
          (this.$refs.formUpdateTip as InstanceType<typeof ValidationProvider>).setErrors(err.response?.data.error);
        });
    },
  },
});
