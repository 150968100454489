
export const phoneFormat = (phone: string | undefined | null): string => {
  const mask = process.env.VUE_APP_PHONE_MASK || '+503 #### ####';

  let index = 0;

  if (!phone) {
    return '';
  }

  return mask.split(' ').map(segment => {
    const part = phone.substring(index, index + segment.length);
    index += segment.length;
    return part;
  }).join(' ');
};
