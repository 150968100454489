












































import { AxiosError } from 'axios';
import bankAccount from '@/store/types/bankAccount';
import { TeipNotifications } from '@/util/teipNotifications';
import root from '@/store/types/root';
import { ValidationProvider } from 'vee-validate';
import formAccount from './formAccount.vue';
import { ContactParams } from '@/store/interfaces/contact';
import { mapActions, mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';
import { CompanyBankAccount } from '@/store/interfaces/company';

export type tModel = CompanyBankAccount & {
  contact_id: string;
};

export default Vue.extend({
  name: 'BankAccountModal',
  components: {
    formAccount,
  },
  props: {
    viewModal: {
      type: Boolean,
      required: true,
    },
    contacts: {
      type: Array as PropType<ContactParams[]>,
      required: true,
    },
  },
  data: (): {
    model: tModel;
  } => ({
    model: {
      bank_id: '',
      account_number: '',
      account_type: 'current',
      contact_id: '',
      billing_address: '',
      account_holder: '',
      billing_type: 'final_consumer',
    },
  }),
  methods: {
    ...mapActions({
      saveBankAccount: `companyModule/bankAccount/${bankAccount.actions.SAVE_BANK_ACCOUNT}`,
    }),
    cancelCreateBankAccount(): void {
      this.cleanInputs();
      this.$emit('update:viewModal', false);
      (
        this.$refs.formValidatorContact as InstanceType<
          typeof ValidationProvider
        >
      ).reset();
    },
    cleanInputs(): void {
      this.model.bank_id = '';
      this.model.account_number = '';
      this.model.account_type = 'current';
      this.model.contact_id = '';
      this.model.billing_address = '';
      this.model.account_holder = '';
      this.model.billing_type = 'final_consumer';
    },
    firstFormSubmit(): void {
      const notification = new TeipNotifications();
      const data = {
        company_id: this.$route.params.company_id,
        data: this.$data.model,
      };

      this.saveBankAccount(data)
        .then(() => {
          this.cancelCreateBankAccount();
        })
        .catch((err: AxiosError) => {
          if (err && err.response?.status === 422) {
            (
              this.$refs.formValidatorContact as InstanceType<
                typeof ValidationProvider
              >
            ).setErrors(err.response.data.error);
          } else {
            notification.warning('No hemos podido agregar la cuenta :(');
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
    }),
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean) {
        this.$emit('update:viewModal', value);
      },
    },
  },
});
