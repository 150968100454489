export default {
  mutations: {
    SET_TICKET_LIST: 'SET_TICKET_LIST',
    SET_CLEAN_FILTER: 'SET_CLEAN_FILTER',
    SET_STATUS_TICKET: 'SET_STATUS_TICKET',
  },
  getters: {
    GET_TICKET_LIST: 'GET_TICKET_LIST',
  },
  actions: {
    TICKET_LIST: 'TICKET_LIST',
    UPDATE_STATUS_TICKET: 'UPDATE_STATUS_TICKET',
  },
};
