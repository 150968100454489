import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import { WithdrawalsParams, WithdrawalsState } from '../interfaces/withdrawals';
import refundByTransfer from '../types/withdrawals';
import { responseCollection } from '../interfaces/json-api/generics';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';

const namespaced = true;

const initialState = (): WithdrawalsState => {
  return {
    reports: {
      data: [],
      pagination: {},
    },
    reportsByUser: {
      data: [],
      pagination: {},
    },
  };
};

const state: WithdrawalsState = initialState();

const getters: GetterTree<WithdrawalsState, RootState> = {
  [refundByTransfer.getters.GET_REFUND_BY_TRANSFER]: state => {
    return state.reports;
  },
  [refundByTransfer.getters.GET_REFUND_BY_USER_TRANSFER]: state => {
    return state.reportsByUser;
  },
};
const mutations: MutationTree<WithdrawalsState> = {
  [refundByTransfer.mutations.SET_REFUND_BY_TRANSFER]: (state, refunds: responseCollection<WithdrawalsParams>) => {
    state.reports.data = refunds.data;
    state.reports.pagination = refunds.meta.pagination;
  },
  [refundByTransfer.mutations.SET_REFUND_BY_USER_TRANSFER]:
    (state, refunds: responseCollection<WithdrawalsParams>) => {
      state.reportsByUser.data = refunds.data;
      state.reportsByUser.pagination = refunds.meta.pagination;
    },
};
const actions: ActionTree<WithdrawalsState, RootState> = {
  [refundByTransfer.actions.REFUND_BY_TRANSFER_LIST]: ({ commit, }, query = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/withdrawals?${query}`)
        .then((res: AxiosResponse) => {
          commit(refundByTransfer.mutations.SET_REFUND_BY_TRANSFER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [refundByTransfer.actions.REFUND_BY_TRANSFER_USER_LIST]: ({ commit, }, { user_id, query, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/customers/${user_id}/withdrawals?${query}`)
        .then((res: AxiosResponse) => {
          commit(refundByTransfer.mutations.SET_REFUND_BY_USER_TRANSFER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [refundByTransfer.actions.WITHDRAWALS_REFUND]: (_vuexInject, user_id) => {
    return new Promise((resolve, reject) => {
      http
        .post(`backoffice/withdrawals/${user_id}/refund`)
        .then((res: AxiosResponse) => {
          // commit(refundByTransfer.mutations.SET_REFUND_BY_USER_TRANSFER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
