











































































import { transferParams } from '@/store/interfaces/transfer';
import {
  tDeviceTransactionInfo,
  tPayTransactionInfo,
  tTransactionModal
} from '@/store/interfaces/transactions';
import Transaction from '../Reusable/Transaction.vue';
import Pay from './Detail/Pay.vue';
import Device from './Detail/Device.vue';
import transfer from '@/store/types/transfer';
import { TeipNotifications } from '@/util/teipNotifications';
import { mapActions, mapGetters } from 'vuex';
import Vue, { PropType } from 'vue';
import configurations from '@/store/types/configurations';

export default Vue.extend({
  name: 'TransferDetailModal',
  components: { Transaction, Pay, Device, },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    transfer: {
      required: true,
      type: Object as PropType<transferParams>,
    },
    withUser: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDownloadVoucher: false,
    };
  },
  computed: {
    ...mapGetters({
      hostConfig: `configurationModule/${configurations.getters.GET_HOST_CONFIGURATION}`,
    }),
    transaction(): tTransactionModal {
      return {
        authorization_number: this.transfer.authorization_number,
        reference_number: this.transfer.reference_number,
        risk: this.transfer.risk,
        status: this.transfer.status,
        response_code: this.transfer.response_code,
        distribution: this.transfer.distribution,
        card_last_digits: this.transfer.card_last_digits,
        card_network: this.transfer.card_network,
      };
    },
    pay(): tPayTransactionInfo {
      return {
        card_last_digits: this.transfer.card_last_digits,
        card_network: this.transfer.card_network,
        balance_amount_cents: this.transfer.balance_amount_cents,
        card_amount_cents: this.transfer.card_amount_cents,
        payment_distributions: [],
        balance_amount_formatted: this.transfer.balance_amount_formatted,
        card_amount_formatted: this.transfer.card_amount_formatted,
        total_formatted: this.transfer.amount_formatted,
      };
    },
    device(): tDeviceTransactionInfo {
      return {
        biometrics: this.transfer.biometrics,
        device_name: this.transfer.device_name,
        device_platform: this.transfer.device_platform,
        device_vendor: this.transfer.device_vendor,
        device_version: this.transfer.device_version,
      };
    },
    syncedShowModal: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
  },
  methods: {
    ...mapActions({
      downloadVoucherInPdf: `transfers/${transfer.actions.TRANSFER_VOUCHER_DOWNLOAD}`,
    }),
    async downloadVoucher() {
      this.isDownloadVoucher = true;
      const nameOfPdf = `voucher-${this.transfer.id}.pdf`;
      try {
        await this.downloadVoucherInPdf({
          id: this.transfer.id,
          name: nameOfPdf,
        });
      } catch (error) {
        const notification = new TeipNotifications();
        notification.warning(
          'No se pudo descargar el voucher, algo salió mal. :('
        );
      }
      this.isDownloadVoucher = false;
    },
  },
});
