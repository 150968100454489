export default {
  mutations: {
    SET_COMMERCE_KIND: 'SET_COMMERCE_KIND',
    SET_CURRENT_QUERY_STRING: 'SET_CURRENT_QUERY_STRING',
  },
  getters: {
    GET_COMMERCE_KIND: 'GET_COMMERCE_KIND',
  },
  actions: {
    LIST_COMMERCE_KIND: 'LIST_COMMERCE_KIND',
    DELETE_COMMERCE_KIND: 'DELETE_COMMERCE_KIND',
    SAVE_COMMERCE_KIND: 'SAVE_COMMERCE_KIND',
    UPDATE_COMMERCE_KIND: 'UPDATE_COMMERCE_KIND',
  },
};
