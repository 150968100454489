var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('div',[_c('b-row',[_c('b-col',[_c('stats-card',{attrs:{"title":"Transacciones éxitosas","type":"gradient-green","sub-title":("" + (_vm.getSingleUser.statistics.success_transactions_number)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_vm._v("\n                Última:\n                "+_vm._s(_vm.getSingleUser.statistics.last_success_transaction_at)+"\n              ")])])],2)],1),_c('b-col',[_c('stats-card',{attrs:{"title":"Transacciones fallidas","type":"gradient-red","sub-title":("" + (_vm.getSingleUser.statistics.failure_transactions_number)),"icon":"ni ni-active-40"}},[_c('template',{slot:"footer"},[_c('span',{staticClass:"text-nowrap"},[_vm._v("\n                Última:\n                "+_vm._s(_vm.getSingleUser.statistics.last_failure_transaction_at)+"\n              ")])])],2)],1)],1),_c('b-card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"md":"auto"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Transacciones")])])],1)],1),_c('teip-table',{attrs:{"props-to-search":_vm.propsToSearch,"table-columns":_vm.tableColumns,"host":_vm.companyHost,"table-data":_vm.getTransactionList.data,"fetch-callback":_vm.transactionList,"ids":{
            user_id: _vm.$route.params.user_id,
            transaction_id: _vm.$route.query.transaction_id,
          },"table-pagination":_vm.getTransactionList.pagination,"can-search":false,"table":"","trigger-drop-down":"hover","dropdown":true},on:{"row-select":_vm.transactionSelected},scopedSlots:_vm._u([{key:"status",fn:function(ref){
          var row = ref.row;
return [_c('StatusBadges',{attrs:{"status":row.status}})]}},{key:"card",fn:function(ref){
          var row = ref.row;
return [_c('PaymentMethods',{attrs:{"payment-methods":row.payment_distributions}})]}},{key:"dropdown",fn:function(ref){
          var row = ref.row;
return [_c('b-dropdown-item',{class:[_vm.isCash(row) ? 'payment-intent' : ''],on:{"click":function($event){return _vm.paymentIntentView(row)}}},[_c('i',{staticClass:"fas fa-eye"}),_vm._v("\n              Intentos de pago\n            ")])]}}])})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }