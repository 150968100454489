/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { checkPermissions } from '../routeValidation';
import { NavigationGuardNext, Route } from 'vue-router';
import {
  transactionHistoryMonitorPermissions,
  transactionHistoryNotAffiliatedPermissions,
  transactionHistoryPermissions,
  transferHistoryPermissions
} from './permissions/index';

const TransactionsTicketsMonitor = () =>
  import(
    /* webpackChunkName: "transastion_ticket_monitor" */
    '@/views/TransactionsTickets/Monitor/Home.vue'
  );
const TransactionsTicketsRecord = () =>
  import(
    /* webpackChunkName: "transastion_ticket_record" */
    '@/views/TransactionsTickets/Record/Home.vue'
  );

const transactionsTicketsPages = {
  path: '/',
  component: DashboardLayout,
  name: 'TransactionsTickets',
  children: [
    {
      path: '/transactions-tickets/monitor',
      name: 'transactionsTicketsMonitor',
      component: TransactionsTicketsMonitor,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, transactionHistoryMonitorPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/transactions-tickets/record',
      name: 'transactionsTicketsRecord',
      component: TransactionsTicketsRecord,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, transactionHistoryPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/transactions-tickets/affiliated',
      name: 'transferNoAffiliated',
      component: () =>
        import(
          /* webpackChunkName: "transastion_ticket_record" */
          '@/views/TransactionsTickets/NoAffiliated/Index.vue'
        ),
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, transactionHistoryNotAffiliatedPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/transfer/history',
      name: 'transferHistory',
      component: () =>
        import(
          /* webpackChunkName: "transastion_ticket_record" */
          '@/views/TransactionsTickets/Transfer.vue'
        ),
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
        checkPermissions(to, from, next, transferHistoryPermissions);
      },
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default transactionsTicketsPages;
