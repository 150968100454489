


















































import { posParams } from '@/store/interfaces/pos';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'PosUserModal',
  props: {
    viewModal: {
      required: true,
      type: Boolean,
    },
    pos: {
      required: true,
      type: Object as PropType<posParams>,
    },
  },
  computed: {
    syncedViewModal: {
      get(): boolean {
        return this.viewModal;
      },
      set(value: boolean): void {
        this.$emit('update:viewModal', value);
      },
    },
  },
  methods: {
    close(): void {
      this.syncedViewModal = false;
    },
  },
});

