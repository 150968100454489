











import Vue, { PropType } from 'vue';

import { tPaymentDistributions } from '@/store/interfaces/transactions';
import CreditCard from './CreditCard.vue';

export default Vue.extend({
  name: 'PaymentMethods',
  components: {
    CreditCard,
  },
  props: {
    paymentMethods: {
      required: true,
      type: Array as PropType<tPaymentDistributions[]>,
    },
  },
  computed: {
    creditCard(): undefined | tPaymentDistributions {
      return this.paymentMethods.find(method => method.kind === 'credit_card');
    },
    activePaymentMethods(): string {
      const withoutCreditCard = this.paymentMethods.filter(
        method => method.kind !== 'credit_card'
      );

      if (withoutCreditCard.length === 0) return '';

      if (withoutCreditCard.length === 1) {
        if (this.creditCard) {
          return `y ${withoutCreditCard[0].label}`;
        }
        return withoutCreditCard[0].label;
      }

      const lastMethod = withoutCreditCard.pop();

      return (
        withoutCreditCard.map(method => method.label).join(', ') +
        ` y ${lastMethod?.label}`
      );
    },
  },
});
