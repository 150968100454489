
























































































import userStaffType from '@/store/types/userStaff';
import staffPermissionType from '@/store/types/staffPermissions';
import Fuse from 'fuse.js';

import SweetAlert from '@/util/classes/SweetAlert';
import { TeipNotifications } from '@/util/teipNotifications';
import {
  PermissionsGroup
} from '@/store/interfaces/staffUser';
import { PermissionGroupParams } from '@/store/interfaces/PermissionGroup';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';

declare type permission = {
  selected: boolean;
  value: string
}

export default Vue.extend({
  name: 'PermissionsListForm',
  props: {
    canUpdate: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: () => ({
    search: '',
    filter: '',
    messageToSearch: false,
    permissionlist: [],
    loading: true,
    userPermissionids: [] as permission[],
    fuseSearch: [],
    optionTofilter: [
      {
        id: 'all',
        value: 'Todos',
      },
      {
        id: 'no-asignados',
        value: 'No asignados',
      },
      {
        id: 'asignados',
        value: 'Asignados',
      }
    ],
  }),
  computed: {
    ...mapGetters({
      userStaff: `userStaff/${userStaffType.getters.GET_SINGLE_USER_STAFF}`,
      getPermissionsList: `staffPermissions/${staffPermissionType.getters.GET_PERMISSIONS_LIST}`,
    }),
  },
  methods: {
    ...mapActions({
      PermissionsList: `staffPermissions/${staffPermissionType.actions.PERMISSIONS_LIST}`,
      updateUserStaff: `userStaff/${userStaffType.actions.UPDATE_USER_STAFF}`,
    }),

    searchPermissionList(booleanSearch: boolean): void {
      this.setPermissionList();
      const permission: permission[] = this.$data.userPermissionids.filter(
        (filter: permission) => filter.selected === booleanSearch
      );

      const permissionlist: PermissionGroupParams[] = [];
      this.$data.permissionlist.data.forEach((element: PermissionGroupParams) => {
        const permissionGroup = permission.find(
          (fil: permission) => fil.value === element.id
        );
        if (permissionGroup !== undefined) {
          permissionlist.push(element);
        }
      });
      this.$data.permissionlist.data = permissionlist;
    },

    setPermissionList(): void {
    // clone all information of the getPermissionList, but without reactivity of getPermissionsList
      this.$data.permissionlist = JSON.parse(
        JSON.stringify(this.getPermissionsList)
      );

      // Set value selected for switch in html
      const permissionsId: permission[] = [];
      this.$data.permissionlist.data.forEach((element: PermissionGroupParams, index: number) => {
        this.$data.permissionlist.data[index].position = index;

        const permissionGroup = this.userStaff.permission_groups.find(
          (fil: PermissionsGroup) => fil.id === element.id
        );
        const perm = {
          selected: false,
          value: String(element.id),
        };
        if (permissionGroup !== undefined) {
          perm.selected = true;
          permissionsId.push(perm);
        } else {
          permissionsId.push(perm);
        }
      });

      this.$data.userPermissionids = permissionsId;

      this.$data.fuseSearch = new Fuse(this.$data.permissionlist.data, {
        keys: ['name', 'permissions.name'],
        threshold: 0.3,
      });
    },

    updatePermissions(): void {
      const notif = new TeipNotifications();
      const permission_group_ids: Array<string> = [];
      this.$data.userPermissionids.forEach((element: permission) => {
        if (element.selected) {
          permission_group_ids.push(element.value);
        }
      });

      const data = {
        user_id: this.userStaff.id,
        data: {
          permission_group_ids,
        },
      };
      const swal = new SweetAlert();
      swal
        .askingSweetAlert({
          title: '¿Actualizar permisos?',
          text: 'Con está acción das permisos',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sí, actualizar',
          alertType: 'success',
        })
        .then((res) => {
          if (res) {
            this.updateUserStaff(data)
              .then(() => {
                notif.success('Actualizado correctamente');
              })
              .catch(() => {
                notif.warning('Error', 'No hemos podido actualizar permisos');
              });
          } else {
            this.setPermissionList();
          }
        });
    },
  },

  async mounted(): Promise<void> {
    await this.PermissionsList('staff');
    this.$data.loading = false;
  },
  watch: {
    filter(val: string) {
      switch (val) {
      case 'no-asignados':
        this.searchPermissionList(false);
        break;
      case 'asignados':
        this.searchPermissionList(true);
        break;
      default:
        this.setPermissionList();
        break;
      }
    },
    search(val: string) {
      setTimeout(() => {
        this.setPermissionList();
        this.$data.messageToSearch = false;
        if (val.trim()) {
          this.$data.permissionlist.data = this.$data.fuseSearch.search(val.trim());
          if (this.$data.permissionlist.data.length === 0) {
            this.$data.messageToSearch = true;
          }
        }
      }, 500);
    },
  },
});
