








































import uploadLogo from '@/components/Reusable/uploadLogo.vue';
import { BlobImage } from '@/store/interfaces/BlobImage';
import Vue from 'vue';

export type updateImageTypesRecieved = {
  infoFile: BlobImage | null
  file: File | null
}

export default Vue.extend({
  name: 'UpdateImageFor',
  components: {
    uploadLogo,
  },
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    clean: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    sourceName: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      infoFile: null,
      file: null,
    };
  },
  methods: {
    firstFormSubmit() {
      this.$emit('submit', {
        ...this.$data,
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    setInfoFile(file: File) {
      this.$data.infoFile = file;
    },
    ucfirst(value: string) {
      return value
        .charAt(0)
        .toUpperCase()
        .concat(value.substring(1, value.length));
    },
  },
});
