export default {
  mutations: {
    SET_BUYERS: 'SET_BUYERS',
  },
  getters: {
    GET_BUYERS: 'GET_BUYERS',
  },
  actions: {
    BUYERS_LIST: 'BUYERS_LIST',
  },
};
