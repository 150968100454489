import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  BannerState,
  BannerParams
} from '@/store/interfaces/banner';
// Establece los types de los modulos store
import bannerType from '@/store/types/banner';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../interfaces/rootState';
import { responseCollection, responseSingle } from '@/store/interfaces/json-api/generics';

const namespaced = true;

const initialState = (): BannerState => {
  return {
    banners: {
      data: [],
      pagination: {},
    },
    banner: {
      id: '',
      title: '',
      headline: '',
      description: '',
      link: '',
      start_at: '',
      end_at: '',
      image: '',
      range: '',
    },
  };
};

const state: BannerState = initialState();
const getters: GetterTree<BannerState, RootState> = {
  [bannerType.getters.GET_BANNERS]: (state) => {
    return state.banners;
  },
  [bannerType.getters.GET_BANNER]: state => {
    return state.banner;
  },
  [bannerType.getters.GET_NEW_BANNER_SAVED]: state => {
    return state.banner;
  },
};
const mutations: MutationTree<BannerState> = {
  [bannerType.mutations.SET_BANNERS]: (
    state,
    banners: responseCollection<BannerParams>
  ) => {
    state.banners.data = banners.data;
    state.banners.pagination = banners.meta.pagination;
  },
  [bannerType.mutations.SET_BANNER]: (
    state,
    banner: responseSingle<BannerParams>
  ) => {
    state.banner = banner.data;
  },
};
const actions: ActionTree<BannerState, RootState> = {
  [bannerType.actions.SAVE_BANNER]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/banners', { data, })
        .then((res: AxiosResponse) => {
          commit(bannerType.mutations.SET_BANNER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bannerType.actions.EDIT_BANNER]: ({ commit, }, data: BannerParams) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/banners/${data.id}`, { data, })
        .then((res: AxiosResponse) => {
          commit(bannerType.mutations.SET_BANNER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bannerType.actions.DELETE_BANNER]: (_, data: BannerParams) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/banners/${data.id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bannerType.actions.BANNER_LIST]: ({ commit, }, queryParams) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/banners?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(bannerType.mutations.SET_BANNERS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [bannerType.actions.BANNER]: ({ commit, }, id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/banners/${id}`)
        .then((res: AxiosResponse) => {
          commit(bannerType.mutations.SET_BANNER, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
