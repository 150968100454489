
















































import usersApp from '@/store/types/usersApp';
import { tPropsToSearch, tTableColumns } from 'teip-components';
import StatusBadges from '@/components/Reusable/statusBadges.vue';
import { WithdrawalsParams } from '@/store/interfaces/withdrawals';
import refundByTransfer from '@/store/types/withdrawals';
import DatesFormat from '@/util/classes/datesFormat';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { companyHost } from '@/const/companyHost';

const formatDate = new DatesFormat();

export default Vue.extend({
  components: {
    StatusBadges,
  },
  data() {
    return {
      companyHost,
      propsToSearch: [] as tPropsToSearch,
      tableColumns: [
        {
          prop: 'name',
          label: 'Nombre',
          minWidth: 250,
          formatter: (row: WithdrawalsParams): string => {
            return `${row.user_first_name} ${row.user_last_name}`;
          },
        },
        {
          prop: 'user_email',
          label: 'Correo',
          minWidth: 200,
          sortable: true,
        },
        {
          prop: 'total_formatted',
          label: 'Monto retirado',
          minWidth: 150,
        },
        {
          prop: 'status',
          label: 'Estado',
          minWidth: 150,
          slotName: 'status',
        },
        {
          prop: 'bank_account_number',
          label: 'Cuenta bancaria',
          minWidth: 200,
          sortable: true,
        },
        {
          prop: 'bank_name',
          label: 'Banco',
          minWidth: 150,
          sortable: true,
        },
        {
          prop: 'created_at',
          label: 'Fecha y Hora',
          minWidth: 180,
          sortable: true,
          formatter: (row: WithdrawalsParams): string => {
            return `${formatDate.formatDateTime(row.created_at)}`;
          },
        }
      ] as tTableColumns,
      withdrawal: {
        id: '',
        reference_number: '',
        account_id: '',
        biometrics: false,
        amount_formatted: '',
        fee_formatted: '',
        total_formatted: '',
        status: 'pending',
        created_at: '',
        bank_account_alias: '',
        bank_account_type: 'current',
        bank_account_number: '',
        bank_account_holder_first_name: '',
        bank_account_holder_last_name: '',
        bank_account_document_number: '',
        bank_name: '',
        bank_logo_url: '',
        user_id: '',
        user_email: '',
        user_first_name: '',
        user_last_name: '',
        user_handle: '',
        user_avatar_url: '',
        device_name: '',
        device_platform: '',
        device_version: '',
        device_vendor: '',
      } as WithdrawalsParams,
    };
  },
  methods: {
    ...mapActions({
      transactionList: `withdrawalsModule/${refundByTransfer.actions.REFUND_BY_TRANSFER_USER_LIST}`,
    }),
    drawalsSelected(row: WithdrawalsParams): void {
      this.withdrawal = row;
    },
  },
  computed: {
    ...mapGetters({
      getSingleUser: `usersApp/${usersApp.getters.GET_SINGLE_USER}`,
      getRefundByTransfer: `withdrawalsModule/${refundByTransfer.getters.GET_REFUND_BY_USER_TRANSFER}`,
    }),
  },
});

