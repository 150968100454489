






















import Vue, { PropType } from 'vue';
export type tNavLink = string | { name: string; active: boolean; to: string }[];

export default Vue.extend({
  name: 'HeadBaseLink',
  props: {
    navLink: {
      required: true,
      type: [String, Array] as PropType<tNavLink>,
    },
  },
  data() {
    return {
      hostCompany: `${process.env.VUE_APP_COMPANY_HOST}`,
    };
  },
  computed: {
    navLinkString(): string {
      return typeof this.navLink === 'string' ? this.navLink : '';
    },
    navLinkArray(): { name: string; active: boolean; to: string }[] {
      return typeof this.navLink === 'object' ? this.navLink : [];
    },
  },
});
