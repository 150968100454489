import { newCommerceKindType } from '@/store/interfaces/BlobImage';
import uploadimage from '@/store/types/uploadimage';
import { Dispatch } from 'vuex';

const uploadIcon = async (commerce_kind_id: string, commerceKind: newCommerceKindType, dispatch: Dispatch)
: Promise<void> => {
  const image = {
    data: commerceKind.icon,
    currentFile: commerceKind.iconFile,
  };
  const updateIcon = {
    data: {
      icon: commerceKind.icon.blob_signed_id,
    },
    commerce_kind_id,
  };

  await dispatch(`uploadImage/${uploadimage.actions.UPLOAD_IMAGE}`, image, { root: true, });
  await dispatch(`uploadImage/${uploadimage.actions.REGISTER_ICON_COMMERCE_KIND}`,
    updateIcon, { root: true, });
};

export default uploadIcon;
