import {
  DiscountState,
  DiscountParams
} from './../interfaces/discount';
import { responseCollection } from './../interfaces/json-api/generics';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { GetterTree, ActionTree, MutationTree } from 'vuex';
// Establece los types de los modulos store
import discountType from '@/store/types/discount';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';

const namespaced = true;

const initialState = (): DiscountState => {
  return {
    discounts: {
      data: [],
      pagination: {},
    },
  };
};

const state: DiscountState = initialState();
const getters: GetterTree<DiscountState, any> = {
  [discountType.getters.GET_DISCOUNTS]: (state) => {
    return state.discounts;
  },
};
const mutations: MutationTree<DiscountState> = {
  [discountType.mutations.SET_DISCOUNTS]: (
    state,
    discounts: responseCollection<DiscountParams>
  ) => {
    state.discounts.data = discounts.data;
    state.discounts.pagination = discounts.meta.pagination;
  },
};
const actions: ActionTree<DiscountState, any> = {
  [discountType.actions.SAVE_DISCOUNT]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('backoffice/discounts', { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [discountType.actions.EDIT_DISCOUNT]: (_, data: DiscountParams) => {
    return new Promise((resolve, reject) => {
      http
        .put(`backoffice/discounts/${data.id}`, { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [discountType.actions.DELETE_DISCOUNT]: (_, data: DiscountParams) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`backoffice/discounts/${data.id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [discountType.actions.DISCOUNT_LIST]: ({ commit, }, queryParams) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/discounts?${queryParams}`)
        .then((res: AxiosResponse) => {
          commit(discountType.mutations.SET_DISCOUNTS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [discountType.actions.DISCOUNT]: (_, id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/discounts/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
