import axios, { AxiosResponse } from 'axios';
import store from '@/store';
import RootTypes from '@/store/types/root';

const URL: string | undefined = process.env.VUE_APP_URL_API;

const http = axios.create();
// Add a request interceptor
http.interceptors.request.use(
  config => {
    // Do something before request is sent
    store.commit(RootTypes.mutations.SET_START_PROCESS);
    return config;
  },
  error => {
    // Do something with request error
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return response;
  },
  error => {
    // Do something with response error
    store.commit(RootTypes.mutations.SET_END_PROCESS);
    return Promise.reject(error);
  }
);

export { http, URL };
