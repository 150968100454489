export const teipTranslation = {
  teip: {
    menu: {
      company: {
        plural: {
          formal: 'Empresas',
          informal: 'empresas',
        },
        singular: {
          formal: 'Empresa',
          informal: 'empresa',
        },
      },
      commerce: {
        plural: {
          formal: 'Comercios',
          informal: 'comercios',
        },
        singular: {
          formal: 'Comercio',
          informal: 'comercio',
        },
      },
      transactions: {
        plural: {
          formal: 'Transacciones',
          informal: 'transacciones',
        },
        singular: {
          formal: 'Transacción',
          informal: 'transacción',
        },
      },
    },
    company: {
      commerce: {
        profile: {
          heading: {
            company: '@:menu.company.plural.formal',
          },
        },
      },
      header: {
        tabSelect: '@:menu.company.plural.formal',
        currentPage: '@:menu.company.singular.formal',
        navLink: '@:menu.company.singular.formal',
        newCompanyButton: 'Nueva @:menu.company.singular.formal',
      },
      table: {
        bussinessName: '@:menu.company.plural.formal',
        title: '@:menu.company.plural.formal',
        input: {
          search: 'Búsqueda por @:menu.company.singular.formal',
        },
        options: {
          add: 'Agregar @:menu.commerce.singular.formal',
        },
      },
    },
    commerce: {
      header: {
        tabSelect: '@:menu.commerce.plural.formal',
        currentPage: '@:menu.commerce.singular.formal',
        navLink: '@:menu.commerce.plural.formal afiliados',
        newCommerceButton: 'Nuevo @:menu.commerce.singular.formal',
      },
      table: {
        bussinessName: '@:menu.commerce.plural.formal',
        title: '@:menu.commerce.plural.formal',
        input: {
          search: 'Búsqueda por @:menu.commerce.singular.formal',
        },
      },
    },
  },
};
