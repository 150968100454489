export default {
  mutations: {
    SET_SETTLEMENTS: 'SET_SETTLEMENTS',
    SET_LIQUIDATIONS_BY_SETTLEMENTS: 'SET_LIQUIDATIONS_BY_SETTLEMENTS',
  },
  getters: {
    GET_SETTLEMENTS: 'GET_SETTLEMENTS',
    GET_LIQUIDATIONS_BY_SETTLEMENTS: 'GET_LIQUIDATIONS_BY_SETTLEMENTS',
  },
  actions: {
    SETTLEMENTS: 'SETTLEMENTS',
    LIQUIDATIONS_BY_SETTLEMENTS: 'LIQUIDATIONS_BY_SETTLEMENTS',
  },
};
