export function status(status: string): string {
  const statusParser: { [key: string]: string; } = {
    pending: 'Pendiente',
    success: 'Completada',
    failure: 'Fallo',
    rejected: 'Rechazada',
    cancelled: 'Cancelado',
    expired: 'Expirado',
    active: 'Activo',
    inactive: 'Inactivo',
    completed: 'Completado',
  };
  return statusParser[status] ? statusParser[status] : 'No definido';
}

export function ticketStatus(status: string): string {
  const ticketParser: { [key: string]: string; } = {
    pending: 'Pendiente',
    completed: 'Completada',
    cancelled: 'Cancelada',
  };
  return ticketParser[status] ? ticketParser[status] : 'No definido';
}

export function ticketableType(ticket: string): string {
  const ticketableTypeParser: { [key: string]: string } = {
    PaymentRequest: 'Solicitud de pago',
    Support: 'Soporte',
    PaymentRefund: 'Devolución',
    WithdrawalRefund: 'Devolución por transferencia.',
  };
  return ticketableTypeParser[ticket] ? ticketableTypeParser[ticket] : 'No definido';
}

export function risk(status: string): string {
  const riskParser: { [key: string]: string; } = {
    none: 'Ninguno',
    medium: 'Medio',
    low: 'Bajo',
    high: 'Alto',
  };
  return riskParser[status] ? riskParser[status] : 'Desconocido';
}

export function billingType(billing: string): string {
  const billingParser: { [key: string]: string; } = {
    final_consumer: 'Consumidor final',
    fiscal_credit: 'Crédito físcal',
  };
  return billingParser[billing] ? billingParser[billing] : 'Desconocido';
}

export function bankAccountType(bank: string): string {
  const bankAccountParser: { [key: string]: string; } = {
    current: 'Corriente',
    savings: 'Ahorro',
  };
  return bankAccountParser[bank] ? bankAccountParser[bank] : 'Desconocido';
}

export function liquidationPeriod(val: string): string {
  const liquidation: { [key: string]: string } = {
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
  };
  return liquidation[val] ? liquidation[val] : 'No definido';
}

export function renewalTraduction(renewal: string): string {
  const renewals: { [key: string]: string } = {
    automatic: 'Automático',
    manual: 'Manual',
  };
  return renewals[renewal] ? renewals[renewal] : 'No definido';
}

export function taxPayer(tax: string): string {
  const taxPayers: { [key: string]: string } = {
    non: 'No contribuyente',
    small: 'Pequeño',
    medium: 'Mediano',
    big: 'Grande',
    governmental: 'Gubernamental',
    other: 'Otro',
  };
  return taxPayers[tax] ? taxPayers[tax] : 'Desconocido';
}
