import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import localize from 'dayjs/plugin/localizedFormat';
import { DEFAULT_WRONG_DATE } from '../consts'; // importa el idioma español

dayjs.locale('es');

dayjs.extend(localize);
dayjs.extend(utc);
type tDayJSParam = string | Date | number | null

export const dayjsUTC = (date: string | Date): dayjs.Dayjs => dayjs(date).utc();
export const dayJsInstance = (date?: tDayJSParam): dayjs.Dayjs => dayjs(date);

type tDate = string | null | undefined;

export default class DatesFormat {
  public formatDate(date: tDate): string {
    if (date) {
      return dayjsUTC(date).format('ll');
    }
    return DEFAULT_WRONG_DATE;
  }

  public formatDateTime(date: tDate): string {
    if (date) {
      return dayjsUTC(date).format('lll');
    }
    return DEFAULT_WRONG_DATE;
  }

  public formatYear(date: tDate): string {
    if (date) {
      return dayjsUTC(date).format('MM-DD-YYYY');
    }
    return DEFAULT_WRONG_DATE;
  }

  public formatBrithdate(date: tDate): string {
    if (date) {
      return dayjsUTC(date).format('ll');
    }
    return DEFAULT_WRONG_DATE;
  }
}
