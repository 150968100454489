







































































import branchsUser from '@/store/types/branchsUser';
import { BranchUserParams } from '@/store/interfaces/branchsUser';
import root from '@/store/types/root';
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'BranchUserForm',
  props: {
    errors: {
      required: true,
      type: Object,
    },
    success: {
      required: true,
      type: Boolean,
    },
    update: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    model: {
      profile: {
        first_name: '',
        last_name: '',
      },
      email: '',
    },
  }),
  computed: {
    ...mapGetters({
      getProcess: root.getters.GET_START_PROCESS,
      getSingleBranchUser: `commerceModule/branches/users/${branchsUser.getters.GET_SINGLE_USER_BRANCH}`,
    }),
    updateSync: {
      get(): boolean {
        return this.update;
      },
      set(val: boolean): void {
        this.$emit('update', val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setSingleBranchUser: `commerceModule/branches/users/${branchsUser.mutations.SET_SINGLE_USER_BRANCH}`,
    }),
    branchUser(): void {
      this.$emit('send');
    },
    cancel(): void {
      this.$router.push({
        name: 'users-braches',
        hash: '#usuarios',
      });
    },
  },
  created(): void {
    this.model = this.getSingleBranchUser;
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler(val: BranchUserParams): void {
        setTimeout(() => {
          this.setSingleBranchUser(val);
        }, 500);
      },
    },
    errors: {
      immediate: true,
      deep: true,
      handler(val): void {
        if (Object.keys(val).length !== 0) {
          (
            this.$refs.formValidatorUserBranchForm as InstanceType<
              typeof ValidationProvider
            >
          ).setErrors(val);
        }
      },
    },
    success: {
      immediate: true,
      handler(val: boolean): void {
        if (val) {
          (
            this.$refs.formValidatorUserBranchForm as InstanceType<
              typeof ValidationProvider
            >
          ).reset();
        }
      },
    },
  },
});
