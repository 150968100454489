import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  liquidationSettlementParams,
  settlementParams,
  SettlementsState
} from '@/store/interfaces/settlements';
// Establece los types de los modulos store
import settlementsTypes from '@/store/types/settlements';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../interfaces/rootState';
import { responseCollection } from '../interfaces/json-api/generics';

const namespaced = true;

const initialState = (): SettlementsState => {
  return {
    settlements: {
      data: [],
      pagination: {},
    },
    liquidationBySettlements: {
      data: [],
      pagination: {},
    },
  };
};

const state: SettlementsState = initialState();
const getters: GetterTree<SettlementsState, RootState> = {
  [settlementsTypes.getters.GET_SETTLEMENTS]: (state) => {
    return state.settlements;
  },
  [settlementsTypes.getters.GET_LIQUIDATIONS_BY_SETTLEMENTS]: (state) => {
    return state.liquidationBySettlements;
  },
};
const mutations: MutationTree<SettlementsState> = {
  [settlementsTypes.mutations.SET_SETTLEMENTS]: (
    state,
    settlements: responseCollection<settlementParams>
  ) => {
    state.settlements.data = settlements.data;
    state.settlements.pagination = settlements.meta.pagination;
  },
  [settlementsTypes.mutations.SET_LIQUIDATIONS_BY_SETTLEMENTS]: (
    state,
    settlements: responseCollection<liquidationSettlementParams>
  ) => {
    state.liquidationBySettlements.data = settlements.data;
    state.liquidationBySettlements.pagination = settlements.meta.pagination;
  },
};
const actions: ActionTree<SettlementsState, RootState> = {
  [settlementsTypes.actions.SETTLEMENTS]: ({ commit, }, query) => {
    return new Promise((resolve, reject) => {
      http
        .get(`backoffice/settlements?${query}`)
        .then((res: AxiosResponse) => {
          commit(settlementsTypes.mutations.SET_SETTLEMENTS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [settlementsTypes.actions.LIQUIDATIONS_BY_SETTLEMENTS]: ({ commit, },
    { settlement_id, query, }) => {
    const URL = `backoffice/settlements/${settlement_id}/liquidations?${query}`;
    return new Promise((resolve, reject) => {
      http
        .get(URL)
        .then((res: AxiosResponse) => {
          commit(settlementsTypes.mutations.SET_LIQUIDATIONS_BY_SETTLEMENTS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
